import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../TextInputCounter/TextInputCounter";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
  maxLength,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";

interface ICreate {
  [key: string]: any;
}

const CreateToolbar = ({ ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Create"
        style={{ top: 54 }}
        transform={({ ...data }: ICreate) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            // publishedAt: data.enabled == true || data.enabled == "true" ? date.toISOString() : null,
            order: 100,
          };

          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const CreateScreen = (props: any) => {
  const { onCreated, hasEnabled, resource } = props;
  const notify = useNotify();

  const onSuccess = (data: any) => {
    notify(`Changes saved`);
    onCreated(data.id);
  };

  return (
    <Create
      resource={resource}
      redirect={false}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm toolbar={<CreateToolbar hasEnabled={hasEnabled} />}>
        <TextInputCounter
          source="questionText"
          multiline
          fullWidth
          resettable
          label="Question Text"
          validate={[required(), maxLength(250)]}
          limit={250}
        />

        <TextInputCounter
          source="answerText"
          multiline
          fullWidth
          resettable
          label="Answer Text"
          validate={[required(), maxLength(500)]}
          limit={500}
        />
      </SimpleForm>
    </Create>
  );
};
