import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import {
  SimpleForm,
  Toolbar,
  SaveButton,
  useEditController,
} from "react-admin";
import { RichEditorField } from "../../components/RichEditorField/RichEditorField";
import { RaEditWrapper } from "../../components/RaEditWrapper";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, record, editorContent, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const mappedRequest: any = {
            ...data,
            content: editorContent ? editorContent : record.content,
          };

          // Clean up fields.
          delete mappedRequest.imageUpload;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;
          delete mappedRequest.journalTags;
          delete mappedRequest.properties;

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  // Editor.
  const [editorContent, setEditorContent] = React.useState<any>(null);

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <RaEditWrapper mutationMode="pessimistic">
      <SimpleForm
        toolbar={<EditToolbar record={record} editorContent={editorContent} />}
      >
        <RichEditorField
          source="content"
          onContentChange={(d: any) => setEditorContent(d)}
        />
      </SimpleForm>
    </RaEditWrapper>
  );
};
