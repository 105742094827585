import { ResourceProps } from "react-admin";
import Icon from "@mui/icons-material/NightShelter";
import { Resource } from "../../enums";
import ListScreen from "./list";
import { EditScreen } from "./edit";

const partnerWithUsPages: ResourceProps = {
  name: Resource.PARTNER_PAGE,
  icon: Icon,
  list: ListScreen,
  edit: EditScreen,
  options: {
    label: "Partner w/ Us",
  },
};

export default partnerWithUsPages;
