import { useEffect, useState } from "react";
import {
  Datagrid,
  useDataProvider,
  useList,
  EditButton,
  CreateButton,
  BulkDeleteButton,
  useListContext,
  FunctionField,
  useNotify,
  Toolbar,
} from "react-admin";
import { RaListContextProviderWrapper } from "../../components/RaListContextProviderWrapper";

const CREATE_NEW_LABEL = "New Article";
const DATAPROVIDER_RESOURCE_ID = "journal-articles";
const UPDATE_MSG = "Article order updated.";
const UPDATE_ORDER_MSG = "Updating article order...";

const ListActions = () => (
  <Toolbar>
    <CreateButton label={CREATE_NEW_LABEL} />
  </Toolbar>
);

const BulkActionButtons = (props: any) => {
  const { onAddDeletedIds } = props;
  const { selectedIds } = useListContext();

  const onConfirmDelete = () => {
    onAddDeletedIds(selectedIds);
  };

  const confirmMsg = `
    Are you sure you want to delete?
  `;

  return (
    <>
      <BulkDeleteButton
        mutationMode="optimistic"
        onClick={onConfirmDelete}
        confirmContent={confirmMsg}
      />
    </>
  );
};

const ListScreen = () => {
  const dataProvider = useDataProvider();
  const [resources, setResources] = useState<any[]>([]);
  const [metadata, setMetadata] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [deletedIds, setDeletedIds] = useState<any[]>([]);
  const notify = useNotify();

  const addDeletedIds = (ids: string[]) => {
    setDeletedIds([...deletedIds, ...ids]);
  };

  const fetchResources = async (page = 1) => {
    try {
      setLoading(true);

      const { data, metadata } = await dataProvider.getJournalArticleList({
        page: page,
      });

      setResources(data);
      setMetadata(metadata);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  // Order functions.
  const moveMainItem = async (index: number, direction: "up" | "down") => {
    const movedResources = [...resources];

    if (direction === "up" && index > 0 && index < movedResources.length) {
      const removedItem = movedResources.splice(index, 1)[0];
      movedResources.splice(index - 1, 0, removedItem);
    }

    if (
      direction === "down" &&
      index >= 0 &&
      index < movedResources.length - 1
    ) {
      const removedItem = movedResources.splice(index, 1)[0];
      movedResources.splice(index + 1, 0, removedItem);
    }

    setResources(movedResources);

    await updateResourceOrder(movedResources);
  };

  const updateResourceOrder = async (movedResources: any[]) => {
    notify(UPDATE_ORDER_MSG);

    const updateOrderPayload = movedResources.map((item, itemIndex: number) => {
      return {
        id: item.id,
        name: item.title,
        order: itemIndex,
      };
    });

    await Promise.all(
      updateOrderPayload.map(async (p: any) => {
        const payload: any = {
          id: p.id,
          data: {
            order: p.order,
          },
        };

        return dataProvider.update(DATAPROVIDER_RESOURCE_ID, payload);
      }),
    );

    notify(UPDATE_MSG);
  };

  // Filter and flat.

  const filteredResources = resources.filter(
    (p: any) => !deletedIds.includes(p.id),
  );

  const mappedResources = filteredResources.map((item, itemIndex: number) => {
    return {
      ...item,
      index: itemIndex,
    };
  });

  const listContext = useList({ data: mappedResources, isLoading: loading });

  // Custom FunctionFields.
  const renderName = (record: any) => {
    const index = record.index;

    const isFirst = index === 0;
    const isLast = index === resources.length - 1;

    return (
      <>
        <span
          className={`record-name ${!record.publishedAt && "disabled-name"}`}
        >
          {record.title.length > 100
            ? record.title.substr(0, 100) + "..."
            : record.title}
        </span>
      </>
    );
  };

  const renderEnabled = (record: any) => {
    return record.publishedAt ? (
      "Published"
    ) : (
      <span className={`${!record.publishedAt && "disabled-name"}`}>Draft</span>
    );
  };

  const renderPublished = (record: any) => {
    return record.publishedAt ? (
      new Date(
        record.publishedAt.replace("00:00:00", "12:00:00"),
      ).toDateString()
    ) : (
      <span>-</span>
    );
  };

  const renderPagination = () => {
    if (!metadata || !metadata.pagination) {
      return <p></p>;
    }

    const { pagination } = metadata;
    const { pageNumber, pageCount } = pagination;
    const items = new Array(pageCount).fill(0);

    return (
      <p className="pagination">
        {items.map((i: number, index) => {
          const realItem = index + 1;

          return (
            <span
              key={index}
              className={`pagination-item ${
                realItem == pageNumber ? "current-page" : ""
              }`}
              onClick={() => fetchResources(realItem)}
            >
              {realItem}
            </span>
          );
        })}
      </p>
    );
  };

  return (
    <RaListContextProviderWrapper value={listContext}>
      <ListActions />
      <Datagrid
        bulkActionButtons={
          <BulkActionButtons onAddDeletedIds={addDeletedIds} />
        }
      >
        <FunctionField label="Title" render={renderName} />
        <FunctionField label="Published at" render={renderPublished} />
        <FunctionField label="Status" render={renderEnabled} />
        <EditButton className="editToHover" label="Edit" />
      </Datagrid>
      <Toolbar sx={{ background: "transparent" }}>{renderPagination()}</Toolbar>
    </RaListContextProviderWrapper>
  );
};

export default ListScreen;
