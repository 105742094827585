import { defaultTheme } from "react-admin";
import { deepmerge } from "@mui/utils";

export const CONTAINER_PADDING_VERTICAL = 48;
export const CONTAINER_PADDING_HORIZONTAL = 32;

const theme = deepmerge(defaultTheme, {
  palette: {
    primary: {
      main: "#DD4725",
    },
    secondary: {
      main: "#F6F6EF",
    },
    background: {
      default: "#F6F6EF",
      paper: "#F6F6EF",
    },
    mode: "light",
  },
  typography: {
    fontFamily: "PassengerSans,sans-serif",
  },
  components: {
    RaLayout: {
      styleOverrides: {
        root: `
          #main-content {
            background-color: #fff;
            padding: 0 ${CONTAINER_PADDING_HORIZONTAL}px;

            & .RaEdit-main > .MuiPaper-root,
            & .RaCreate-main > .MuiPaper-root {
              background-color: #fff;
              box-shadow: none;
              border: 1px solid #E5E5E5;
              border-radius: 8px;
            }

            & .RaEdit-main > .MuiPaper-root.RaEdit-card > form .MuiToolbar-root,
            & .RaCreate-main > .MuiPaper-root.RaCreate-card > form .MuiToolbar-root,

            /* To be hacked to act like .RaList-main > div:nth-child(0n+1 of .MuiToolbar-root) */
            & > .RaList-main > .MuiToolbar-root {
              position: absolute;
              top: ${CONTAINER_PADDING_VERTICAL}px;
              right: ${CONTAINER_PADDING_HORIZONTAL}px;
              min-height: auto;
            }

            /* Hack described above */
            & > .RaList-main > .MuiToolbar-root ~ .MuiToolbar-root {
              position: relative;
              top: unset;
              right: unset;
            }

            & > .RaList-main > .MuiToolbar-root > .MuiButtonBase-root {
              padding: 12px 16px;
              border-radius: 40px;
              text-transform: none;
              color: #FBF8EF;
              background-color: #DD4725;
              font-size: 16px;
              font-weight: 500;
              line-height: 16px;
            }
          }

          & .RaLayout-appFrame {
            margin-top: 0;
          }
        `,
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FBF8EF",
          padding: "48px 24px",

          "& ul.MuiList-root": {
            "& > .MuiButtonBase-root, & > .MuiButtonBase-root .MuiTypography-root":
              {
                fontSize: "18px",
                fontWeight: "300",
                lineHeight: "24px",
                color: "#191919",
              },

            "& .MuiButtonBase-root": {
              padding: "8px 12px",
              margin: "6px 0",
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "rgba(200, 208, 200, .3)",
              },
              "&.RaMenuItemLink-active": {
                backgroundColor: "rgba(200, 208, 200, 1)",
              },
              "& .MuiSvgIcon-root": {
                fill: "#191919",
              },
            },
          },
          "& .MuiCollapse-root .MuiButtonBase-root, & .MuiCollapse-root .MuiTypography-root":
            {
              fontSize: "16px",
              fontWeight: "300",
              lineHeight: "22px",
              color: "#525252",
            },
        },
      },
    },
    RaSaveButton: {
      styleOverrides: {
        root: {
          padding: "12px 16px",
          borderRadius: "40px",
          textTransform: "none",
          "& svg": {
            display: "none",
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          border: "1px solid #E5E5E5",
          backgroundColor: "#FAFAFA",
          "& .MuiTableCell-root": {
            paddingTop: "16px",
            paddingBottom: "16px",
          },
          "& .MuiTableCell-head": {
            color: "#000",
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "24px",
            backgroundColor: "#fff",
          },
          "& .MuiTableCell-body": {
            color: "#525252",
            backgroundColor: "#FAFAFA",
            "& .MuiTypography-root": {
              fontSize: "16px",
              fontWeight: "300",
              lineHeight: "22px",
            },
          },
          "& .MuiTableCell-body.column-_edit": {
            opacity: "0",
          },
          "& .MuiTableCell-body .editToHover": {
            opacity: "0",
          },
          "& .MuiTableRow-root > td:first-of-type": {
            borderLeft: "2px solid transparent",
          },
          "& .MuiTableRow-root:hover": {
            "& > td:first-of-type": {
              borderLeft: "2px solid #DD4725",
            },
            "& .MuiTableCell-body": {
              backgroundColor: "#F5F5F5",
            },
            "& .MuiTableCell-body.column-_edit": {
              opacity: "1",
            },
            "& .MuiTableCell-body .editToHover": {
              opacity: "1",
            },
          },
        },
      },
    },
    RaTabbedForm: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#fff",
          "&.tabbed-form > .MuiTabs-root": {
            width: "16%",
          },
          "&.tabbed-form > .MuiCardContent-root": {
            maxWidth: "84%",
          },
          "& > .MuiTabs-root": {
            backgroundColor: "#fafafa",
            "& .MuiTabs-flexContainer": {
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "24px 0",
              "& > .MuiTab-root": {
                fontSize: "16px",
                fontWeight: "300",
                lineHeight: "22px",
                color: "#737373",
                textTransform: "none",
                alignItems: "start",
                padding: "0 16px",
                minHeight: "auto",
                "&.Mui-selected": {
                  color: "#525252",
                  borderLeft: "2px solid #DD4725",
                },
              },
            },
          },

          "& > .MuiCardContent-root": {
            flexGrow: 1,
            padding: "24px",
          },

          "& > .MuiDivider-root, & .MuiTabs-indicator": {
            display: "none",
          },
        },
      },
    },
    RaImageInput: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          "& > div.previews:empty": {
            display: "none",
          },
          "& > div.previews:not(:is(:empty))": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "186px",
            maxHeight: "186px",
            padding: "10px",
            backgroundColor: "#F6F6F1",
            "& img.RaImageField-image": {
              display: "block", // Fixes `line-height` side effect on inline images.
              width: "auto",
              height: "auto",
              maxHeight: "166px",
              margin: "0",
            },
            "& > *": {
              overflow: "hidden",
            },
          },
          "& > .RaFileInput-dropZone": {
            order: 98,
          },
          "& > .MuiFormHelperText-root": {
            order: 99,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0 !important",
          backgroundColor: "transparent !important",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiDialog-container > .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm": {
            padding: 16,
            gap: 16,
            "& .MuiTypography-root.MuiTypography-h6.MuiDialogTitle-root":{
              padding: 0
            },
          },
          "& .MuiDialog-container > .MuiPaper-root": {
            backgroundColor: "#fff",
            "& > div": {
              padding: "0",
            },
            "& .edit-page .MuiPaper-root, & .create-page .MuiPaper-root": {
              backgroundColor: "#fff",
              border: "none",
              boxShadow: "none",
              "& .MuiCardContent-root": {
                padding: "0",
              },
            },
            
          },
          
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        variant: "standard",
        shrink: true,
      },
      styleOverrides: {
        root: {
          position: "relative",
          color: "#191919",
          fontSize: "18px",
          fontWeight: "300",
          lineHeight: "24px",
          letterSpacing: "-0.01em",
          transform: "none",
          marginBottom: "8px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
        margin: "normal",
      },
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            borderColor: "#E5E5E5",
            borderRadius: "80px",
            fontSize: "14px",
            fontWeight: "300",
            color: "#292929",
          },
          "& .MuiFormHelperText-root.Mui-error":{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          },
          "& .MuiInputBase-root.MuiInputBase-multiline": {
            borderRadius: "8px",
          },
          "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
            top: "0",
          },
          "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline > legend": {
            display: "none",
          },
        },
      },
    },
  },
});

export default theme;
