import {
  useList,
  EditButton,
  CreateButton,
  Toolbar,
  BulkDeleteButton,
  FunctionField,
  useNotify,
} from "react-admin";
import { RaListContextProviderWrapper } from "../../components/RaListContextProviderWrapper";

import {
  MovingItemPayload,
  OrderableDatagrid,
} from "../../components/OrderableDatagrid";
import { useResourceList } from "../../hooks/useResourceList";

const ListActions = () => (
  <Toolbar>
    <CreateButton label={"New Activity"} />
  </Toolbar>
);

const ActivitiesBulkActionButtons = ({ onDelete }: any) => {
  const confirmMsg = `
    Are you sure you want to delete?\n\n
    If you delete a Category - all children activities will be deleted.
  `;

  return (
    <>
      <BulkDeleteButton
        mutationMode="optimistic"
        mutationOptions={{ onSettled: () => onDelete() }}
        confirmContent={confirmMsg}
      />
    </>
  );
};

const ActivitiesList = () => {
  const { state, items, updateResources, refetch } =
    useResourceList("activities");
  const notify = useNotify();

  const updateActivityOrder = async (itemsPayload: MovingItemPayload[]) => {
    notify("Updating activities order...");

    await updateResources(itemsPayload);

    notify("Activities order updated.");

    refetch();
  };

  const flatActivities = items?.flatMap((item, itemIndex: number) => {
    let subActivities = item.subActivities || [];

    return [
      {
        ...item,
        index: itemIndex,
      },
      ...subActivities.flatMap((subItem: any, subItemIndex: number) => ({
        ...subItem,
        parentIndex: itemIndex,
        index: subItemIndex,
      })),
    ];
  });

  const listContext = useList({
    data: flatActivities,
    isLoading: !Array.isArray(items) && state === "loading",
  });

  // Custom FunctionFields.
  const renderName = (record: any) => {
    return (
      <span className={`record-name ${!record.enabled && "disabled-name"}`}>
        {record.name}
      </span>
    );
  };

  const renderType = (record: any) => {
    return record.parentId ? (
      <span className={`${!record.enabled && "disabled-name"}`}>
        — Activity
      </span>
    ) : (
      <span className={`${!record.enabled && "disabled-name"}`}>Category</span>
    );
  };

  const renderEnabled = (record: any) => {
    return record.enabled ? (
      "Enabled"
    ) : (
      <span className={`${!record.enabled && "disabled-name"}`}>Disabled</span>
    );
  };

  return (
    <RaListContextProviderWrapper value={listContext}>
      <ListActions />
      <OrderableDatagrid
        items={items ?? []}
        subItemKey="subActivities"
        isLoading={state === "loading"}
        onUpdateOrder={updateActivityOrder}
        bulkActionButtons={<ActivitiesBulkActionButtons onDelete={refetch} />}
      >
        <FunctionField label="Name" render={renderName} />
        <FunctionField label="Type" render={renderType} />
        <FunctionField label="Enabled?" render={renderEnabled} />
        <EditButton className="editToHover" label="Edit" />
      </OrderableDatagrid>
    </RaListContextProviderWrapper>
  );
};

export default ActivitiesList;
