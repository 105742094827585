import { useCallback, useEffect, useState } from "react";
import { Button, useDataProvider } from "react-admin";
import EventItem from "./EventItem";
import "./EventsManager.css";
import { ResourceEditor } from "../ResourceEditor/ResourceEditor";

import { CreateScreen as EventsCreate } from "../../components/events/create";
import { ResourceEditorDialog } from "../ResourceEditor/ResourceEditorDialog";

const EventsManager = ({
  events,
  isLoading,
  propertyId,
  refetchEvents,
}: {
  events: any[];
  isLoading: boolean;
  propertyId: string | null;
  refetchEvents: any;
}) => {
  const dataProvider = useDataProvider();
  const [currentTab, setCurrentTab] = useState<string>("current");
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [allActivities, setAllActivities] = useState<any[]>([]);

  const handleOpenCreateDialog = useCallback(
    () => setCreateDialogOpen(true),
    [],
  );

  const handleCloseCreateDialog = useCallback(
    () => setCreateDialogOpen(false),
    [],
  );

  const handleOnResourceCreated = useCallback(() => {
    setCreateDialogOpen(false);
  }, []);

  useEffect(() => {
    const fetchChildrenActivities = async () => {
      const { data } = await dataProvider.getChildrenActivities();

      const mappedAllActivities = data.map((actv: any) => {
        return {
          id: actv.id,
          name: actv.name,
        };
      });

      setAllActivities(mappedAllActivities);
    };
    fetchChildrenActivities();
  }, [propertyId]);

  if (isLoading) {
    return <p>Loading property events...</p>;
  }

  // Get the current date and time
  const currentDate = new Date();

  const isInTheFuture = (dateToCheck: Date) => {
    return dateToCheck >= currentDate;
  };

  const currentEvents: any[] = [];
  const pastEvents: any[] = [];

  events.forEach((r) => {
    if (isInTheFuture(new Date(r.startDate))) {
      currentEvents.push(r);
      return;
    } else {
      pastEvents.push(r);
      return;
    }
  });

  return (
    <div>
      <section className="top-area">
        <div className="tabs">
          <Button
            onClick={() => {
              setCurrentTab("current");
            }}
            label={`Current (${currentEvents.length})`}
            variant={`${currentTab === "current" ? "contained" : "text"}`}
          />
          <Button
            onClick={() => {
              setCurrentTab("past");
            }}
            label={`Past (${pastEvents.length})`}
            variant={`${currentTab === "past" ? "contained" : "text"}`}
          />
        </div>

        <div className="add-new-event">
          <div>
            <Button
              sx={{
                gap: "16px", // Implied icon margin of 8px -> 24px total.
                color: "#000",
                textTransform: "none",
                fontWeight: 300,
                py: "20px",
              }}
              size="medium"
              onClick={handleOpenCreateDialog}
              variant="text"
              label={"Create Event"}
            />

            <ResourceEditorDialog
              isOpen={createDialogOpen}
              title={"Create Event"}
              content={
                <EventsCreate
                  onCreated={handleOnResourceCreated}
                  resource={"events"}
                  propertyId={propertyId}
                  activities={allActivities}
                />
              }
              onClose={handleCloseCreateDialog}
            />
          </div>
        </div>
      </section>

      <div style={{ display: currentTab === "current" ? "block" : "none" }}>
        {currentEvents.length === 0 ? (
          <p>Zero current events to display.</p>
        ) : null}
        {currentEvents.map((r, i) => {
          return (
            <EventItem
              key={`${r.id}-${i}`}
              event={r}
              refetchEvents={refetchEvents}
              activities={allActivities}
            />
          );
        })}
      </div>
      <div style={{ display: currentTab === "past" ? "block" : "none" }}>
        {pastEvents.length === 0 ? <p>Zero past events to display.</p> : null}
        {pastEvents.map((r, i) => {
          return (
            <EventItem
              key={`${r.id}-${i}`}
              event={r}
              refetchEvents={refetchEvents}
              activities={allActivities}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EventsManager;
