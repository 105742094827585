import { Fragment, ReactNode, ReactElement, useState } from "react";

import { List, ListItem, ListItemText, Collapse } from "@mui/material";
import { useTranslate, useSidebarState } from "react-admin";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const SubMenu = (props: SubMenuProps) => {
  const { isDropdownOpen = false, primaryText, leftIcon, children } = props;
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [isOpen, setIsOpen] = useState(isDropdownOpen);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <ListItem
        dense
        button
        onClick={handleToggle}
        sx={{
          paddingLeft: "1rem",
          color: "rgba(0, 0, 0, 0.54)",
        }}
      >
        {leftIcon}
        <ListItemText
          inset
          primary={primaryText ? translate(primaryText) : ""}
          sx={{
            paddingLeft: leftIcon ? 2 : 0,
            fontSize: "1rem",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        />
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={
            open
              ? {
                  paddingLeft: "25px",
                  transition:
                    "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                }
              : {
                  paddingLeft: 0,
                  transition:
                    "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                }
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export type SubMenuProps = {
  children?: ReactNode;
  isDropdownOpen?: boolean;
  leftIcon?: ReactElement;
  primaryText?: string;
};

export default SubMenu;
