import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";
import { RaCreateWrapper } from "../../components/RaCreateWrapper";

interface ICreate {
  [key: string]: any;
}

const CreateToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: ICreate) => {
          const mappedRequest = {
            ...data,
            enabled: data.enabled,
            order: 100,
          };
          return {
            ...mappedRequest,
            ...files,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const CreateScreen = () => {
  const notify = useNotify();

  // Resource Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    iconUploadId: null | any;
  }>({
    iconUploadId: null,
  });

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: "iconUploadId", files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title } = useRecordContext();

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.children;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  const onFileRemoval = (target: string, _file: any) => {
    setResourceFiles({ ...resourceFiles, [target]: null });
    return true; // is handled from frontend
  };

  return (
    <RaCreateWrapper title="New Amenity">
      <SimpleForm toolbar={<CreateToolbar files={resourceFiles} />}>
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="name"
            validate={[required()]}
          />
          <SelectInput
            choices={enabledFlags}
            source="enabled"
            label="Enabled?"
            defaultValue="false"
            validate={[required()]}
          />
        </div>

        <h4 className="heading-file-uploads">Icon</h4>

        <div className="resource-icons-grid">
          <ImageInput
            source="iconUploadId"
            label="Amenity Icon (.png, .jpg, .jpeg)"
            validate={[required()]}
            accept={"image/png,image/jpg,image/jpeg"}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "iconUploadId"),
            }}
            validateFileRemoval={(file: any) =>
              onFileRemoval("iconUploadId", file)
            }
          >
            <FileFieldWithContext>
              <ImageField source="src" title="title" />
            </FileFieldWithContext>
          </ImageInput>
        </div>
      </SimpleForm>
    </RaCreateWrapper>
  );
};
