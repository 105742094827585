export const week_days = [
  {
    id: 0,
    name: "Sunday",
    value: 0,
  },
  {
    id: 1,
    name: "Monday",
    value: 1,
  },
  {
    id: 2,
    name: "Tuesday",
    value: 2,
  },
  {
    id: 3,
    name: "Wednesday",
    value: 3,
  },
  {
    id: 4,
    name: "Thursday",
    value: 4,
  },
  {
    id: 5,
    name: "Friday",
    value: 5,
  },
  {
    id: 6,
    name: "Saturday",
    value: 6,
  },
];
