import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  useGetOne,
  useEditController,
} from "react-admin";
import { RaEditWrapper } from "../../components/RaEditWrapper";

const DATAPROVIDER_RESOURCE_ID = "journal-tags";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const mappedRequest: any = {
            ...data,
          };

          // Clean up fields.
          delete mappedRequest.id;

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const { id } = useParams();
  const { data } = useGetOne(`/${DATAPROVIDER_RESOURCE_ID}`, { id });

  const resourceName = data ? data.name : "";

  // Record handler.
  const { record } = useEditController(props);

  return (
    <RaEditWrapper
      mutationMode="pessimistic"
      title={resourceName}
      redirect="list"
    >
      <SimpleForm toolbar={<EditToolbar />} record={record}>
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="name"
            validate={[required()]}
          />
        </div>
      </SimpleForm>
    </RaEditWrapper>
  );
};
