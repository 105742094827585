import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import "./styles.css";
import EventsManager from "../../components/events-manager/EventsManager";

const EventsList = () => {
  const dataProvider = useDataProvider();
  const [properties, setProperties] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [currentProperty, setCurrentProperty] = useState<string | null>(null);
  const [events, setEvents] = useState<any[]>([]);
  const [loadingEvents, setLoadingEvents] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const { data } = await dataProvider.getMany("properties", { ids: [] });

        const filteredProperties = data.filter(
          (p) => p.revinateKey && p.revinateKey !== "",
        );

        setProperties(filteredProperties);

        if (filteredProperties.length > 0) {
          setCurrentProperty(filteredProperties[0].id);
        }
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchProperties();
  }, []);

  const fetchEvents = async () => {
    console.log("fetching events for " + currentProperty);
    setLoadingEvents(true);

    const { data } = await dataProvider.getAllPropertyEvents(currentProperty);

    setEvents(data);

    setLoadingEvents(false);
  };

  useEffect(() => {
    if (currentProperty !== null) {
      fetchEvents();
    }
  }, [currentProperty]);

  const onAfterImport = async () => {};

  if (loading) {
    return <div className="events-wrapper">Loading active properties...</div>;
  }

  return (
    <div className="events-wrapper">
      <h1 className="events-title">Events</h1>
      <div className="property-picker">
        <FormControl sx={{ m: 0, minWidth: 120, mb: "20px" }} size="small">
          <Select
            onChange={(e) => {
              setCurrentProperty(e.target.value);
            }}
            value={currentProperty}
          >
            {properties.map((p: any) => {
              return (
                <MenuItem value={p.id} key={p.id}>
                  {`${p.name} / ${p.locality}, ${p.area}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div>
        <EventsManager
          propertyId={currentProperty}
          events={events}
          isLoading={loadingEvents}
          refetchEvents={fetchEvents}
        />
      </div>
    </div>
  );
};

export default EventsList;
