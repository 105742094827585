import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  required,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useEditController,
  maxLength,
  TabbedForm,
  FormTab,
} from "react-admin";
import useFileUpload from "../../hooks/useFileUpload";
import { RaEditWrapper } from "../../components/RaEditWrapper";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
          };

          const resourceFilesReq = {
            appUploadId: files.appUpload ?? data.appUpload.id,
          };

          // Clean up fields.
          delete mappedRequest.appUpload;
          delete mappedRequest.id;

          return {
            ...mappedRequest,
            ...resourceFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const notify = useNotify();

  // Upload Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    appUpload: null | any;
  }>({
    appUpload: null,
  });

  const [resourceImgs, setResourceImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!resourceImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={resourceImgs[name].uri}
              title={resourceImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: string, files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setResourceImgs({
        ...resourceImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  // Record handler.
  const { record } = useEditController(props);

  return (
    <RaEditWrapper mutationMode="pessimistic">
      <TabbedForm
        toolbar={<EditToolbar files={resourceFiles} record={record} />}
      >
        <FormTab label="Store">
          <TextInputCounter
            source="appAppleStoreUrl"
            label="Apple Store URL"
            fullWidth
            validate={[required(), maxLength(250)]}
            limit={250}
          />

          <TextInputCounter
            source="appGoogleStoreUrl"
            label="Google Store URL"
            fullWidth
            validate={[required(), maxLength(250)]}
            limit={250}
          />
        </FormTab>
        <FormTab label="Home">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="appHeading"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="bookCopy"
            multiline
            fullWidth
            resettable
            validate={[maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="discoveryCopy"
            label="Discover Copy"
            multiline
            fullWidth
            resettable
            validate={[maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="communityCopy"
            multiline
            fullWidth
            resettable
            validate={[maxLength(100)]}
            limit={100}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="appUpload"
              label="App Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "appUpload"),
              }}
            >
              {renderImgPreview("appUpload")}
            </ImageInput>
          </div>
        </FormTab>
        <FormTab label="Property">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="propertyTitle"
            label="Title"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="propertyDescription"
            label="Description"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(200)]}
            limit={200}
          />
        </FormTab>
        <FormTab label="Nav/Footer">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="navFooterTitle"
            label="Title"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="navFooterDescription"
            label="Description"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(200)]}
            limit={200}
          />
        </FormTab>
      </TabbedForm>
    </RaEditWrapper>
  );
};
