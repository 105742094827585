import { useCallback, useEffect, useState } from "react";
import { Button, useDataProvider, useNotify, useDelete } from "react-admin";

import Box from "@mui/material/Box";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { Heading } from "./ResourceEditor.styled";
import { ResourceEditorItem } from "./ResourceEditorItem";
import { ResourceEditorDialog } from "./ResourceEditorDialog";

export type UsualResourceItem = {
  id: string;
  title?: string;
  name?: string;
  publishedAt?: string;
};

export type MovingResourceItemPayload = {
  id: string;
  data: {
    order: number;
  };
};

export type ResourceEditorProps<ResourceItemType> = {
  heading: string;
  resourceId: string;
  createNewLabel: string;
  updatedOrderMsg: string;
  updatingOrderMsg: string;
  hasOrder?: boolean;
  hasEnabled?: boolean;
  hasBorder?: boolean;
  fetchResourceItems?: () => Promise<ResourceItemType[]>;
  onUpdateResourceItems?: (
    resources: ResourceItemType[],
    updatedItems: MovingResourceItemPayload[],
  ) => Promise<void>;
  onRemoveResourceItem?: (
    resources: ResourceItemType[],
    resource: ResourceItemType,
  ) => Promise<void>;
  CreateScreen?: (props: any) => JSX.Element;
  EditScreen?: (props: any) => JSX.Element;
  renderName?: (resource: any) => JSX.Element | string;
};

export const ResourceEditor = <T extends UsualResourceItem>({
  heading,
  resourceId,
  createNewLabel,
  updatedOrderMsg,
  updatingOrderMsg,
  hasOrder,
  hasEnabled,
  hasBorder,
  fetchResourceItems,
  onUpdateResourceItems,
  onRemoveResourceItem,
  CreateScreen,
  EditScreen,
  renderName,
}: ResourceEditorProps<T>) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [remove, { isLoading: isRemovingItem }] = useDelete();

  const [resources, setResources] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchResources = useCallback(async () => {
    try {
      setLoading(true);

      let data;
      if (fetchResourceItems) {
        data = await fetchResourceItems();
      } else {
        data = (
          await dataProvider.getMany(resourceId, {
            ids: [],
          })
        ).data;
      }

      setResources(data);
    } catch (err: any) {
      notify(err);
    } finally {
      setLoading(false);
    }
  }, [dataProvider, resourceId, fetchResourceItems, notify]);

  const updateResourcesOrder = useCallback(
    async (
      updatedResources: T[],
      movedResources: MovingResourceItemPayload[],
    ) => {
      setLoading(true);
      notify(updatingOrderMsg);

      if (onUpdateResourceItems) {
        await onUpdateResourceItems(updatedResources, movedResources);
      } else {
        await Promise.all(
          movedResources.map((p) => dataProvider.update(resourceId, p as any)),
        );
      }

      await fetchResources();

      setLoading(false);
      notify(updatedOrderMsg);
    },
    [
      dataProvider,
      updatingOrderMsg,
      updatedOrderMsg,
      resourceId,
      notify,
      fetchResources,
      onUpdateResourceItems,
    ],
  );

  const moveResourceItem = useCallback(
    (fromIndex: number, toIndex: number) => {
      if (
        loading ||
        fromIndex < 0 ||
        toIndex < 0 ||
        fromIndex >= resources.length ||
        toIndex >= resources.length ||
        fromIndex === toIndex
      ) {
        return;
      }

      const movedResources = [...resources];
      movedResources[fromIndex] = resources[toIndex];
      movedResources[toIndex] = resources[fromIndex];

      setResources(movedResources);
      updateResourcesOrder(movedResources, [
        {
          id: movedResources[fromIndex].id,
          data: {
            order: fromIndex,
          },
        },
        {
          id: movedResources[toIndex].id,
          data: {
            order: toIndex,
          },
        },
      ]);
    },
    [loading, resources, updateResourcesOrder],
  );

  const handleRemoveItem = useCallback(
    async (removedResource: T) => {
      if (onRemoveResourceItem) {
        await onRemoveResourceItem(resources, removedResource);
        return fetchResources();
      } else {
        return remove(
          resourceId,
          { id: removedResource.id },
          {
            onSettled: () => {
              fetchResources();
            },
          },
        );
      }
    },
    [resourceId, resources, fetchResources, onRemoveResourceItem, remove],
  );

  const handleOpenCreateDialog = useCallback(
    () => setCreateDialogOpen(true),
    [],
  );

  const handleCloseCreateDialog = useCallback(
    () => setCreateDialogOpen(false),
    [],
  );

  const handleOnResourceCreated = useCallback(() => {
    setCreateDialogOpen(false);
    fetchResources();
  }, [fetchResources]);

  useEffect(() => {
    fetchResources();
  }, []);

  const isLoading = loading || isRemovingItem;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingTop: hasBorder ? "24px" : undefined,
        borderTop: hasBorder ? "solid 1px #D6D6D6" : undefined,
      }}
    >
      <Heading>{heading}</Heading>

      <div>
        {resources.map((item, index, { length }) => (
          <ResourceEditorItem<T>
            key={item.id}
            resource={item}
            canOrder={!!hasOrder}
            EditComponent={
              !!EditScreen && (
                <EditScreen
                  id={item.id}
                  resource={resourceId}
                  onCreated={fetchResources}
                  hasEnabled={hasEnabled}
                />
              )
            }
            isDisabled={!!hasEnabled && !item.publishedAt}
            isFirst={index === 0}
            isLast={index === length - 1}
            isLoading={isLoading}
            onDeleteItem={handleRemoveItem}
            onMoveUp={() => moveResourceItem(index, index - 1)}
            onMoveDown={() => moveResourceItem(index, index + 1)}
          >
            {!renderName ? item.title ?? item.name : renderName(item)}
          </ResourceEditorItem>
        ))}
      </div>

      {!!CreateScreen && (
        <div>
          <Button
            sx={{
              gap: "16px", // Implied icon margin of 8px -> 24px total.
              color: "#000",
              textTransform: "none",
              fontWeight: 300,
              py: "20px",
            }}
            size="medium"
            onClick={handleOpenCreateDialog}
            startIcon={<AddOutlinedIcon />}
            variant="text"
            label={createNewLabel}
          />

          <ResourceEditorDialog
            isOpen={createDialogOpen}
            title={createNewLabel}
            content={
              <CreateScreen
                onCreated={handleOnResourceCreated}
                hasEnabled={hasEnabled}
                resource={resourceId}
                resources={resources}
              />
            }
            onClose={handleCloseCreateDialog}
          />
        </div>
      )}
    </Box>
  );
};
