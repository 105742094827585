import { ResourceProps } from "react-admin";
import Icon from "@mui/icons-material/People";
import { Resource } from "../../enums";
import ListScreen from "./list";
import { CreateScreen } from "./create";
import { EditScreen } from "./edit";

const events: ResourceProps = {
  name: Resource.EVENTS,
  icon: Icon,
  list: ListScreen,
  create: CreateScreen,
  edit: EditScreen,
  options: {
    label: "Events",
  },
};

export default events;
