import moment from "moment";
import momentTimezone from "moment-timezone";

export const timeFormatter = (value: string | Date) => {
  if (value == null || value === "") return "";

  if (value instanceof Date)
    return moment(value, "hmm").format("HH:mm").toString();

  // comes as hh:mm:ss which conflicts with backend update as it expects hh:mm
  return moment(value, "hhmmss").format("HH:mm").toString();
};

export const timeParser = (value: string) => {
  return moment(value, "hhmm").format("HH:mm").toString();
};

export const getAllTimezones = () => {
  return momentTimezone.tz.names().map((c: string) => {
    return {
      id: c,
      value: c,
      name: c,
    };
  });
};

export function isTodayInRange(
  fromDate: string | null,
  toDate: string | null,
): boolean {
  const today = new Date();
  const from = fromDate ? new Date(fromDate) : null;
  const to = toDate ? new Date(toDate) : null;

  if (!from && !to) {
    return true;
  }

  if (!to) {
    return true;
  }

  return (!from || today >= from) && today <= to;
}
