import { ReactNode } from "react";
import { Edit, EditProps } from "react-admin";

import { MainPageContainer } from "./MainPageContainer";
import { MainPageTitle } from "./MainPageTitle";

export type RaEditWrapperProps = EditProps & { children: ReactNode };

export const RaEditWrapper = ({
  title,
  children,
  ...props
}: RaEditWrapperProps) => (
  <MainPageContainer>
    <MainPageTitle value={title} />
    <Edit {...props}>{children}</Edit>
  </MainPageContainer>
);
