import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  required,
  Toolbar,
  SaveButton,
  useEditController,
  maxLength,
  SimpleForm,
  SelectInput,
  DateInput,
} from "react-admin";

import { enabledFlags } from "../../constants/utils";
import { RaEditWrapper } from "../../components/RaEditWrapper";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            publishedAt:
              data.enabled == true || data.enabled == "true"
                ? date.toISOString()
                : null,
            url: data.url == "" ? null : data.url,
            fromDate: data.fromDate !== "" ? data.fromDate : null,
            toDate: data.toDate !== "" ? data.toDate : null,
          };

          // Clean up fields.
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
    record.fromDate = record.fromDate
      ? record.fromDate.replace("00:00:00", "12:00:00")
      : null;
    record.toDate = record.toDate
      ? record.toDate.replace("00:00:00", "12:00:00")
      : null;
  }

  return (
    <RaEditWrapper mutationMode="pessimistic">
      <SimpleForm toolbar={<EditToolbar record={record} />}>
        <TextInputCounter
          formClassName="form-text-input-field"
          source="text"
          multiline
          fullWidth
          validate={[required(), maxLength(250)]}
          limit={250}
        />

        <TextInputCounter
          source="url"
          multiline
          fullWidth
          resettable
          validate={[maxLength(250)]}
          limit={250}
        />

        <div className="form-text-input-row">
          <DateInput source="fromDate" label="Starts At" />
          <DateInput source="toDate" label="Ends At" />
        </div>

        <div className="notes">
          <p>
            - The announcement will be active until midnight of the provided
            date (at <strong>Ends At</strong>).
          </p>
          <p>
            - For example, if you want this announcement to go through the 16th,
            then the end date should be set to the 17th.
          </p>
          <p>
            - Set <strong>Ends At</strong> to empty and annoucement will be
            always valid.
          </p>
        </div>

        <SelectInput
          choices={enabledFlags}
          source="enabled"
          label="Enabled?"
          defaultValue="false"
          validate={[required()]}
        />
      </SimpleForm>
    </RaEditWrapper>
  );
};
