import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
  Edit,
  useGetOne,
  useEditController,
  maxLength,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";

const DATAPROVIDER_RESOURCE_ID = "local-recommendations";
const RESOURCE_DISPLAY_NAME = "Local Recommendations";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            coordinates: `(${data.latitude}, ${data.longitude})`,
          };

          const resourceFilesReq = {
            imageUploadId: files.imageUpload ?? data.imageUpload.id,
          };

          // Clean up fields.
          delete mappedRequest.imageUpload;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;
          delete mappedRequest.latitude;
          delete mappedRequest.longitude;
          delete mappedRequest.localRecommendationType;

          if (data.url === "") {
            delete mappedRequest.url;
          }

          return {
            ...mappedRequest,
            ...resourceFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const { id, onCreated, hasEnabled, resource, propertyId } = props;
  const notify = useNotify();

  // Grab Children Resource List.
  const dataProvider = useDataProvider();
  const [localRecommendationTypes, setLocalRecommendationTypes] =
    React.useState<any>([]);

  React.useEffect(() => {
    dataProvider
      .getMany("local-recommendation-types", { ids: [] })
      .then((response) => {
        if (response && response.data) {
          setLocalRecommendationTypes(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching local recommendation types:", error);
      });
  }, [dataProvider]);

  // Upload Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    imageUpload: null | any;
  }>({
    imageUpload: null,
  });

  const [resourceImgs, setResourceImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!resourceImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={resourceImgs[name].uri}
              title={resourceImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: "imageUpload", files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setResourceImgs({
        ...resourceImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  // Coordinates handler.
  const { record } = useEditController(props);
  const [latitude, longitude] = record ? record.coordinates : [null, null];

  if (record) {
    record.latitude = latitude;
    record.longitude = longitude;

    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <Edit
      mutationMode="pessimistic"
      resource={resource}
      id={id}
      mutationOptions={{ onSuccess: onCreated }}
      redirect={false}
    >
      <SimpleForm
        toolbar={
          <EditToolbar
            files={resourceFiles}
            record={record}
            hasEnabled={hasEnabled}
            propertyId={propertyId}
          />
        }
        id={id}
        resource={resource}
      >
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="name"
            validate={[required()]}
          />
          <TextInputCounter
            formClassName="form-text-input-field"
            source="address"
            validate={[required()]}
          />
          <TextInputCounter
            formClassName="form-text-input-field"
            source="city"
            validate={[required()]}
          />
          <TextInputCounter
            formClassName="form-text-input-field"
            source="state"
            validate={[required()]}
          />
        </div>
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="zipCode"
            validate={[required()]}
          />
          <TextInputCounter
            formClassName="form-text-input-field"
            source="url"
            validate={[]}
          />
          <SelectInput
            source="localRecommendationTypeId"
            label="Local Rec. Type"
            choices={localRecommendationTypes.map((type: any) => ({
              id: type.id,
              name: type.name,
            }))}
            emptyValue={null}
          />
        </div>

        <div className="form-text-input-row">
          <TextInputCounter
            source="latitude"
            label="Latitude"
            value={latitude}
            validate={[required()]}
          />
          <TextInputCounter
            source="longitude"
            label="Longitude"
            value={longitude}
            validate={[required()]}
          />
        </div>

        <TextInputCounter
          source="googlePlaceUrl"
          label="Google Place Url"
          validate={[maxLength(1000)]}
          limit={1000}
          fullWidth
        />

        <h4 className="heading-file-uploads">Image</h4>

        <div className="resource-icons-grid">
          <ImageInput
            source="imageUpload"
            label="Local Rec. Image (.png, .jpg, .jpeg)"
            validate={[required()]}
            accept={"image/png,image/jpg,image/jpeg"}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "imageUpload"),
            }}
          >
            {renderImgPreview("imageUpload")}
          </ImageInput>
        </div>
      </SimpleForm>
    </Edit>
  );
};
