import { ResourceProps } from "react-admin";
import Icon from "@mui/icons-material/NightShelter";
import { Resource } from "../../enums";
import ListScreen from "./list";
import { EditScreen } from "./edit";

const homePages: ResourceProps = {
  name: Resource.HOME_PAGES,
  icon: Icon,
  list: ListScreen,
  edit: EditScreen,
  options: {
    label: "Home",
  },
};

export default homePages;
