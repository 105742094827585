import { ResourceProps } from "react-admin";
import { Resource } from "../../enums";
import PropertiesList from "./PropertiesList";
import { PropertiesCreate } from "./PropertiesCreate";
import { PropertiesEdit } from "./PropertiesEdit";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

const properties: ResourceProps = {
  name: Resource.PROPERTIES,
  icon: HomeOutlinedIcon,
  list: PropertiesList,
  create: PropertiesCreate,
  edit: PropertiesEdit,
  options: {
    label: "Properties",
  },
};

export default properties;
