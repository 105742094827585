import { ReactElement } from "react";
import { Create, CreateProps } from "react-admin";

import { MainPageContainer } from "./MainPageContainer";
import { MainPageTitle } from "./MainPageTitle";

export type RaCreateWrapperProps = CreateProps & { children: ReactElement };

export const RaCreateWrapper = ({
  title,
  children,
  ...props
}: RaCreateWrapperProps) => (
  <MainPageContainer>
    <MainPageTitle value={title} />
    <Create {...props}>{children}</Create>
  </MainPageContainer>
);
