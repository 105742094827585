import { ResourceProps } from "react-admin";
import Icon from "@mui/icons-material/NightShelter";
import { Resource } from "../../enums";
import ActivitiesList from "./ActivitiesList";
import { ActivitiesCreate } from "./ActivitiesCreate";
import { ActivitiesEdit } from "./ActivitiesEdit";

const activities: ResourceProps = {
  name: Resource.ACTIVITIES,
  icon: Icon,
  list: ActivitiesList,
  create: ActivitiesCreate,
  edit: ActivitiesEdit,
  options: {
    label: "Activities",
  },
};

export default activities;
