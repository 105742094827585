import {
  ReactElement,
  Children,
  cloneElement,
  isValidElement,
  useCallback,
} from "react";
import { Datagrid, DatagridProps } from "react-admin";

import NorthRoundedIcon from "@mui/icons-material/NorthRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";

import { ClickableIcon } from "./ClickableIcon";

export type MovingItemPayload = {
  id: any;
  data: {
    order: number;
  };
};

export type OrderableDatagridProps = DatagridProps & {
  items: any[];
  subItemKey?: string;
  isLoading?: boolean;
  onUpdateOrder: (movedItemsPayload: MovingItemPayload[]) => void;
};

export const OrderableDatagrid = ({
  items,
  subItemKey,
  isLoading,
  children,
  onUpdateOrder,
  ...props
}: OrderableDatagridProps) => {
  const onMoveItemClick = useCallback(
    (record: any, fromIndex: number, toIndex: number) => {
      if (isLoading) {
        return;
      }

      const { id, parentId, parentIndex } = record;

      const itemsArr = !parentId
        ? items
        : items[parentIndex]?.[subItemKey ?? "items"];
      const swappingRecord = itemsArr[toIndex];

      onUpdateOrder([
        {
          id,
          data: {
            order: toIndex,
          },
        },
        {
          id: swappingRecord.id,
          data: {
            order: fromIndex,
          },
        },
      ]);
    },
    [items, subItemKey, isLoading, onUpdateOrder],
  );

  const renderOrderActions = (record: any) => {
    const { parentId, index, parentIndex } = record;
    const isSubItem = !!parentId;

    const isFirst = index === 0;
    const isLast = !isSubItem
      ? index === items.length - 1
      : index === items[parentIndex]?.[subItemKey ?? "items"].length - 1;

    const iconColor = isLoading ? "gray" : undefined;
    const additionalStyles = !isLoading ? {} : { cursor: "not-allowed" };

    return (
      <>
        <ClickableIcon
          component={NorthRoundedIcon}
          $display={!isFirst}
          $color={iconColor}
          fontSize="small"
          style={{
            ...additionalStyles,
            marginLeft: isSubItem ? "64px" : undefined,
          }}
          onClick={() =>
            !isFirst && !isLoading && onMoveItemClick(record, index, index - 1)
          }
        />
        <ClickableIcon
          component={SouthRoundedIcon}
          $display={!isLast}
          $color={iconColor}
          fontSize="small"
          style={{ ...additionalStyles, marginLeft: "12px" }}
          onClick={() =>
            !isLast && !isLoading && onMoveItemClick(record, index, index + 1)
          }
        />
      </>
    );
  };

  return (
    <Datagrid {...props}>
      {Children.map(children, (child, index) => {
        if (index > 0 || !isValidElement(child)) {
          return child;
        }

        return cloneElement(child as ReactElement<any>, {
          render: (record: any) => (
            <>
              {renderOrderActions(record)} {child.props?.render?.(record)}
            </>
          ),
        });
      })}
    </Datagrid>
  );
};
