import { AuthProvider } from "react-admin";
import Cookies from "js-cookie";
import axios from "./clients/axios";
import decodeJwt from "jwt-decode";

type Creds = {
  email: string;
  password: string;
};

const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: async (creds: Creds) => {
    try {
      const request = await axios.post("/users/login", creds, {
        headers: { "X-Api-Key": process.env.REACT_APP_LOGE_API_KEY! },
      });
      if (request.data) {
        const { accessToken, refreshToken } = request.data;
        const decodedToken: any = decodeJwt(accessToken);
        const userData = await axios.get("users/me", {
          headers: {
            "X-Api-Key": process.env.REACT_APP_LOGE_API_KEY!,
            Authorization: `Bearer ${accessToken}`,
          },
        });
        Cookies.set(
          "userName",
          `${userData.data.firstName} ${userData.data.lastName}`,
        );
        Cookies.set("userEmail", userData.data.email);
        Cookies.set("accessToken", accessToken);
        Cookies.set("refreshToken", refreshToken);
        Cookies.set("roles", decodedToken.roles);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (e: any) {
      return Promise.reject(e?.response?.data?.message ?? "Error logging in");
    }
  },
  // called when the user clicks on the logout button
  logout: () => {
    Cookies.remove("accessToken");
    Cookies.remove("userEmail");
    Cookies.remove("userName");
    Cookies.remove("refreshToken");
    Cookies.remove("roles");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: (error: any) => {
    const { status, message } = error;

    const isJwtExpired = String(message).toLowerCase().includes("jwtexpired");

    if (status === 401 || status === 403 || isJwtExpired) {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("roles");

      return Promise.reject();
    }

    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return Cookies.get("accessToken") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const roles = Cookies.get("roles");
    return roles ? Promise.resolve(roles) : Promise.reject();
  },
};

export default authProvider;
