import { MouseEvent, useCallback, useMemo, useState } from "react";
import { SaveButton } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { UsualResourceItem } from "./ResourceEditor";

export type SelectCreateScreenProps<ResourceItemType> = {
  resources: ResourceItemType[];
  label?: string;
  onSave: (
    resources: ResourceItemType[],
    selectedResource: ResourceItemType,
  ) => Promise<void>;
  getSelectOptions: (resources: ResourceItemType[]) => ResourceItemType[];
  onCreated?: () => Promise<void>;
};

export const SelectCreateScreen = <T extends UsualResourceItem>({
  resources,
  label,
  onSave,
  getSelectOptions,
  onCreated,
}: SelectCreateScreenProps<T>) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  const options = useMemo(
    () => getSelectOptions(resources),
    [resources, getSelectOptions],
  );

  const handleSelectOptionChange = useCallback(
    (e: SelectChangeEvent<string>) => setSelectedOption(e.target.value),
    [],
  );

  const handleSaveChanges = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      await onSave(
        resources,
        options.find((item) => item.id === selectedOption) as T,
      );
      onCreated?.();
    },
    [resources, options, selectedOption, onCreated, onSave],
  );

  return (
    <>
      <FormControl size="small" sx={{ margin: 0 }}>
        {label ? (
          <InputLabel htmlFor="select-create-screen-control">
            {label}
          </InputLabel>
        ) : null}
        <Select
          name="select-create-screen-control"
          value={selectedOption}
          onChange={handleSelectOptionChange}
        >
          {options.map((item: T) => {
            return (
              <MenuItem value={item.id} key={item.id}>
                {item.title ?? item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <SaveButton
        alwaysEnable={selectedOption !== ""}
        type="button"
        sx={{ width: "fit-content" }}
        onClick={handleSaveChanges}
      />
    </>
  );
};
