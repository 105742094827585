import { useCallback, useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import "./AttendeeList.css";

const AttendeeList = ({ eventId, event }: { eventId: string; event: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const dataProvider = useDataProvider();

  const [attendees, setAttendees] = useState<any[]>([]);

  useEffect(() => {
    const fetchAttendees = async () => {
      try {
        setLoading(true);

        const { data } = await dataProvider.getEventAttendees({
          eventId: eventId,
        });

        setAttendees(data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchAttendees();
  }, []);

  if (loading) {
    return (
      <div className="attendee-list">
        <h1 className="events-title">Attendees List for "{event.name}"</h1>
        <div className="list-wrapper">
          <p>Loading list...</p>
        </div>
      </div>
    );
  }

  if (attendees.length === 0) {
    return (
      <div className="attendee-list">
        <h1 className="events-title">Attendees List for "{event.name}"</h1>
        <div className="list-wrapper">
          <p>No attendees for this event.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="attendee-list">
      <h1 className="events-title">
        Attendees List for "{event.name}" - Coming: {event.attendees} Max.:{" "}
        {event.maxAttendees}
      </h1>
      <div className="list-wrapper">
        {attendees.map((a: any, i) => {
          return (
            <div className="list-item">
              <span className="num">{i + 1}</span>
              <span>
                {a.user?.lastName}, {a.user?.firstName}{" "}
                {a.user.email ? `(${a.user?.email})` : ""} - Guests:{" "}
                {a.numOfAttendees}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AttendeeList;
