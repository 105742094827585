import { styled } from "styled-components";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";

export const Heading = styled(Typography)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
`;

export const StyledItemRow = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e5e5e5;
`;

export const ItemText = styled(Typography)<{ $disabled?: boolean }>`
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  flex-grow: 1;

  ${(p) =>
    !!p.$disabled &&
    `
    text-decoration: line-through;
    color: #999;
  `}
`;

export const EditControl = styled(Typography)`
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  color: black;
  cursor: pointer;
  visibility: hidden;

  ${StyledItemRow}:hover & {
    visibility: visible;
  }
`;

export const StyledIcon = styled(Icon)<{
  component: any;
  $display: boolean;
  $color?: string;
}>`
  color: ${(p) => p.$color ?? "#19988b"};
  visibility: hidden;
  ${(p) =>
    !!p.$display &&
    `
    cursor: pointer;
    visibility: visible;
    `}
`;
