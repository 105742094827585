import * as React from "react";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useGetOne,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";
import { RaEditWrapper } from "../../components/RaEditWrapper";

interface ILodgingTypeEdit {
  [key: string]: any;
}

const LodgingTypeEditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: ILodgingTypeEdit) => {
          const mappedRequest = {
            name: data.name,
            enabled: data.enabled,
          };

          const lodgingTypesFilesReq = {
            iconUploadId: files.iconUpload ?? data.iconUpload.id,
          };

          return {
            ...mappedRequest,
            ...lodgingTypesFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const LodgingTypesEdit = () => {
  const { id } = useParams();
  const { data } = useGetOne("/lodging-types", { id });
  const notify = useNotify();

  const lodgingTypeName = data ? data.name : "";

  // Activities Files
  const [lodgingTypesFiles, setLodgingTypeFiles] = React.useState<{
    iconUpload: null | any;
  }>({
    iconUpload: null,
  });

  const [logdingTypeImgs, setLodgingTypeImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!logdingTypeImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={logdingTypeImgs[name].uri}
              title={logdingTypeImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: "iconUpload", files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setLodgingTypeImgs({
        ...logdingTypeImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setLodgingTypeFiles({ ...lodgingTypesFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  return (
    <RaEditWrapper mutationMode="pessimistic" title={lodgingTypeName}>
      <SimpleForm
        toolbar={<LodgingTypeEditToolbar files={lodgingTypesFiles} />}
      >
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="name"
            validate={[required()]}
          />
          <SelectInput
            choices={enabledFlags}
            source="enabled"
            label="Enabled?"
            defaultValue="false"
            validate={[required()]}
          />
        </div>

        <h4 className="heading-file-uploads">Icon</h4>

        <div className="lodging-type-icons-grid">
          <ImageInput
            source="iconUpload"
            label="Lodging Type Icon (.png, .jpg, .jpeg)"
            validate={[required()]}
            accept={"image/png,image/jpg,image/jpeg"}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "iconUpload"),
            }}
          >
            {renderImgPreview("iconUpload")}
          </ImageInput>
        </div>
      </SimpleForm>
    </RaEditWrapper>
  );
};
