import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useInput, FieldTitle } from "ra-core";

import { CommonInputProps } from "react-admin";
import { ResettableTextField, ResettableTextFieldProps } from "react-admin";
import { InputHelperText } from "react-admin";
import { sanitizeInputRestProps } from "react-admin";
import { useState } from "react";

/**
 * An Input component for a string
 *
 * @example
 * <TextInputCounter source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInputCounter source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 */
export const TextInputCounter = (props: TextInputProps) => {
  const {
    className,
    defaultValue,
    label,
    format,
    helperText,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    limit,
    ...rest
  } = props;

  const moddedOnChange = (event: any) => {

    if (onChange) {
      onChange(event);
    }
  };

  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: "text",
    validate,
    onBlur,
    onChange: moddedOnChange,
    ...rest,
  });

  const [count, setCount] = useState(
    limit ? (field.value ? field.value.length : 0) : null,
  );

  React.useEffect(() => {
    if(limit){
      setCount(field.value ? field.value.length : 0)
    }
  },[field.value, limit])
  const renderHelperText =
    helperText !== false || ((isTouched || isSubmitted) && invalid);

  return (
    <ResettableTextField
      id={id}
      {...field}
      className={clsx("ra-input", `ra-input-${source}`, className)}
      label={
        label !== "" && label !== false ? (
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        ) : null
      }
      error={(isTouched || isSubmitted) && invalid}
      helperText={
        <>
          {renderHelperText ? (
            <InputHelperText
              touched={isTouched || isSubmitted}
              error={error?.message}
              helperText={helperText}
            />
          ) : null}
          {limit ? (
            <span className="counter">
              {count}/{limit}
            </span>
          ) : null}
        </>
      }
      {...sanitizeInputRestProps(rest)}
    />
  );
};

TextInputCounter.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  resource: PropTypes.string,
  source: PropTypes.string,
};

export type TextInputProps = CommonInputProps & {
  limit?: undefined | null | number;
} & Omit<ResettableTextFieldProps, "label" | "helperText">;
