import { ReactNode } from "react";
import { ListContextProvider, ListControllerResult } from "react-admin";

import { MainPageContainer } from "./MainPageContainer";
import { MainPageTitle } from "./MainPageTitle";

export type RaListContextProviderWrapperProps = {
  title?: string;
  value: ListControllerResult;
  children: ReactNode;
};

export const RaListContextProviderWrapper = ({
  title,
  children,
  ...props
}: RaListContextProviderWrapperProps) => (
  <MainPageContainer className="RaList-main">
    <MainPageTitle value={title} />
    <ListContextProvider {...props}>{children}</ListContextProvider>
  </MainPageContainer>
);
