import { ResourceProps } from "react-admin";
import Icon from "@mui/icons-material/NightShelter";
import { Resource } from "../../enums";
import LodgingTypesList from "./LodgingTypesList";
import { LodgingTypesCreate } from "./LodgingTypesCreate";
import { LodgingTypesEdit } from "./LodgingTypesEdit";

const lodgingTypes: ResourceProps = {
  name: Resource.LODGING_TYPES,
  icon: Icon,
  list: LodgingTypesList,
  create: LodgingTypesCreate,
  edit: LodgingTypesEdit,
  options: {
    label: "Lodging Types",
  },
};

export default lodgingTypes;
