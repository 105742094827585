import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../TextInputCounter/TextInputCounter";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
  maxLength,
  DateInput,
} from "react-admin";
import { enabledFlags, promoTypes } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";
import { hexColorRequired } from "../../utils/validators";

interface ICreate {
  [key: string]: any;
}

const CreateToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Create"
        style={{ top: 54 }}
        transform={({ ...data }: ICreate) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            publishedAt:
              data.enabled == true || data.enabled == "true"
                ? date.toISOString()
                : null,
            fromDate: data.fromDate !== "" ? data.fromDate : null,
            toDate: data.toDate !== "" ? data.toDate : null,
          };

          delete mappedRequest.enabled;

          if (!files.imageUploadId) {
            delete files.imageUploadId;
          }

          return {
            ...mappedRequest,
            ...files,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const CreateScreen = (props: any) => {
  const { onCreated, hasEnabled, resource } = props;
  const notify = useNotify();

  const onSuccess = (data: any) => {
    notify(`Changes saved`);
    onCreated(data.id);
  };

  // Resource Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    imageUploadId: null | any;
  }>({
    imageUploadId: null,
  });

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: "imageUploadId", files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title } = useRecordContext();

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.children;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  const onFileRemoval = (target: string, _file: any) => {
    setResourceFiles({ ...resourceFiles, [target]: null });
    return true; // is handled from frontend
  };

  return (
    <Create
      resource={resource}
      redirect={false}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm
        toolbar={
          <CreateToolbar files={resourceFiles} hasEnabled={hasEnabled} />
        }
      >
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="name"
            validate={[required(), maxLength(100)]}
            limit={100}
          />
          <TextInputCounter
            formClassName="form-text-input-field"
            source="code"
            validate={[required(), maxLength(50)]}
          />

          <SelectInput
            choices={promoTypes}
            source="type"
            label="Type"
            defaultValue="rate"
            validate={[required()]}
          />

          <SelectInput
            choices={enabledFlags}
            source="enabled"
            label="Enabled?"
            defaultValue="false"
            validate={[required()]}
          />
        </div>

        <div className="form-text-input-row">
          <DateInput source="fromDate" label="Starts At" />
          <DateInput source="toDate" label="Ends At" />
        </div>

        <div className="notes">
          <p>
            - The promo will be active until midnight of the provided date (at{" "}
            <strong>Ends At</strong>).
          </p>
          <p>
            - For example, if you want this promo to go through the 16th, then
            the end date should be set to the 17th.
          </p>
          <p>
            - Set <strong>Ends At</strong> to empty and promo will be always
            valid.
          </p>
        </div>

        <TextInputCounter
          source="description"
          multiline
          fullWidth
          resettable
          validate={[required(), maxLength(140)]}
          limit={140}
        />

        <h4 className="heading-file-uploads">Image</h4>

        <div className="resource-icons-grid">
          <ImageInput
            source="imageUploadId"
            label="Promotion Picture (.png, .jpg, .jpeg)"
            validate={[]}
            accept={"image/png,image/jpg,image/jpeg"}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "imageUploadId"),
            }}
            validateFileRemoval={(file: any) =>
              onFileRemoval("imageUploadId", file)
            }
          >
            <FileFieldWithContext>
              <ImageField source="src" title="title" />
            </FileFieldWithContext>
          </ImageInput>
        </div>
      </SimpleForm>
    </Create>
  );
};
