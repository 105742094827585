import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";
import { RaCreateWrapper } from "../../components/RaCreateWrapper";

interface ICreate {
  [key: string]: any;
}

const CreateToolbar = ({ ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: ICreate) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
          };

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const CreateScreen = () => {
  const notify = useNotify();

  return (
    <RaCreateWrapper title="New Tag" redirect="list">
      <SimpleForm toolbar={<CreateToolbar />}>
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="name"
            validate={[required()]}
          />
        </div>
      </SimpleForm>
    </RaCreateWrapper>
  );
};
