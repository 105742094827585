import { styled } from "styled-components";

import Icon from "@mui/material/Icon";

export const ClickableIcon = styled(Icon)<{
  component: any;
  $display: boolean;
  $color?: string;
}>`
  color: ${(p) => p.$color ?? "#19988b"};
  visibility: hidden;
  ${(p) =>
    !!p.$display &&
    `
    cursor: pointer;
    visibility: visible;
    `}
`;
