import * as React from "react";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
  useGetOne,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";
import { useEffect } from "react";
import { RaEditWrapper } from "../../components/RaEditWrapper";

interface IActivityEdit {
  [key: string]: any;
}

const ActivityEditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IActivityEdit) => {
          const mappedRequest = {
            name: data.name,
            parentId: data.parentId === "" ? null : data.parentId,
            enabled: data.enabled == true || data.enabled == "true",
          };

          const activityFilesReq = {
            iconId: files.icon ?? data.icon.id,
          };

          return {
            ...mappedRequest,
            ...activityFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const ActivitiesEdit = () => {
  const dataProvider = useDataProvider();
  const { id } = useParams();
  const { data } = useGetOne("/activities", { id });
  const notify = useNotify();

  const activityName = data ? data.name : "";

  const [parentActivities, setParentActivities] = React.useState<
    {
      id: null | any;
      name: string;
    }[]
  >([]);

  useEffect(() => {
    const fetchParentActivities = async () => {
      const { data } = await dataProvider.getParentActivities();

      const mappedParentActivities = data.map((parentActv: any) => {
        return {
          id: parentActv.id,
          name: parentActv.name,
        };
      });

      setParentActivities(mappedParentActivities);
    };
    fetchParentActivities();
  }, []);

  // Activities Files
  const [activityFiles, setActivityFiles] = React.useState<{
    icon: null | any;
  }>({
    icon: null,
  });

  const [activityImgs, setActivityImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!activityImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={activityImgs[name].uri}
              title={activityImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        å
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: "icon", files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setActivityImgs({
        ...activityImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setActivityFiles({ ...activityFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  return (
    <RaEditWrapper mutationMode="pessimistic" title={activityName}>
      <SimpleForm toolbar={<ActivityEditToolbar files={activityFiles} />}>
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="name"
            validate={[required()]}
          />
          <SelectInput
            choices={parentActivities}
            source="parentId"
            label="Category"
            validate={[]}
          />
          <SelectInput
            choices={enabledFlags}
            source="enabled"
            label="Enabled?"
            defaultValue="false"
            helperText="If you Disable a Category, all children activities will be disabled."
            validate={[required()]}
          />
        </div>

        <h4 className="heading-file-uploads">Icon</h4>

        <div className="activity-icons-grid">
          <ImageInput
            source="icon"
            label="Activity Icon (.png, .jpg, .jpeg)"
            validate={[required()]}
            accept={"image/png,image/jpg,image/jpeg"}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "icon"),
            }}
          >
            {renderImgPreview("icon")}
          </ImageInput>
        </div>
      </SimpleForm>
    </RaEditWrapper>
  );
};
