import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import ReviewsManager from "../../components/reviews-manager/ReviewsManager";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import "./styles.css";

const ReviewsList = () => {
  const dataProvider = useDataProvider();
  const [properties, setProperties] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [currentProperty, setCurrentProperty] = useState<string | null>(null);
  const [reviews, setReviews] = useState<any[]>([]);
  const [loadingReviews, setLoadingReviews] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const { data } = await dataProvider.getMany("properties", { ids: [] });

        const filteredProperties = data.filter(
          (p) => p.revinateKey && p.revinateKey !== "",
        );

        setProperties(filteredProperties);

        if (filteredProperties.length > 0) {
          setCurrentProperty(filteredProperties[0].id);
        }
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchProperties();
  }, []);

  useEffect(() => {
    const fetchReviews = async () => {
      console.log("fetching reviews for " + currentProperty);
      setLoadingReviews(true);

      const { data } =
        await dataProvider.getAllPropertyReviews(currentProperty);

      setReviews(data);

      setLoadingReviews(false);
    };

    if (currentProperty !== null) {
      fetchReviews();
    }
  }, [currentProperty]);

  const onAfterImport = async () => {
    const { data } = await dataProvider.getAllPropertyReviews(currentProperty);

    setReviews(data);
  };

  if (loading) {
    return <div className="reviews-wrapper">Loading active properties...</div>;
  }

  const onSingleReviewChange = (id: string, payload: any) => {
    setReviews((prev) => {
      let item = prev.find((r) => r.id === id);

      item.publishedAt = payload.publishedAt;

      return [...prev];
    });
  };

  return (
    <div className="reviews-wrapper">
      <div>
        <FormControl sx={{ m: 0, minWidth: 120, mb: "20px" }} size="small">
          <Select
            onChange={(e) => {
              setCurrentProperty(e.target.value);
            }}
            value={currentProperty}
          >
            {properties.map((p: any) => {
              return (
                <MenuItem value={p.id} key={p.id}>
                  {`${p.name} / ${p.locality}, ${p.area}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div>
        <ReviewsManager
          reviews={reviews}
          isLoading={loadingReviews}
          onSingleReviewChange={onSingleReviewChange}
          currentProperty={currentProperty}
          onAfterImport={onAfterImport}
        />
      </div>
    </div>
  );
};

export default ReviewsList;
