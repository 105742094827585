import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  required,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useEditController,
  maxLength,
  TabbedForm,
  FormTab,
} from "react-admin";
import useFileUpload from "../../hooks/useFileUpload";
import { ResourceEditor } from "../../components/ResourceEditor/ResourceEditor";

import { CreateScreen as AboutUsPagesQuotesCreate } from "../../components/about-us-pages-quotes/create";
import { EditScreen as AboutUsPagesQuotesEdit } from "../../components/about-us-pages-quotes/edit";
import { RaEditWrapper } from "../../components/RaEditWrapper";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
          };

          const resourceFilesReq = {
            heroUploadId: files.heroUpload ?? data.heroUpload.id,
            whySubsection1ImageUploadId:
              files.whySubsection1ImageUpload ??
              data.whySubsection1ImageUpload.id,
            whySubsection2ImageUploadId:
              files.whySubsection2ImageUpload ??
              data.whySubsection2ImageUpload.id,
            whySubsection3ImageUploadId:
              files.whySubsection3ImageUpload ??
              data.whySubsection3ImageUpload.id,
            partnerSectionImageUploadId:
              files.partnerSectionImageUpload ??
              data.partnerSectionImageUpload.id,
            developSectionImageUploadId:
              files.developSectionImageUpload ??
              data.developSectionImageUpload.id,
          };

          // Clean up fields.
          delete mappedRequest.heroUpload;
          delete mappedRequest.whySubsection1ImageUpload;
          delete mappedRequest.whySubsection2ImageUpload;
          delete mappedRequest.whySubsection3ImageUpload;
          delete mappedRequest.partnerSectionImageUpload;
          delete mappedRequest.developSectionImageUpload;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
            ...resourceFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const notify = useNotify();

  // Upload Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    heroUpload: null | any;
    whySubsection1ImageUpload: null | any;
    whySubsection2ImageUpload: null | any;
    whySubsection3ImageUpload: null | any;
    partnerSectionImageUpload: null | any;
    developSectionImageUpload: null | any;
  }>({
    heroUpload: null,
    whySubsection1ImageUpload: null,
    whySubsection2ImageUpload: null,
    whySubsection3ImageUpload: null,
    partnerSectionImageUpload: null,
    developSectionImageUpload: null,
  });

  const [resourceImgs, setResourceImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!resourceImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={resourceImgs[name].uri}
              title={resourceImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: string, files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setResourceImgs({
        ...resourceImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <RaEditWrapper mutationMode="pessimistic">
      <TabbedForm
        toolbar={<EditToolbar files={resourceFiles} record={record} />}
      >
        <FormTab label="Hero">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="heroHeading"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="heroUpload"
              label="Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "heroUpload"),
              }}
            >
              {renderImgPreview("heroUpload")}
            </ImageInput>
          </div>
        </FormTab>

        <FormTab label="Why">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="whySectionTitle"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="whySectionSubtitle"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="whySectionDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="whySubsection1ImageUpload"
              label="Why Section #1 Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "whySubsection1ImageUpload",
                ),
              }}
            >
              {renderImgPreview("whySubsection1ImageUpload")}
            </ImageInput>
          </div>

          <TextInputCounter
            formClassName="form-text-input-field"
            source="whySubsection1Title"
            label="Section #1 Title"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="whySubsection1Description"
            label="Section #1 Description"
            multiline
            fullWidth
            validate={[required(), maxLength(500)]}
            limit={500}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="whySubsection2ImageUpload"
              label="Why Section #2 Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "whySubsection2ImageUpload",
                ),
              }}
            >
              {renderImgPreview("whySubsection2ImageUpload")}
            </ImageInput>
          </div>

          <TextInputCounter
            formClassName="form-text-input-field"
            source="whySubsection2Title"
            label="Section #2 Title"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="whySubsection2Description"
            label="Section #2 Description"
            multiline
            fullWidth
            validate={[required(), maxLength(500)]}
            limit={500}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="whySubsection3ImageUpload"
              label="Why Section #3 Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "whySubsection3ImageUpload",
                ),
              }}
            >
              {renderImgPreview("whySubsection3ImageUpload")}
            </ImageInput>
          </div>

          <TextInputCounter
            formClassName="form-text-input-field"
            source="whySubsection3Title"
            label="Section #3 Title"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="whySubsection3Description"
            label="Section #3 Description"
            multiline
            fullWidth
            validate={[required(), maxLength(500)]}
            limit={500}
          />
        </FormTab>

        <FormTab label="Partner">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="partnerSectionTitle"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="partnerSectionDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="partnerSectionImageUpload"
              label="Partner Section Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "partnerSectionImageUpload",
                ),
              }}
            >
              {renderImgPreview("partnerSectionImageUpload")}
            </ImageInput>
          </div>

          <TextInputCounter
            formClassName="form-text-input-field"
            source="partnerSectionBullet1Text"
            label="Partner Bullet #1"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="partnerSectionBullet2Text"
            label="Partner Bullet #2"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="partnerSectionBullet3Text"
            label="Partner Bullet #3"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="partnerSectionBullet4Text"
            label="Partner Bullet #4"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />
        </FormTab>

        <FormTab label="Develop">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="developSectionTitle"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="developSectionDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="developSectionImageUpload"
              label="Develop Section Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "developSectionImageUpload",
                ),
              }}
            >
              {renderImgPreview("developSectionImageUpload")}
            </ImageInput>
          </div>

          <TextInputCounter
            formClassName="form-text-input-field"
            source="developSectionBullet1Text"
            label="Develop Bullet #1"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="developSectionBullet2Text"
            label="Develop Bullet #2"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="developSectionBullet3Text"
            label="Develop Bullet #3"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="developSectionBullet4Text"
            label="Develop Bullet #4"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />
        </FormTab>

        <FormTab label="Friends">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="friendsSectionTitle"
            multiline
            fullWidth
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="friendsSectionDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />
        </FormTab>

        <FormTab label="Quotes">
          <ResourceEditor
            resourceId={"about-us-page-quotes"}
            createNewLabel="Add New Quote"
            updatedOrderMsg="Quote order updated."
            updatingOrderMsg="Updating quote order..."
            heading="Quotes"
            renderName={(resource: any) => `${resource.text.substr(0, 100)}...`}
            CreateScreen={AboutUsPagesQuotesCreate}
            EditScreen={AboutUsPagesQuotesEdit}
            hasOrder={false}
            hasEnabled={false}
          />
        </FormTab>
      </TabbedForm>
    </RaEditWrapper>
  );
};
