import { ReactNode } from "react";
import { styled } from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 48px;
  line-height: 56px;
  font-weight: 300;
  letter-spacing: -0.03em;
  padding: 0 0 32px 0 !important;
  border-bottom: 1px solid #d6d6d6;
`;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-container > .MuiPaper-root {
    padding: 32px;
    gap: 32px;

    & > div {
      margin: 0;
      padding: 0;
    }
  }

  & .create-page > .RaCreate-main,
  & .edit-page > .RaEdit-main {
    margin: 0;
  }

  & .create-page .MuiPaper-root,
  & .edit-page .MuiPaper-root {
    box-shadow: none;
  }
`;

export type ResourceEditorDialogProps = {
  isOpen: boolean;
  content: ReactNode;
  onClose: () => void;
  title?: JSX.Element | string;
};

export const ResourceEditorDialog = ({
  isOpen,
  title,
  content,
  onClose,
}: ResourceEditorDialogProps) => {
  return (
    <StyledDialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      {title && <StyledDialogTitle>{title}</StyledDialogTitle>}
      {content}
    </StyledDialog>
  );
};
