import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  required,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useEditController,
  maxLength,
  TabbedForm,
  FormTab,
} from "react-admin";
import useFileUpload from "../../hooks/useFileUpload";
import { ResourceEditor } from "../../components/ResourceEditor/ResourceEditor";

import { CreateScreen as AmbassadorsCreate } from "../../components/ambassadors/create";
import { EditScreen as AmbassadorsEdit } from "../../components/ambassadors/edit";

import { CreateScreen as ArtistsCreate } from "../../components/artists/create";
import { EditScreen as ArtistsEdit } from "../../components/artists/edit";

import { CreateScreen as ArtsCreate } from "../../components/artist-arts/create";
import { EditScreen as ArtsEdit } from "../../components/artist-arts/edit";
import { RaEditWrapper } from "../../components/RaEditWrapper";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
          };

          const resourceFilesReq = {
            heroUploadId: files.heroUpload ?? data.heroUpload.id,
          };

          // Clean up fields.
          delete mappedRequest.heroUpload;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
            ...resourceFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const notify = useNotify();

  // Upload Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    heroUpload: null | any;
  }>({
    heroUpload: null,
  });

  const [resourceImgs, setResourceImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!resourceImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={resourceImgs[name].uri}
              title={resourceImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: string, files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setResourceImgs({
        ...resourceImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <RaEditWrapper mutationMode="pessimistic">
      <TabbedForm
        toolbar={<EditToolbar files={resourceFiles} record={record} />}
      >
        <FormTab label="Top">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="heroHeading"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="heroUpload"
              label="Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "heroUpload"),
              }}
            >
              {renderImgPreview("heroUpload")}
            </ImageInput>
          </div>
        </FormTab>

        <FormTab label="Ambassadors">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="ambassadorSectionTitle"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="ambassadorSectionDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />

          <ResourceEditor
            resourceId={"ambassadors"}
            createNewLabel="Add New Ambassador"
            updatedOrderMsg="Ambassador order updated."
            updatingOrderMsg="Updating ambassador order..."
            heading="Ambassadors"
            renderName={(resource: any) => `${resource.name}`}
            CreateScreen={AmbassadorsCreate}
            EditScreen={AmbassadorsEdit}
            hasOrder={true}
            hasEnabled={false}
          />
        </FormTab>

        <FormTab label="Artists">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="artistSectionTitle"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="artistSectionDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />

          <ResourceEditor
            resourceId={"artists"}
            createNewLabel="Add New Artist"
            updatedOrderMsg="Artist order updated."
            updatingOrderMsg="Updating artist order..."
            heading="Artists"
            renderName={(resource: any) => `${resource.name}`}
            CreateScreen={ArtistsCreate}
            EditScreen={ArtistsEdit}
            hasOrder={true}
            hasEnabled={false}
          />
        </FormTab>

        <FormTab label="Arts">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="artSectionTitle"
            fullWidth
            multiline
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <TextInputCounter
            source="artSectionDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />

          <ResourceEditor
            resourceId={"artist-arts"}
            createNewLabel="Add New Art"
            updatedOrderMsg="Art order updated."
            updatingOrderMsg="Updating art order..."
            heading="Arts"
            renderName={(resource: any) =>
              `${resource.description.substr(0, 100)}...`
            }
            CreateScreen={ArtsCreate}
            EditScreen={ArtsEdit}
            hasOrder={false}
            hasEnabled={false}
          />
        </FormTab>
      </TabbedForm>
    </RaEditWrapper>
  );
};
