import { useEffect, useState } from "react";
import "./HGearsEditor.css";
import {
  Button,
  useDataProvider,
  useNotify,
  Confirm,
  useDelete,
} from "react-admin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Grid";

export const HGearsEditor = (props: any) => {
  const {
    createNewLabel,
    resource,
    updatedOrderMsg,
    updatingOrderMsg,
    CreateScreen,
    EditScreen,
    hasOrder,
    hasEnabled,
    heading,
    propertyId,
    limit,
  } = props;

  const [tab, setTab] = useState<"list" | "edit" | "create">("list");
  const [editedId, setEditedId] = useState<string>("");
  const [deletedId, setDeletedId] = useState<string | null>(null);

  const [open, setOpen] = useState(false);

  const [remove, { isLoading }] = useDelete();

  const handleDeleteClick = (id: string) => {
    setDeletedId(id);
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    const ids = resources
      .filter((r: any) => r.id !== deletedId)
      .map((r: any) => r.id);

    const { data } = await dataProvider.updatePropertyHighlightedGears(
      propertyId,
      {
        highlightedGears: ids,
      },
    );

    fetchResources();
    setOpen(false);
    setDeletedId(null);
  };

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [resources, setResources] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchResources = async () => {
    try {
      setLoading(true);

      const { data } = await dataProvider.getPropertyHGears({
        propertyId: propertyId,
      });

      setResources(data);
    } catch (err: any) {
      notify(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  const moveMainItem = async (index: number, direction: "up" | "down") => {
    const movedResources = [...resources];

    if (direction === "up" && index > 0 && index < movedResources.length) {
      const removedItem = movedResources.splice(index, 1)[0];
      movedResources.splice(index - 1, 0, removedItem);
    }

    if (
      direction === "down" &&
      index >= 0 &&
      index < movedResources.length - 1
    ) {
      const removedItem = movedResources.splice(index, 1)[0];
      movedResources.splice(index + 1, 0, removedItem);
    }

    setResources(movedResources);

    await updateResourcesOrder(movedResources);
  };

  const removeItem = async (index: number, id: string) => {
    handleDeleteClick(id);
  };

  const updateResourcesOrder = async (movedResources: any[]) => {
    notify(updatingOrderMsg);

    const ids = movedResources.map((r: any) => r.id);

    const { data } = await dataProvider.updatePropertyHighlightedGears(
      propertyId,
      {
        highlightedGears: ids,
      },
    );

    notify(updatedOrderMsg);
  };

  const onCreated = async (id: any) => {
    if (!id.id) {
      const ids = resources.map((r: any) => r.id);

      const { data } = await dataProvider.updatePropertyHighlightedGears(
        propertyId,
        {
          highlightedGears: [...ids, id],
        },
      );
    }

    fetchResources();
    changeTab("list");
  };

  const changeTab = (newTab: "list" | "edit" | "create", id?: string) => {
    if (id) {
      setEditedId(id);
    }

    setTab(newTab);
  };

  const orderButtons = (index: number, isFirst: boolean, isLast: boolean) => {
    if (!hasOrder) {
      return null;
    }

    return (
      <>
        {!isFirst ? (
          <a
            className="arrow-btn solid"
            onClick={(e) => moveMainItem(index, "up")}
          >
            &larr;
          </a>
        ) : null}
        {!isLast ? (
          <a
            className="arrow-btn solid"
            onClick={(e) => moveMainItem(index, "down")}
          >
            &rarr;
          </a>
        ) : null}
      </>
    );
  };

  const renderEditor = () => {
    switch (tab) {
      case "list":
        return (
          <>
            <Box
              sx={{
                pb: 1.5,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {limit == null || limit > resources.length ? (
                <Button
                  onClick={() => changeTab("create")}
                  color="primary"
                  variant="text"
                  label={createNewLabel}
                />
              ) : (
                <Typography variant="subtitle2">Limit Reached</Typography>
              )}
            </Box>
            <Box
              sx={{
                pb: 0.5,
                mb: 0.5,
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Item>
                    <Typography variant="subtitle2">{heading}</Typography>
                  </Item>
                </Grid>
              </Grid>
            </Box>

            {loading ? (
              <Typography variant="subtitle2" color="primary">
                Loading...
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {resources.map((resource: any, index: number) => {
                  const isFirst = index === 0;
                  const isLast = index === resources.length - 1;

                  return (
                    <Grid item xs={3} key={resource.id}>
                      <Box
                        sx={{
                          py: 0.5,
                          my: 0.5,
                          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                          position: "relative",
                        }}
                      >
                        <div
                          className={`item-cover ${
                            resource.publishedAt === null
                              ? "item-cover-disabled"
                              : ""
                          }`}
                          style={{
                            backgroundImage: `url(${resource.imageUpload.uri})`,
                          }}
                        >
                          <Typography variant="subtitle2" className="actions">
                            <a
                              className="arrow-btn solid"
                              onClick={(e) => removeItem(index, resource.id)}
                            >
                              &#215;
                            </a>
                            {orderButtons(index, isFirst, isLast)}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="primary"
                            onClick={() => changeTab("edit", resource.id)}
                            sx={{
                              cursor: "pointer",
                            }}
                            className="edit"
                          ></Typography>
                        </div>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <Confirm
              isOpen={open}
              loading={isLoading}
              title={`Delete`}
              content="Are you sure you want to delete this item?"
              onConfirm={handleConfirm}
              onClose={handleDialogClose}
            />
          </>
        );
        break;
      case "create":
        return (
          <>
            <Box
              sx={{
                pb: 1.5,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => changeTab("list")}
                color="primary"
                variant="text"
                label={"BACK TO LIST"}
              />
            </Box>
            <CreateScreen
              onCreated={onCreated}
              hasEnabled={hasEnabled}
              resource={resource}
              propertyId={propertyId}
            />
          </>
        );
        break;
      case "edit":
        return (
          <>
            <Box
              sx={{
                pb: 1.5,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => changeTab("list")}
                color="primary"
                variant="text"
                label={"BACK TO LIST"}
              />
            </Box>
            <EditScreen
              id={editedId}
              resource={resource}
              onCreated={onCreated}
              hasEnabled={hasEnabled}
              propertyId={propertyId}
            />
          </>
        );
        break;

      default:
        break;
    }
  };

  return <div className="wrapper">{renderEditor()}</div>;
};
