import React, { ReactNode } from "react";
import { Button, Confirm } from "react-admin";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

type Tab = "list" | "edit" | "create";
export type GalleryProps = {
  hasOrder: boolean;
  tab: Tab;
  moveMainItem: (index: number, direction: "up" | "down") => void;
  heading: string;
  loading: boolean;
  limit: number;
  resources: any;
  changeTab: (tab: Tab, id?: string) => void;
  createNewLabel: string;
  removeItem: (index: number, id: string) => void;
  isOpen: boolean;
  isLoading: boolean;
  handleConfirm: () => void;
  handleDialogClose: () => void;
  createScreen: ReactNode;
  editScreen: ReactNode;
};
function Gallery({
  hasOrder,
  tab,
  moveMainItem,
  heading,
  loading,
  limit,
  resources,
  changeTab,
  createNewLabel,
  removeItem,
  isOpen,
  isLoading,
  handleConfirm,
  handleDialogClose,
  createScreen,
  editScreen,
}: GalleryProps) {
  const orderButtons = (index: number, isFirst: boolean, isLast: boolean) => {
    if (!hasOrder) {
      return null;
    }

    return (
      <Box display="flex" gap="10px">
        {!isFirst ? (
          <ArrowBackIcon
            style={{ fontSize: 20 }}
            className="arrow-btn solid gallery"
            onClick={(e) => moveMainItem(index, "up")}
          >
            &larr;
          </ArrowBackIcon>
        ) : null}
        {!isLast ? (
          <ArrowForwardIcon
            style={{ fontSize: 20 }}
            className="arrow-btn solid gallery"
            onClick={(e) => moveMainItem(index, "down")}
          >
            &rarr;
          </ArrowForwardIcon>
        ) : null}
      </Box>
    );
  };

  switch (tab) {
    case "list":
      return (
        <>
          <Box
            sx={{
              pb: 1.5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          ></Box>
          <Box
            sx={{
              pb: 0.5,
              mb: 3.5,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Item>
                  <Typography className="heading">{heading}</Typography>
                </Item>
              </Grid>
            </Grid>
          </Box>

          {loading ? (
            <Typography variant="subtitle2" color="primary">
              Loading...
            </Typography>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              gap={4}
              rowGap={0}
            >
              <Box
                style={{
                  marginTop: 46,
                  flexGrow: 1,
                  paddingBottom: "0px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  cursor:
                    limit == null || limit > resources.length
                      ? "pointer"
                      : "default",
                }}
                onClick={
                  limit == null || limit > resources.length
                    ? () => changeTab("create")
                    : undefined
                }
                className="item-container"
              >
                <Box
                  style={{
                    background:
                      limit == null || limit > resources.length
                        ? "#F5F5F5"
                        : "rgba(0, 0, 0, 0.1)",
                    marginTop: "8px",
                    border: "1px solid #E5E5E5",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="item-cover"
                >
                  {limit == null || limit > resources.length ? (
                    <>
                      {" "}
                      <AddIcon style={{ fontSize: 20, color: "#191919" }} />
                      <Typography
                        style={{
                          fontSize: 14,
                          color: "#191919",
                          fontWeight: 300,
                        }}
                      >
                        {createNewLabel}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        style={{
                          fontSize: 14,
                          color: "#525252",
                          fontWeight: 300,
                        }}
                      >
                        Limit Reached
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
              {resources.map((resource: any, index: number) => {
                const isFirst = index === 0;
                const isLast = index === resources.length - 1;
                const uploadUri = resource.imageUpload
                  ? resource.imageUpload.uri
                  : resource.upload.uri;

                return (
                  <Box
                    style={{
                      marginTop: 46,
                      flexGrow: 1,
                      paddingBottom: "0px",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                    key={resource.id}
                    className="item-container"
                  >
                    <Box
                      sx={{
                        py: 0.5,
                        my: 0.5,

                        paddingBottom: 3.5,
                        position: "relative",
                      }}
                    >
                      <div
                        className="item-cover"
                        style={{
                          backgroundSize: "cover",
                          backgroundImage: `url(${uploadUri})`,
                        }}
                      >
                        <Box className="actions">
                          <div>{orderButtons(index, isFirst, isLast)}</div>
                          <CloseIcon
                            style={{ fontSize: 20 }}
                            className="arrow-btn solid gallery remove"
                            onClick={(e) => removeItem(index, resource.id)}
                          >
                            &#215;
                          </CloseIcon>
                        </Box>
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          onClick={() => changeTab("edit", resource.id)}
                          sx={{
                            cursor: "pointer",
                          }}
                          className="edit"
                        ></Typography>
                      </div>
                      <Typography
                        variant="caption"
                        fontWeight={300}
                        color="#191919"
                      >
                        {(() => {
                          try {
                            return resource.imageUpload
                              ? `${resource.imageUpload.info.original_filename}.${resource.imageUpload.info.format}`
                              : `${resource.upload.info.original_filename}.${resource.upload.info.format}`;
                          } catch {
                            console.log("Error. Image info:", resource);
                            return "No file name available.";
                          }
                        })()}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}
          <Confirm
            isOpen={isOpen}
            loading={isLoading}
            title={`Delete`}
            content="Are you sure you want to delete this item?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </>
      );
    case "create":
      return (
        <>
          <Box
            sx={{
              pb: 1.5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => changeTab("list")}
              color="primary"
              variant="text"
              label={"BACK TO LIST"}
            />
          </Box>
          {createScreen}
        </>
      );
    case "edit":
      return (
        <>
          <Box
            sx={{
              pb: 1.5,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => changeTab("list")}
              color="primary"
              variant="text"
              label={"BACK TO LIST"}
            />
          </Box>
          {editScreen}
        </>
      );

    default:
      return null;
  }
}

export { Gallery };
