import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  required,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useEditController,
  maxLength,
  TabbedForm,
  FormTab,
} from "react-admin";
import useFileUpload from "../../hooks/useFileUpload";
import { ResourceEditor } from "../../components/ResourceEditor/ResourceEditor";

import { CreateScreen as PromotionsCreate } from "../../components/promotions/create";
import { EditScreen as PromotionsEdit } from "../../components/promotions/edit";
import { RaEditWrapper } from "../../components/RaEditWrapper";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Save Changes"
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
          };

          const resourceFilesReq = {
            heroUploadId: files.heroUpload ?? data.heroUpload.id,
            groupUploadId: files.groupUpload ?? data.groupUpload.id,
          };

          // Clean up fields.
          delete mappedRequest.heroUpload;
          delete mappedRequest.groupUpload;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
            ...resourceFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const { id } = useParams();
  const notify = useNotify();

  // Upload Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    heroUpload: null | any;
    groupUpdate: null | any;
  }>({
    heroUpload: null,
    groupUpdate: null,
  });

  const [resourceImgs, setResourceImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!resourceImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={resourceImgs[name].uri}
              title={resourceImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: string, files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setResourceImgs({
        ...resourceImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <RaEditWrapper mutationMode="pessimistic">
      <TabbedForm
        toolbar={<EditToolbar files={resourceFiles} record={record} />}
      >
        <FormTab label="Hero">
          <div className="form-text-input-row">
            <TextInputCounter
              formClassName="form-text-input-field"
              source="heroHeading"
              validate={[required(), maxLength(100)]}
              limit={100}
            />
          </div>

          <TextInputCounter
            source="heroDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="heroUpload"
              label="Hero Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "heroUpload"),
              }}
            >
              {renderImgPreview("heroUpload")}
            </ImageInput>
          </div>
        </FormTab>

        <FormTab label="Promotions">
          <ResourceEditor
            resourceId={"promotions"}
            createNewLabel="Add New Promotion"
            updatedOrderMsg="Promotion order updated."
            updatingOrderMsg="Updating promotion order..."
            heading="Promotions"
            renderName={(resource: any) => `${resource.name}`}
            CreateScreen={PromotionsCreate}
            EditScreen={PromotionsEdit}
            hasOrder={true}
            hasEnabled={true}
          />
        </FormTab>

        <FormTab label="Property Carousel">
          <div className="form-text-input-row">
            <TextInputCounter
              formClassName="form-text-input-field"
              source="propertyCarouselTitle"
              label="Title"
              validate={[required(), maxLength(100)]}
              limit={100}
            />
          </div>

          <TextInputCounter
            source="propertyCarouselDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />
        </FormTab>

        <FormTab label="Groups">
          <div className="form-text-input-row">
            <TextInputCounter
              formClassName="form-text-input-field"
              source="groupTitle"
              label="Title"
              validate={[required(), maxLength(100)]}
              limit={100}
            />
          </div>

          <TextInputCounter
            source="groupDescription"
            label="Description"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(140)]}
            limit={140}
          />

          <div className="resource-icons-grid">
            <ImageInput
              source="groupUpload"
              label="Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "groupUpload"),
              }}
            >
              {renderImgPreview("groupUpload")}
            </ImageInput>
          </div>
        </FormTab>
      </TabbedForm>
    </RaEditWrapper>
  );
};
