import { ColorInput } from "react-admin-color-picker";
import { styled } from "styled-components";

const StyledColorInput = styled(ColorInput)`
  & .MuiInputBase-input {
    padding: 8.5px 14px;
  }
`;

export type ColorPickerProps = Parameters<typeof ColorInput>[0];

export const ColorPicker = (props: ColorPickerProps) => (
  <StyledColorInput {...props} variant={props.variant ?? "outlined"} />
);
