import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  Edit,
  useEditController,
  maxLength,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, hasEnabled, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            publishedAt:
              data.enabled == true || data.enabled == "true"
                ? date.toISOString()
                : null,
          };

          const resourceFilesReq = {
            imageUploadId: files.imageUpload ?? data.imageUpload.id,
          };

          // Clean up fields.
          delete mappedRequest.imageUpload;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;

          if (!hasEnabled) {
            delete mappedRequest.publishedAt;
          }

          return {
            ...mappedRequest,
            ...resourceFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const { id, onCreated, hasEnabled, resource } = props;
  const notify = useNotify();

  // Upload Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    imageUpload: null | any;
  }>({
    imageUpload: null,
  });

  const [resourceImgs, setResourceImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!resourceImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={resourceImgs[name].uri}
              title={resourceImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: "imageUpload", files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setResourceImgs({
        ...resourceImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <Edit
      mutationMode="pessimistic"
      resource={resource}
      id={id}
      mutationOptions={{ onSuccess: onCreated }}
      redirect={false}
    >
      <SimpleForm
        toolbar={
          <EditToolbar
            files={resourceFiles}
            record={record}
            hasEnabled={hasEnabled}
          />
        }
        id={id}
        resource={resource}
      >
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="title"
            validate={[required(), maxLength(100)]}
            limit={100}
          />
          {hasEnabled ? (
            <SelectInput
              choices={enabledFlags}
              source="enabled"
              label="Enabled?"
              defaultValue="false"
              validate={[required()]}
            />
          ) : null}
        </div>

        <TextInputCounter
          source="description"
          multiline
          fullWidth
          resettable
          validate={[required(), maxLength(500)]}
          limit={500}
        />

        <h4 className="heading-file-uploads">Image</h4>

        <div className="resource-icons-grid">
          <ImageInput
            source="imageUpload"
            label="Image (.png, .jpg, .jpeg)"
            validate={[required()]}
            accept={"image/png,image/jpg,image/jpeg"}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "imageUpload"),
            }}
          >
            {renderImgPreview("imageUpload")}
          </ImageInput>
        </div>
      </SimpleForm>
    </Edit>
  );
};
