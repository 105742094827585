import { useEffect, useState } from "react";
import "./ArtistsActivitiesManager.css";
import { Button, useDataProvider, useNotify } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

export const ArtistsActivitiesManager = (props: any) => {
  const { id } = props;

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [activities, setActivities] = useState<any[]>([]);
  const [allActivities, setAllActivities] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [selectedActivity, setSelectedActivity] = useState<any>({
    id: "x",
    name: "Activities",
  });

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const { data } = await dataProvider.getArtistsActivities({
          id: id,
        });

        setActivities(data);
      } catch (err: any) {
        notify(err);
      } finally {
        setLoading(false);
      }
    };
    fetchActivities();
  }, []);

  useEffect(() => {
    const fetchChildrenActivities = async () => {
      const { data } = await dataProvider.getChildrenActivities();

      const mappedAllActivities = data.map((actv: any) => {
        return {
          id: actv.id,
          name: actv.name,
        };
      });

      setAllActivities(mappedAllActivities);
    };
    fetchChildrenActivities();
  }, []);

  const onChangeActivity = (e: any) => {
    const id = e.target.value;

    const activity = allActivities.find((a: any) => a.id === id);

    if (!activity) {
      setSelectedActivity({
        id: "x",
        name: "Activities",
      });

      return;
    }

    setSelectedActivity({
      id: activity.id,
      name: activity.name,
    });
  };

  const onAddActivity = async (e: any) => {
    if (!selectedActivity || selectedActivity.id === "x") {
      return;
    }

    const newActivities = [...activities, selectedActivity];

    setSelectedActivity({
      id: "x",
      name: "Activities",
    });

    setActivities(newActivities);

    await updateActivityOrder(newActivities);
  };

  const moveMainItem = async (index: number, direction: "up" | "down") => {
    const movedActivities = [...activities];

    if (direction === "up" && index > 0 && index < movedActivities.length) {
      const removedItem = movedActivities.splice(index, 1)[0];
      movedActivities.splice(index - 1, 0, removedItem);
    }

    if (
      direction === "down" &&
      index >= 0 &&
      index < movedActivities.length - 1
    ) {
      const removedItem = movedActivities.splice(index, 1)[0];
      movedActivities.splice(index + 1, 0, removedItem);
    }

    setActivities(movedActivities);

    await updateActivityOrder(movedActivities);
  };

  const removeItem = async (index: number) => {
    const movedActivities = [...activities];

    movedActivities.splice(index, 1);

    setActivities(movedActivities);

    await updateActivityOrder(movedActivities);
  };

  const updateActivityOrder = async (movedActivities: any[]) => {
    notify("Updating activities order...");

    const newOrderIds = movedActivities.map((a: any) => a.id);

    const { data } = await dataProvider.updateArtistsActivities(id, {
      activities: newOrderIds,
    });

    notify("Activities order updated.");
  };

  const alreadyInUseActivities = activities.map((a: any) => a.id);

  const selectableActivities = allActivities.filter(
    (allAct: any) => !alreadyInUseActivities.includes(allAct.id),
  );

  return (
    <section>
      <div>
        {loading ? (
          <div>Loading activities...</div>
        ) : (
          activities.map((act: any, index: number) => {
            const isFirst = index === 0;
            const isLast = index === activities.length - 1;

            return (
              <div key={act.id}>
                <a className="arrow-btn" onClick={(e) => removeItem(index)}>
                  &#215;
                </a>
                {!isFirst ? (
                  <a
                    className="arrow-btn"
                    onClick={(e) => moveMainItem(index, "up")}
                  >
                    &uarr;
                  </a>
                ) : (
                  <a className="arrow-dummy">&darr;</a>
                )}
                {!isLast ? (
                  <a
                    className="arrow-btn"
                    onClick={(e) => moveMainItem(index, "down")}
                  >
                    &darr;
                  </a>
                ) : (
                  <a className="arrow-dummy">&darr;</a>
                )}
                <span className="actv-name">{act.name}</span>
              </div>
            );
          })
        )}
        {selectableActivities.length === 0 ? null : (
          <>
            <div className="add-actv">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select onChange={onChangeActivity} value={selectedActivity.id}>
                  <MenuItem value={"x"}>Activities</MenuItem>
                  {selectableActivities.map((act: any) => {
                    return (
                      <MenuItem value={act.id} key={act.id}>
                        {act.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="medium">
                <Button
                  onClick={onAddActivity}
                  color="primary"
                  variant="contained"
                  label="Add Activity"
                />
              </FormControl>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
