import { useEffect, useState } from "react";
import "./AssignPromotionsManager.css";
import { Button, Confirm, useNotify } from "react-admin";
import FormControl from "@mui/material/FormControl";
import dataProvider from "../dataProvider";

export const AssignPromotionsManager = (props: any) => {
  const { promoId } = props;

  const notify = useNotify();

  const [loading, setLoading] = useState(false);

  const [assignAllOpen, setAssignAllOpen] = useState(false);
  const [unassignAllOpen, setUnassignAllOpen] = useState(false);

  const handleAssignDialogClose = () => setAssignAllOpen(false);

  const handleAssignConfirm = async () => {
    setAssignAllOpen(false);
    await onAssignAll();
  };

  const handleUnassignDialogClose = () => setUnassignAllOpen(false);

  const handleUnassignConfirm = async () => {
    setUnassignAllOpen(false);
    await onUnassignAll();
  };

  const fetchProperties = async () => {
    try {
      const { data } = await dataProvider.getMany("properties", { ids: [] });
      return data;
    } catch (err: any) {
      notify(err);
    } finally {
    }
  };

  const updatePromotionOrder = async (id: string, promotions: any[]) => {
    const filteredPromotionIds = promotions.filter(
      (p: any) => p !== null && p !== undefined,
    );

    const { data } = await dataProvider.updatePropertyPromotions(id, {
      promotions: filteredPromotionIds,
    });

    return data;
  };

  const onAssignAll = async () => {
    setLoading(true);

    const propertiesRaw = await fetchProperties();

    if (propertiesRaw) {
      const properties = propertiesRaw.filter((p: any) => !p.isComingSoon);

      const processedProperties = await Promise.all(
        properties.map(async (p: any) => {
          const property = await dataProvider.getOne("/properties", {
            id: p.id,
          });

          const { data } = property;

          const { promotions } = data;

          const promoIds = promotions.map((promo: any) => promo.id);

          if (promoIds.includes(promoId)) {
            return;
          }

          const newPromoIds = [...promoIds, promoId];

          const updatedProperty = await updatePromotionOrder(p.id, newPromoIds);

          return updatedProperty;
        }),
      );

      notify("Promotions were assigned to all properties.");
    }

    setLoading(false);
  };

  const onUnassignAll = async () => {
    setLoading(true);

    const propertiesRaw = await fetchProperties();

    if (propertiesRaw) {
      const properties = propertiesRaw;

      await Promise.all(
        properties.map(async (p: any) => {
          const property = await dataProvider.getOne("/properties", {
            id: p.id,
          });

          const { data } = property;

          const { promotions } = data;

          const promoIds = promotions.map((promo: any) => promo.id);

          if (!promoIds.includes(promoId)) {
            return;
          }

          const newPromoIds = promoIds.filter(
            (promo: any) => promo !== promoId,
          );

          await updatePromotionOrder(p.id, newPromoIds);
        }),
      );

      notify("Promotions were unassigned from all properties.");
    }

    setLoading(false);
  };

  return (
    <section>
      <div>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="medium">
          <Button
            onClick={() => setAssignAllOpen(true)}
            color="primary"
            variant="contained"
            label="Assign to all properties"
            disabled={loading}
            style={{
              marginBottom: "15px",
            }}
          />
          <Button
            onClick={() => setUnassignAllOpen(true)}
            color="primary"
            variant="contained"
            disabled={loading}
            label="Unassign from all Properties"
          />
        </FormControl>
        {loading ? <div>Processing...</div> : null}
        <Confirm
          isOpen={assignAllOpen}
          loading={loading}
          title={`Assign to all properties`}
          content="Are you sure you want to assign this promotion to all properties?"
          onConfirm={handleAssignConfirm}
          onClose={handleAssignDialogClose}
        />
        <Confirm
          isOpen={unassignAllOpen}
          loading={loading}
          title={`Unassign from all properties`}
          content="Are you sure you want to unassign this promotion from all properties?"
          onConfirm={handleUnassignConfirm}
          onClose={handleUnassignDialogClose}
        />
      </div>
    </section>
  );
};
