import React from "react";
import { useRecordContext, useInput } from "react-admin";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "./constants";

export const RichEditorField = (props) => {
  const { id, field, fieldState } = useInput({ source: props.source });
  const instanceRef = React.useRef(null);

  const { value, onChange } = field;

  const onChangeData = async (evt) => {
    const savedData = await instanceRef.current.save();
    props.onContentChange(savedData);
    onChange();
  }

  return (
    <div className="editor-wrapper">
      <EditorJs
        instanceRef={(instance) => (instanceRef.current = instance)}
        onChange={onChangeData}
        tools={EDITOR_JS_TOOLS}
        data={value}
        changeData={onChangeData}
      />
    </div>
  );
};
