import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  required,
  Toolbar,
  SaveButton,
  useEditController,
  maxLength,
  TabbedForm,
  FormTab,
} from "react-admin";
import { ResourceEditor } from "../../components/ResourceEditor/ResourceEditor";

import { CreateScreen as ContactUsPageFAQsCreate } from "../../components/contact-us-page-faqs/create";
import { EditScreen as ContactUsPageFAQsEdit } from "../../components/contact-us-page-faqs/edit";

import { CreateScreen as ContactUsPagePurposesCreate } from "../../components/contact-us-page-purposes/create";
import { EditScreen as ContactUsPagePurposesEdit } from "../../components/contact-us-page-purposes/edit";
import { RaEditWrapper } from "../../components/RaEditWrapper";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const mappedRequest: any = {
            ...data,
          };

          // Clean up fields.
          delete mappedRequest.topSectionImageUpload;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <RaEditWrapper mutationMode="pessimistic">
      <TabbedForm toolbar={<EditToolbar record={record} />}>
        <FormTab label="Top">
          <div className="form-text-input-row">
            <TextInputCounter
              formClassName="form-text-input-field"
              source="topSectionTitle"
              validate={[required(), maxLength(100)]}
              limit={100}
            />
          </div>

          <TextInputCounter
            source="topSectionDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />
        </FormTab>

        <FormTab label="Form">
          <div className="form-text-input-row">
            <TextInputCounter
              formClassName="form-text-input-field"
              source="formSectionTitle"
              validate={[required(), maxLength(100)]}
              limit={100}
            />
          </div>

          <TextInputCounter
            source="formSectionDescription"
            multiline
            fullWidth
            resettable
            validate={[required(), maxLength(500)]}
            limit={500}
          />
        </FormTab>

        <FormTab label="FAQs">
          <ResourceEditor
            resourceId={"contact-us-page-faqs"}
            createNewLabel="Add New FAQ"
            updatedOrderMsg="FAQ order updated."
            updatingOrderMsg="Updating FAQ order..."
            heading="FAQs"
            renderName={(resource: any) =>
              `${resource.questionText.substr(0, 100)}...`
            }
            CreateScreen={ContactUsPageFAQsCreate}
            EditScreen={ContactUsPageFAQsEdit}
            hasOrder={true}
            hasEnabled={false}
          />
        </FormTab>

        <FormTab label="Purposes">
          <ResourceEditor
            resourceId={"contact-us-page-purposes"}
            createNewLabel="Add New Purpose"
            updatedOrderMsg="Purpose order updated."
            updatingOrderMsg="Updating purpose order..."
            heading="Purposes"
            renderName={(resource: any) => `${resource.purpose}`}
            CreateScreen={ContactUsPagePurposesCreate}
            EditScreen={ContactUsPagePurposesEdit}
            hasOrder={true}
            hasEnabled={false}
          />
        </FormTab>
      </TabbedForm>
    </RaEditWrapper>
  );
};
