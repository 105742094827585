import { Menu, useLogout } from "react-admin";
import SubMenu from "../SubMenu";
import { resourcesTree, ResourceListItem } from "../../resources";
import { LogeLogo } from "../../assets";
import { Button } from "@mui/material";
import { Avatar } from "@mui/material";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import MuiMenu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";

const LogOutButton = styled(Button)`
  padding-bottom: 24px !important;
  gap: 12px;
  display: flex !important;
  justify-content: flex-start !important;
  padding-inline: 0 !important;
`;

const LogOutText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const StyledTypography = styled(Typography)<{ $color?: string }>`
  color: ${({ $color }) => $color || "black"};
`;

export const SidebarMenu = () => {
  const logout = useLogout();
  const userName = Cookies.get("userName");
  const userEmail = Cookies.get("userEmail");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div>
        <LogeLogo width={64} height={64} style={{ paddingLeft: "12px" }} />
        <Menu>
          {resourcesTree.map((resource) => {
            if (!resource) {
              return null;
            }

            const { options, icon: ResourceIcon, name, resources } = resource;

            if (resources?.length) {
              return <NestedMenu key={resource.name} resource={resource} />;
            }

            return (
              <Menu.Item
                key={name}
                to={`/${name}`}
                primaryText={options?.label}
                leftIcon={ResourceIcon ? <ResourceIcon /> : undefined}
              />
            );
          })}
        </Menu>
      </div>

      <LogOutButton
        aria-controls={open ? "logout-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{ position: "relative" }}
      >
        <Avatar sx={{ bgcolor: "#DD4725" }}>{userName?.slice(0, 1)}</Avatar>
        <LogOutText>
          <StyledTypography
            variant="caption"
            fontWeight={300}
            textAlign="start"
            $color="#191919"
          >
            {userName}
          </StyledTypography>
          <StyledTypography
            variant="caption"
            fontWeight={300}
            textAlign="start"
            $color="#737373"
          >
            {userEmail}
          </StyledTypography>
        </LogOutText>
      </LogOutButton>
      <MuiMenu
        id="logout-menu"
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Log-out</ListItemText>
        </MenuItem>
      </MuiMenu>
    </div>
  );
};

type NestedMenuProps = {
  resource: ResourceListItem;
};

const NestedMenu = ({ resource }: NestedMenuProps) => {
  if (!resource.resources?.length) {
    return null;
  }

  return (
    <SubMenu
      primaryText={resource.options?.label}
      leftIcon={resource.icon ? <resource.icon /> : undefined}
    >
      {resource.resources.map((item) => {
        if (item.resources?.length) {
          return <NestedMenu key={item.name} resource={item} />;
        }

        return (
          <Menu.Item
            key={item.name}
            to={`/${item.name}`}
            primaryText={item.options?.label}
          />
        );
      })}
    </SubMenu>
  );
};
