import { useCallback, useEffect, useMemo, useState } from "react";
import { useDataProvider } from "react-admin";
import { ResourceEditor } from "./ResourceEditor/ResourceEditor";
import { SelectCreateScreen } from "./ResourceEditor/SelectCreateScreen";
import { isTodayInRange } from "../utils/time-utils";

type PromotionsManagerProps = {
  id: string;
};

export const PromotionsManager = ({ id }: PromotionsManagerProps) => {
  const dataProvider = useDataProvider();
  const [enabledResources, setEnabledResources] = useState([]);

  const fetchPropertyResources = useCallback(async () => {
    const { data } = await dataProvider.getPropertyPromotions({ id: id });
    return data;
  }, [dataProvider, id]);

  const getName = (item: any) =>
    `${item.name}  ${
      !item.publishedAt || !isTodayInRange(item.fromDate, item.toDate)
        ? " (Hidden)"
        : ""
    }`;

  const fetchEnabledResources = useCallback(async () => {
    const { data } = await dataProvider.getPromotions();
    setEnabledResources(data);
    const mappedAllPromotions = data.map((actv: any) => {
      return {
        id: actv.id,
        name: getName(actv),
        publishedAt: actv.publishedAt,
        fromDate: actv.fromDate,
        toDate: actv.toDate,
      };
    });
    setEnabledResources(mappedAllPromotions);
  }, [dataProvider]);

  const updateResources = useCallback(
    async (resources: any[]) =>
      dataProvider.updatePropertyPromotions(id, {
        promotions: resources.map((a: any) => a.id),
      }),
    [dataProvider, id],
  );

  const handleMoveItems = useCallback(
    (resources: any[]) => updateResources(resources),
    [updateResources],
  );

  const handleRemoveItem = useCallback(
    (resources: any[], removedResource: any) =>
      updateResources(
        resources.filter((item: any) => item.id !== removedResource.id),
      ),
    [updateResources],
  );

  const CreateScreen = useMemo(
    () => (props: any) => (
      <SelectCreateScreen
        {...props}
        label="Promotion"
        onSave={(resources, selectedResource) =>
          updateResources([...resources, selectedResource])
        }
        getSelectOptions={(resources) =>
          enabledResources.filter(
            (type: any) => !resources.some((res) => res.id === type.id),
          )
        }
      />
    ),
    [enabledResources, updateResources],
  );

  useEffect(() => {
    fetchEnabledResources();
  }, []);

  return (
    <ResourceEditor
      hasOrder
      hasBorder
      resourceId="promotions"
      renderName={getName}
      heading="Promotions"
      createNewLabel="Add New Promotion"
      updatingOrderMsg="Updating promotions order..."
      updatedOrderMsg="Promotions order updated."
      fetchResourceItems={fetchPropertyResources}
      CreateScreen={CreateScreen}
      onUpdateResourceItems={handleMoveItems}
      onRemoveResourceItem={handleRemoveItem}
    />
  );
};
