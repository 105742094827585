import { useEffect, useState } from "react";
import "./GroupSectionGalleryEditor.css";
import { useDataProvider, useNotify, useDelete } from "react-admin";
import { Gallery } from "../Gallery/Gallery";

const DATAPROVIDER_RESOURCE_ID = "group-event-page-section-uploads";

export const GroupSectionGalleryEditor = (props: any) => {
  const {
    createNewLabel,
    resource,
    updatedOrderMsg,
    updatingOrderMsg,
    CreateScreen,
    EditScreen,
    hasOrder,
    hasEnabled,
    heading,
    groupEventPageSectionId,
    limit,
  } = props;

  const [tab, setTab] = useState<"list" | "edit" | "create">("list");
  const [editedId, setEditedId] = useState<string>("");
  const [deletedId, setDeletedId] = useState<string | null>(null);

  const [open, setOpen] = useState(false);

  const [remove, { isLoading }] = useDelete();

  const handleDeleteClick = (id: string) => {
    setDeletedId(id);
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    remove(
      resource,
      {
        id: deletedId,
      },
      {
        onSettled: () => {
          fetchResources();
        },
      },
    );
    setOpen(false);
    setDeletedId(null);
  };

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [resources, setResources] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchResources = async () => {
    try {
      setLoading(true);

      const { data } = await dataProvider.getGroupsSectionUploads({
        groupEventPageSectionId: groupEventPageSectionId,
      });

      setResources(data);
    } catch (err: any) {
      notify(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  const moveMainItem = async (index: number, direction: "up" | "down") => {
    const movedResources = [...resources];

    if (direction === "up" && index > 0 && index < movedResources.length) {
      const removedItem = movedResources.splice(index, 1)[0];
      movedResources.splice(index - 1, 0, removedItem);
    }

    if (
      direction === "down" &&
      index >= 0 &&
      index < movedResources.length - 1
    ) {
      const removedItem = movedResources.splice(index, 1)[0];
      movedResources.splice(index + 1, 0, removedItem);
    }

    setResources(movedResources);

    await updateResourcesOrder(movedResources);
  };

  const removeItem = async (index: number, id: string) => {
    handleDeleteClick(id);
  };

  const updateResourcesOrder = async (movedResources: any[]) => {
    notify(updatingOrderMsg);

    const updateOrderPayload = movedResources.map((item, itemIndex: number) => {
      return {
        id: item.id,
        order: itemIndex,
      };
    });

    await Promise.all(
      updateOrderPayload.map(async (p: any) => {
        const payload: any = {
          id: p.id,
          data: {
            order: p.order,
          },
        };

        return dataProvider.update(DATAPROVIDER_RESOURCE_ID, payload);
      }),
    );

    notify(updatedOrderMsg);
  };

  const onCreated = async (id: any) => {
    fetchResources();
    changeTab("list");
  };

  const changeTab = (newTab: "list" | "edit" | "create", id?: string) => {
    if (id) {
      setEditedId(id);
    }

    setTab(newTab);
  };

  return (
    <div className="wrapper">
      {
        <Gallery
          hasOrder={hasOrder}
          tab={tab}
          moveMainItem={moveMainItem}
          heading={heading}
          loading={loading}
          limit={limit}
          resources={resources}
          changeTab={changeTab}
          createNewLabel={createNewLabel}
          removeItem={removeItem}
          isOpen={open}
          isLoading={isLoading}
          handleConfirm={handleConfirm}
          handleDialogClose={handleDialogClose}
          createScreen={
            <CreateScreen
              onCreated={onCreated}
              hasEnabled={hasEnabled}
              resource={resource}
              groupEventPageSectionId={groupEventPageSectionId}
            />
          }
          editScreen={
            <EditScreen
              id={editedId}
              resource={resource}
              onCreated={onCreated}
              hasEnabled={hasEnabled}
              groupEventPageSectionId={groupEventPageSectionId}
            />
          }
        />
      }
    </div>
  );
};
