import { ReactElement } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import { styled } from "@mui/system";
import { useResourceDefinition } from "react-admin";
import { Link } from "react-router-dom";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

const ResourceBreadcrumbLink = styled(Link)`
  color: #000;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  text-decoration: none;
`;

const PageTitle = styled(Typography)`
  color: #000;
  font-size: 48px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -0.03em;
  margin-bottom: 32px;
`;

export type MainPageTitleProps = {
  value?: string | ReactElement;
};

export const MainPageTitle = ({ value }: MainPageTitleProps) => {
  const currentResource = useResourceDefinition();
  const resourceDisplayName = currentResource?.options?.label ?? null;

  return (
    <Box>
      {value && resourceDisplayName && (
        <ResourceBreadcrumbLink to={`/${currentResource.name}`}>
          <Icon
            component={ChevronLeftRoundedIcon}
            sx={{
              fontSize: "inherit",
              verticalAlign: "text-top",
              marginRight: "4px",
            }}
          />
          {resourceDisplayName}
        </ResourceBreadcrumbLink>
      )}
      <PageTitle>{value ?? resourceDisplayName}</PageTitle>
    </Box>
  );
};
