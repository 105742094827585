import { useEffect, useState } from "react";
import { Button, useDataProvider } from "react-admin";
import ReviewItem from "./ReviewItem";
import "./ReviewsManager.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {
  getMonthsArray,
  getPastTenYears,
  isDateInFuture,
  isFirstDateBefore,
  getDateRange,
} from "./utils";

const ReviewsManager = ({
  reviews,
  isLoading,
  onSingleReviewChange,
  currentProperty,
  onAfterImport,
}: {
  reviews: any[];
  isLoading: boolean;
  onSingleReviewChange: any;
  currentProperty: string | null;
  onAfterImport: any;
}) => {
  const dataProvider = useDataProvider();
  const [currentTab, setCurrentTab] = useState<string>("new");
  const [checkedReviewIds, setCheckedReviewIds] = useState<any[]>([]);
  const [bulkLoading, setBulkLoading] = useState(false);

  const [fromMonth, setFromMonth] = useState<string>("x");
  const [toMonth, setToMonth] = useState<string>("x");

  const [fromYear, setFromYear] = useState<string>("x");
  const [toYear, setToYear] = useState<string>("x");

  const [isForceFormValid, setForceFormValid] = useState(false);
  const [isForceFormLoading, setForceFormLoading] = useState(false);
  const [forceFromError, setForceFromError] = useState<string | null>(null);
  const [forceToError, setForceToError] = useState<string | null>(null);
  const [forceFormError, setForceFormError] = useState<string | null>(null);
  const [forceFormMsg, setForceFormMsg] = useState<string | null>(null);

  const pastTenYears = getPastTenYears();
  const months = getMonthsArray();

  if (isLoading) {
    return <p>Loading property reviews...</p>;
  }

  // Get the current date and time
  const currentDate = new Date();

  // Calculate the date that is 6 days ago
  const sixDaysAgo = new Date();
  sixDaysAgo.setDate(currentDate.getDate() - 6);

  const isLessThan6DaysAgo = (dateToCheck: Date) => {
    return dateToCheck >= sixDaysAgo;
  };

  const onToggleChecked = (id: string) => {
    if (checkedReviewIds.includes(id)) {
      // we remove.
      setCheckedReviewIds((prev) => {
        const out = prev.filter((r) => r !== id);
        return out;
      });
    } else {
      // we add
      setCheckedReviewIds((prev) => {
        return [...prev, id];
      });
    }
  };

  const onBulkClick = async (setPublished: boolean) => {
    setBulkLoading(true);

    const payload = {
      publishedAt: setPublished ? new Date().toISOString() : null,
    };

    await Promise.all(
      checkedReviewIds.map(async (id) => {
        const { data } = await dataProvider.updatePropertyReview(id, payload);

        onSingleReviewChange(id, payload);
      }),
    );

    setCheckedReviewIds([]);
    setBulkLoading(false);
  };

  const onForcePull = async () => {
    let hasValidationError = false;

    setForceFromError(null);
    setForceToError(null);
    setForceFormError(null);
    setForceFormMsg(null);

    if (fromYear === "x" || fromMonth === "x") {
      setForceFromError("Pick month and year.");
      hasValidationError = true;
    }

    if (toYear === "x" || toMonth === "x") {
      setForceToError("Pick month and year.");
      hasValidationError = true;
    }

    if (hasValidationError) {
      return;
    }

    const fromYearNumber = parseInt(fromYear, 10);
    const fromMonthNuber = parseInt(fromMonth, 10);

    const toYearNumber = parseInt(toYear, 10);
    const toMonthNuber = parseInt(toMonth, 10);

    if (isDateInFuture(fromYearNumber, fromMonthNuber)) {
      setForceFromError("Date is in the future.");
      hasValidationError = true;
    }

    if (isDateInFuture(toYearNumber, toMonthNuber)) {
      setForceToError("Date is in the future.");
      hasValidationError = true;
    }

    if (hasValidationError) {
      return;
    }

    if (
      !isFirstDateBefore(
        toYearNumber,
        toMonthNuber,
        fromYearNumber,
        fromMonthNuber,
      )
    ) {
      setForceFormError("From date must come before to date.");
      hasValidationError = true;
    }

    if (hasValidationError) {
      return;
    }

    setForceFormLoading(true);

    const { from, to } = getDateRange(
      fromMonthNuber,
      fromYearNumber,
      toMonthNuber,
      toYearNumber,
    );

    const { data } = await dataProvider.fetchProperyReviews(currentProperty, {
      from: from,
      to: to,
    });

    if (data) {
      setForceFormMsg("Pulled reviews with success.");
      onAfterImport();
    } else {
      setForceFormError("Error pulling reviews.");
    }

    setForceFormLoading(false);
  };

  const newReviews: any[] = [];
  const unpublishedReviews: any[] = [];
  const publishedReviews: any[] = [];

  reviews.forEach((r) => {
    if (!r.publishedAt && isLessThan6DaysAgo(new Date(r.date))) {
      newReviews.push(r);
      return;
    }

    if (!r.publishedAt) {
      unpublishedReviews.push(r);
    }

    if (r.publishedAt) {
      publishedReviews.push(r);
    }
  });

  return (
    <div>
      <div className="tabs">
        <Button
          onClick={() => {
            setCheckedReviewIds([]);
            setCurrentTab("new");
          }}
          label={`New (${newReviews.length})`}
          variant={`${currentTab === "new" ? "contained" : "text"}`}
        />
        <Button
          onClick={() => {
            setCheckedReviewIds([]);
            setCurrentTab("unpublished");
          }}
          label={`Unpublished (${unpublishedReviews.length})`}
          variant={`${currentTab === "unpublished" ? "contained" : "text"}`}
        />
        <Button
          onClick={() => {
            setCheckedReviewIds([]);
            setCurrentTab("published");
          }}
          label={`Published (${publishedReviews.length})`}
          variant={`${currentTab === "published" ? "contained" : "text"}`}
        />
        <Button
          onClick={() => {
            setCheckedReviewIds([]);
            setCurrentTab("force");
          }}
          label={`Force Pull Reviews`}
          variant={`${currentTab === "force" ? "contained" : "text"}`}
        />
      </div>
      <div className="bulk">
        {currentTab === "new" || currentTab === "unpublished" ? (
          <Button
            onClick={() => {
              onBulkClick(true);
            }}
            disabled={checkedReviewIds.length === 0 || bulkLoading}
            label={`Publish Selected (${checkedReviewIds.length})`}
          />
        ) : null}
        {currentTab === "published" ? (
          <Button
            onClick={() => {
              onBulkClick(false);
            }}
            disabled={checkedReviewIds.length === 0 || bulkLoading}
            label={`Hide All Selected (${checkedReviewIds.length})`}
          />
        ) : null}
        {bulkLoading ? <p>Processing</p> : null}
      </div>
      <div style={{ display: currentTab === "new" ? "block" : "none" }}>
        {newReviews.length === 0 ? <p>Zero new reviews to display.</p> : null}
        {newReviews.map((r, i) => {
          return (
            <ReviewItem
              key={r.id}
              review={r}
              onSingleReviewChange={onSingleReviewChange}
              checked={checkedReviewIds.includes(r.id)}
              onToggleChecked={onToggleChecked}
            />
          );
        })}
      </div>
      <div style={{ display: currentTab === "unpublished" ? "block" : "none" }}>
        {unpublishedReviews.length === 0 ? (
          <p>Zero unpublished reviews to display.</p>
        ) : null}
        {unpublishedReviews.map((r, i) => {
          return (
            <ReviewItem
              key={r.id}
              review={r}
              onSingleReviewChange={onSingleReviewChange}
              checked={checkedReviewIds.includes(r.id)}
              onToggleChecked={onToggleChecked}
            />
          );
        })}
      </div>
      <div style={{ display: currentTab === "published" ? "block" : "none" }}>
        {publishedReviews.length === 0 ? (
          <p>Zero published reviews to display.</p>
        ) : null}
        {publishedReviews.map((r, i) => {
          return (
            <ReviewItem
              key={r.id}
              review={r}
              onSingleReviewChange={onSingleReviewChange}
              checked={checkedReviewIds.includes(r.id)}
              onToggleChecked={onToggleChecked}
            />
          );
        })}
      </div>
      <div style={{ display: currentTab === "force" ? "block" : "none" }}>
        <div className="force">
          <div className="force-from">
            <p>
              <strong>From Date</strong>
            </p>
            <div>
              <FormControl
                sx={{ m: 0, minWidth: 120, mb: "20px" }}
                size="small"
                className="force-picker"
              >
                <Select
                  onChange={(e) => {
                    setFromMonth(e.target.value);
                  }}
                  value={fromMonth}
                >
                  <MenuItem value={"x"}>Month</MenuItem>
                  {months.map((month) => {
                    return (
                      <MenuItem value={month.value} key={month.value}>
                        {month.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Select
                  onChange={(e) => {
                    setFromYear(e.target.value);
                  }}
                  value={fromYear}
                >
                  <MenuItem value={"x"}>Year</MenuItem>
                  {pastTenYears.map((year) => {
                    return (
                      <MenuItem value={year} key={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {forceFromError ? (
                <p className="force-error">{forceFromError}</p>
              ) : null}
            </div>
          </div>
          <div className="force-to">
            <p>
              <strong>To Date</strong>
            </p>
            <div>
              <FormControl
                sx={{ m: 0, minWidth: 120, mb: "20px" }}
                size="small"
                className="force-picker"
              >
                <Select
                  onChange={(e) => {
                    setToMonth(e.target.value);
                  }}
                  value={toMonth}
                >
                  <MenuItem value={"x"}>Month</MenuItem>
                  {months.map((month) => {
                    return (
                      <MenuItem value={month.value} key={month.value}>
                        {month.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Select
                  onChange={(e) => {
                    setToYear(e.target.value);
                  }}
                  value={toYear}
                >
                  <MenuItem value={"x"}>Year</MenuItem>
                  {pastTenYears.map((year) => {
                    return (
                      <MenuItem value={year} key={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {forceToError ? (
                <p className="force-error">{forceToError}</p>
              ) : null}
            </div>
          </div>
          <div>
            <Button
              onClick={() => {
                onForcePull();
              }}
              disabled={isForceFormLoading}
              variant="contained"
              label={`Pull Reviews`}
            />
            {isForceFormLoading ? <p>Pulling reviews from API...</p> : null}
            {forceFormError ? (
              <p className="force-error" style={{ marginTop: "10px" }}>
                {forceFormError}
              </p>
            ) : null}
            {forceFormMsg ? <p>{forceFormMsg}</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsManager;
