import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
  Edit,
  useGetOne,
  useEditController,
  maxLength,
  regex,
  DateInput,
} from "react-admin";
import useFileUpload from "../../hooks/useFileUpload";
import { coordinatesPattern } from "../../utils/validators";

import { EventGalleryEditor } from "../../components/EventGalleryEditor/EventGalleryEditor";

import { CreateScreen as EventUploadsCreate } from "../../components/event-uploads/create";
import { EditScreen as EventUploadsEdit } from "../../components/event-uploads/edit";
import {
  enabledFlags,
  featuredFlags,
  visibleFlags,
} from "../../constants/utils";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            publishedAt:
              data.enabled == true || data.enabled == "true"
                ? date.toISOString()
                : null,
          };

          const resourceFilesReq = {
            heroImageId: files.heroImage ?? data.heroImage.id,
          };

          delete mappedRequest.id;
          delete mappedRequest.activity;
          delete mappedRequest.gallery;
          delete mappedRequest.heroImage;
          delete mappedRequest.property;
          delete mappedRequest.order;
          delete mappedRequest.enabled;
          delete mappedRequest.isUserAttending;

          return {
            ...mappedRequest,
            ...resourceFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const { id, onCreated, hasEnabled, resource, activities, event } = props;
  const notify = useNotify();

  // Upload Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    heroImage: null | any;
  }>({
    heroImage: null,
  });

  const [resourceImgs, setResourceImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!resourceImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={resourceImgs[name].uri}
              title={resourceImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: string, files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setResourceImgs({
        ...resourceImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <Edit
      mutationMode="pessimistic"
      resource={resource}
      id={id}
      mutationOptions={{ onSuccess: onCreated }}
      redirect={false}
    >
      <SimpleForm
        toolbar={
          <EditToolbar
            files={resourceFiles}
            record={record}
            hasEnabled={hasEnabled}
          />
        }
        id={id}
        resource={resource}
      >
        <div className="form-text-input-row">
          <SelectInput
            choices={featuredFlags}
            source="isFeatured"
            label="Featured?"
            defaultValue="false"
            validate={[required()]}
          />

          <SelectInput
            choices={visibleFlags}
            source="enabled"
            label="Enabled?"
            defaultValue="false"
            validate={[required()]}
          />
        </div>

        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="name"
            validate={[required(), maxLength(120)]}
            limit={120}
          />
        </div>

        <TextInputCounter
          formClassName="form-text-input-field"
          source="address"
          label="Address"
          defaultValue=""
          fullWidth
          validate={[required(), maxLength(500)]}
          limit={500}
        />

        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="coordinates"
            validate={[
              required(),
              regex(coordinatesPattern, "Expected format is (2.123,1.234)"),
              maxLength(120),
            ]}
            limit={120}
          />
          <DateInput
            source="startDate"
            label="Start Date"
            validate={[required()]}
          />
          <TextInputCounter
            formClassName="form-text-input-field"
            source="startTime"
            label="Start Time"
            defaultValue="12:00 AM"
            validate={[required(), maxLength(100)]}
            limit={100}
          />

          <SelectInput
            source="activityId"
            label="Activity"
            choices={activities.map((type: any) => ({
              id: type.id,
              name: type.name,
            }))}
            validate={[required()]}
          />

          <TextInputCounter
            formClassName="form-text-input-field"
            source="duration"
            label="Duration"
            defaultValue="HH:MM"
            validate={[required(), maxLength(100)]}
            limit={100}
          />
          <TextInputCounter
            formClassName="form-text-input-field"
            source="maxAttendees"
            label="Max Attendees Number"
            defaultValue="9999"
            validate={[required(), maxLength(100)]}
            limit={100}
          />
        </div>

        <TextInputCounter
          formClassName="form-text-input-field"
          source="eventDetails"
          label={"Event Details"}
          limit={1000}
          multiline
          fullWidth
          validate={[required(), maxLength(1000)]}
        />

        <TextInputCounter
          formClassName="form-text-input-field"
          source="directions"
          limit={500}
          multiline
          fullWidth
          validate={[required(), maxLength(500)]}
        />

        <h4 className="heading-file-uploads">Hero Image</h4>

        <div className="resource-icons-grid">
          <ImageInput
            source="heroImage"
            label="Photo (.png, .jpg, .jpeg)"
            validate={[required()]}
            accept={"image/png,image/jpg,image/jpeg"}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "heroImage"),
            }}
          >
            {renderImgPreview("heroImage")}
          </ImageInput>
        </div>

        <h4 className="heading-file-uploads">Gallery</h4>

        <EventGalleryEditor
          resource={"event-uploads"}
          eventId={event.id}
          createNewLabel="Add New Image"
          updatedOrderMsg="Item order updated."
          updatingOrderMsg="Updating item order..."
          CreateScreen={EventUploadsCreate}
          EditScreen={EventUploadsEdit}
          hasOrder={true}
          unique={false}
          hasEnabled={false}
          limit={null}
        />
      </SimpleForm>
    </Edit>
  );
};
