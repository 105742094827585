import * as React from "react";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  required,
  SimpleFormIterator,
  TimeInput,
  NumberInput,
  SelectInput,
  ArrayInput,
  FileInput,
  ImageInput,
  FileField,
  ImageField,
  Toolbar,
  SaveButton,
  useGetOne,
  useNotify,
  regex,
  maxLength,
  FormDataConsumer,
  useRecordContext,
  TabbedForm,
  FormTab,
  useDataProvider,
} from "react-admin";
import { countryListAllIsoData } from "../../constants/country_codes";
import { PropertyHour } from "../../types/Property";
import useFileUpload from "../../hooks/useFileUpload";
import {
  getAllTimezones,
  timeFormatter,
  timeParser,
} from "../../utils/time-utils";
import { week_days } from "../../utils/weekdays";
import { ColorPicker } from "../../components/ColorPicker";
import {
  startWithHTTPS,
  hexColorRequired,
  coordinatesPattern,
  requiredIfExtIdIsNotEmpty,
} from "../../utils/validators";
import { ActivitiesManager } from "../../components/ActivitiesManager";
import { LodgingTypesManager } from "../../components/LodgingTypesManager";

import { visibleFlags, featuredFlags } from "../../constants/utils";
import { GroupEventsManager } from "../../components/GroupEventsManager";
import { AmenitiesManager } from "../../components/AmenitiesManager";
import { PromotionsManager } from "../../components/PromotionsManager";
import { GalleryEditor } from "../../components/GalleryEditor/GalleryEditor";

import { CreateScreen as PropertyUploadsCreate } from "../../components/property-uploads/create";
import { EditScreen as PropertyUploadsEdit } from "../../components/property-uploads/edit";

import { HExpEditor } from "../../components/HExpEditor/HExpEditor";
import { CreateScreen as HExpCreate } from "../../components/highlighted-experiences/create";
import { EditScreen as HExpEdit } from "../../components/highlighted-experiences/edit";

import { CreateScreen as HGearsCreate } from "../../components/highlighted-gears/create";
import { EditScreen as HGearsEdit } from "../../components/highlighted-gears/edit";

import { LocalRecEditor } from "../../components/LocalRecEditor/LocalRecEditor";
import { CreateScreen as LocalRecCreate } from "../../components/local-recommendations/create";
import { EditScreen as LocalRecEdit } from "../../components/local-recommendations/edit";
import { RoomCategoryEditor } from "../../components/RoomCategoryEditor/RoomCategoryEditor";
import { RaEditWrapper } from "../../components/RaEditWrapper";
import { HGearsEditor } from "../../components/HGearsEditor/HGearsEditor";

interface PropertyFilesType {
  upload_id_menu: any | null;
  upload_id_map: any | null;
  upload_id_wordmark: any | null;
  upload_id_composition: any | null;
  upload_id_badge: any | null;
  property_photos: any[];
}

interface IPropertyEdit {
  [key: string]: any;
  propertyHours: PropertyHour[];
}

const PropertyEditToolbar = ({
  files,
  ...props
}: {
  files: PropertyFilesType;
}) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({
          id,
          createdAt,
          updatedAt,
          badge,
          composition,
          map,
          menu,
          wordmark,
          propertyPhotos,
          propertyHours,
          checkinTime,
          checkoutTime,
          faqs,
          ...data
        }: IPropertyEdit) => {
          const propertyFiles = {
            uploadIdBadge: files.upload_id_badge ?? badge.id,
            uploadIdComposition: files.upload_id_composition ?? composition.id,
            uploadIdMap: files.upload_id_map ?? map.id,
            uploadIdMenu: files.upload_id_menu ?? menu.id,
            uploadIdWordmark: files.upload_id_wordmark ?? wordmark.id,
            propertyPhotos: propertyPhotos
              .filter((x: any) => x?.uploadId !== undefined)
              .map((x: any) => ({ order: x.order, uploadId: x.uploadId })), // clean automatic fields Field Type by react-admin
          };
          if (files.property_photos.length > 0) {
            const propertyFilesSize = propertyFiles.propertyPhotos.length;
            const newPropertyPhotos = files.property_photos.map(
              (x: any, idx) => ({
                uploadId: x.uploadId,
                order: propertyFilesSize + idx,
              }),
            );
            propertyFiles.propertyPhotos =
              propertyFiles.propertyPhotos.concat(newPropertyPhotos);
          }
          const propertyFormattedHours = propertyHours.map((x) => ({
            dayOfWeek: x.dayOfWeek,
            openingTime: timeParser(x.openingTime),
            closingTime: timeParser(x.closingTime),
          }));
          const newFaqs = faqs.map((x: any) => ({
            question: x.question,
            answer: x.answer,
          }));

          delete data.activities;
          delete data.lodgingTypes;
          delete data.journalArticles;
          delete data.promotions;
          delete data.promotionsPage;
          delete data.roomsByCategory;
          delete data.appInfo;

          return {
            ...data,
            enabled: data.enabled,
            ...propertyFiles,
            checkinTime: timeParser(checkinTime),
            checkoutTime: timeParser(checkoutTime),
            propertyHours: propertyFormattedHours,
            faqs: newFaqs,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const PropertiesEdit = (props: any) => {
  // Grab Children Resource List.
  const dataProvider = useDataProvider();
  const [regions, setRegions] = React.useState<any>([]);
  const [partners, setPartners] = React.useState<any>([]);

  React.useEffect(() => {
    dataProvider
      .getMany("regions", { ids: [] })
      .then((response) => {
        if (response && response.data) {
          setRegions(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching regions:", error);
      });

    dataProvider
      .getMany("partners", { ids: [] })
      .then((response) => {
        if (response && response.data) {
          setPartners(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching partners:", error);
      });
  }, [dataProvider]);

  // Resource Files
  const [propertyFiles, setPropertyFiles] = React.useState({
    upload_id_menu: null,
    upload_id_map: null,
    upload_id_wordmark: null,
    upload_id_composition: null,
    upload_id_badge: null,
    property_photos: [],
  });
  const propertyFilesRef = React.useRef<any>(propertyFiles);
  propertyFilesRef.current = propertyFiles;

  const [propertyImgs, setPropertyImgs] = React.useState<{
    [key: string]: any;
  }>({});
  const propertyImgsRef = React.useRef<any>();
  propertyImgsRef.current = propertyImgs;

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});
  const uploadedFileNamesRef = React.useRef<any>();
  uploadedFileNamesRef.current = uploadedFileNames;

  const { uploadFile } = useFileUpload();
  const { id } = useParams();
  const { data } = useGetOne("/properties", { id });
  const notify = useNotify();

  const propertyName = data
    ? `${data.name} ${data.isComingSoon ? "(Coming Soon)" : "(Live)"}`
    : "";

  const handlePrefileUpload = async (
    name:
      | "upload_id_menu"
      | "upload_id_map"
      | "upload_id_wordmark"
      | "upload_id_composition"
      | "property_photos"
      | "upload_id_badge",
    files: File[],
  ) => {
    const isPropertyPhotos = name === "property_photos";
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({
      ...uploadedFileNamesRef.current,
      [fileName]: "Uploading...",
    });

    if (!isPropertyPhotos) {
      setPropertyImgs({
        ...propertyImgsRef.current,
        [name]: {
          uploading: true,
        },
      });
    }

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      // Add the new upload info to the property imgs.
      if (!isPropertyPhotos) {
        setPropertyImgs({
          ...propertyImgsRef.current,
          [name]: {
            title: asset.info.original_filename,
            uri: asset.uri,
            uploading: false,
          },
        });
      }

      const newValue = isPropertyPhotos
        ? [...propertyFilesRef.current.property_photos, { uploadId: asset.id }]
        : asset.id;
      setPropertyFiles({ ...propertyFilesRef.current, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNamesRef.current, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNamesRef.current,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const renderImgPreview = (name: string) => {
    if (!propertyImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={propertyImgs[name].uri}
              title={propertyImgs[name].title}
              width={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="info.original_filename" />
      </FileFieldWithContext>
    );
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNamesRef.current, title)) {
      return null;
    }

    if (uploadedFileNamesRef.current[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNamesRef.current[title]}</p>;
    }
  };

  const roomsByCategory = data && data.roomsByCategory;

  return (
    <RaEditWrapper mutationMode="pessimistic" title={propertyName}>
      <TabbedForm
        toolbar={<PropertyEditToolbar files={propertyFiles} />}
        record={data}
      >
        <FormTab label="Details">
          <div className="form-text-input-row">
            <SelectInput
              choices={visibleFlags}
              source="enabled"
              label="Visibility"
              defaultValue="false"
              validate={[required()]}
            />
          </div>
          <div className="form-text-input-row">
            <TextInputCounter
              source="name"
              validate={[required(), maxLength(50)]}
              limit={50}
            />
            <TextInputCounter
              source="slug"
              label="Slug"
              validate={[required()]}
            />
            <TextInputCounter
              source="akiaId"
              label="Akia ID"
              validate={[required(), maxLength(50)]}
              limit={50}
            />
            <TextInputCounter
              source="locality"
              label="City"
              validate={[required(), maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              source="area"
              label="State"
              validate={[
                required(),
                maxLength(2, "Expected 2 chars. E.g.: NY"),
              ]}
              limit={2}
            />
          </div>
          <div className="form-input-row">
            <TextInputCounter
              source="pageTitle"
              multiline
              fullWidth
              resettable
              validate={[maxLength(1000)]}
              limit={1000}
            />
          </div>
          <div className="form-input-row">
            <TextInputCounter
              source="description"
              multiline
              fullWidth
              resettable
              validate={[required()]}
            />
          </div>
          <div className="form-text-input-row">
            <TextInputCounter
              source="externalId"
              label="External ID"
              placeholder="External ID"
              parse={(v: any) => (v === "" ? null : v)}
              validate={maxLength(50)}
              limit={50}
            />
            <SelectInput
              choices={countryListAllIsoData}
              source="countryCode"
              validate={[required()]}
            />
            <TextInputCounter
              source="postalAddress"
              validate={[required(), maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              source="postalCode"
              validate={[required(), maxLength(10)]}
              limit={10}
            />
          </div>
          <div className="form-text-input-row">
            <TextInputCounter
              source="email"
              validate={[requiredIfExtIdIsNotEmpty, maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              source="phoneNumber"
              validate={[maxLength(250)]}
              limit={250}
            />
            <TimeInput
              source="checkinTime"
              validate={[required()]}
              format={timeFormatter}
              parse={timeParser}
            />
            <TimeInput
              source="checkoutTime"
              validate={[required()]}
              format={timeFormatter}
              parse={timeParser}
            />
          </div>
          <div className="form-text-input-row">
            <SelectInput
              choices={getAllTimezones()}
              source="timezoneName"
              validate={[required()]}
            />
            <TextInputCounter
              source="coordinates"
              validate={[
                required(),
                regex(coordinatesPattern, "Expected format is (2.123,1.234)"),
              ]}
            />
            <NumberInput source="homeScreenOrder" validate={[required()]} />
          </div>
          <div className="form-text-input-row">
            <ColorPicker
              source="primaryColor"
              label="Primary Color (e.g.: #00ABFA)"
              validate={[required(), hexColorRequired]}
              className="form-picker-full-width"
            />
            <ColorPicker
              source="secondaryColor"
              label="Secondary Color (e.g.: #00ABFA)"
              validate={[required(), hexColorRequired]}
              className="form-picker-full-width"
            />
          </div>
          <div className="form-input-row">
            <TextInputCounter
              source="houseRules"
              multiline
              fullWidth
              validate={[requiredIfExtIdIsNotEmpty]}
            />
          </div>
          <div className="form-input-row">
            <TextInputCounter
              source="accessibilityCopy"
              label="Accessibility"
              multiline
              fullWidth
              validate={[]}
            />
          </div>
          <div className="form-text-input-row">
            <TextInputCounter
              source="wifiSsid"
              validate={[requiredIfExtIdIsNotEmpty, maxLength(30)]}
              limit={30}
            />
            <TextInputCounter
              source="wifiPassword"
              validate={[requiredIfExtIdIsNotEmpty, maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              source="comingSoonLabel"
              validate={[maxLength(15)]}
              limit={15}
            />
          </div>
        </FormTab>
        <FormTab label="Hours">
          <ArrayInput source="propertyHours" label="Office hours">
            <SimpleFormIterator inline>
              <SelectInput
                source="dayOfWeek"
                helperText={false}
                choices={week_days}
                validate={[required()]}
              />
              <TimeInput
                source="openingTime"
                helperText={false}
                validate={[required()]}
                format={timeFormatter}
                parse={timeParser}
              />
              <TimeInput
                source="closingTime"
                helperText={false}
                validate={[required()]}
                format={timeFormatter}
                parse={timeParser}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="FAQs">
          <ArrayInput source="faqs" label="FAQs">
            <SimpleFormIterator inline>
              <TextInputCounter
                source="question"
                helperText={false}
                validate={[required()]}
              />
              <TextInputCounter
                source="answer"
                helperText={false}
                validate={[required()]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Relationships">
          <div className="form-text-input-row">
            <SelectInput
              choices={featuredFlags}
              source="featuredOnNavigation"
              label="Featured Destination?"
              defaultValue="false"
              validate={[required()]}
            />
            <SelectInput
              source="regionId"
              label="Region"
              choices={regions.map((type: any) => ({
                id: type.id,
                name: type.name,
              }))}
              validate={[]}
            />
            <SelectInput
              source="partnerId"
              label="Partner"
              choices={partners.map((type: any) => ({
                id: type.id,
                name: type.name,
              }))}
              validate={[]}
            />
          </div>

          {!!id && (
            <>
              <LodgingTypesManager id={id} />
              <ActivitiesManager id={id} />
              <GroupEventsManager id={id} />
              <AmenitiesManager id={id} />
              <PromotionsManager id={id} />
            </>
          )}
        </FormTab>
        <FormTab label="Badges">
          <h4 className="heading-tab-section">Property Badges</h4>

          <div className="property-badges-grid">
            <ImageInput
              source="wordmark"
              label="Property Badge - Name (.png)"
              accept={"image/png"}
              validate={[required()]}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "upload_id_wordmark",
                ),
              }}
            >
              {renderImgPreview("upload_id_wordmark")}
            </ImageInput>
            <ImageInput
              source="composition"
              label="Property Badge - Home Card Configuration (.png)"
              accept={"image/png"}
              validate={[required()]}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "upload_id_composition",
                ),
              }}
            >
              {renderImgPreview("upload_id_composition")}
            </ImageInput>
            <ImageInput
              source="badge"
              label="Property Badge (.png)"
              accept={"image/png"}
              validate={[required()]}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "upload_id_badge",
                ),
              }}
            >
              {renderImgPreview("upload_id_badge")}
            </ImageInput>
          </div>
        </FormTab>
        <FormTab label="Menu/Map">
          <h4 className="heading-file-uploads">Property Menu</h4>

          <FileInput
            source="menu"
            label="Menu PDF (.pdf)"
            accept={"application/pdf"}
            validate={[required()]}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "upload_id_menu"),
            }}
          >
            <FileFieldWithContext
              placeholder={<FileField source="src" title="title" />}
            >
              <FileField source="uri" title="info.original_filename" />
            </FileFieldWithContext>
          </FileInput>

          <h4 className="heading-file-uploads">Property Map</h4>

          <FileInput
            source="map"
            label="Property Map Image (.pdf)"
            validate={[required()]}
            accept={"application/pdf"}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "upload_id_map"),
            }}
          >
            <FileFieldWithContext
              placeholder={<FileField source="src" title="title" />}
            >
              <FileField source="uri" title="info.original_filename" />
            </FileFieldWithContext>
          </FileInput>
        </FormTab>
        <FormTab label="Mobile">
          <h4 className="heading-tab-section">Property Photos</h4>

          <ArrayInput source="propertyPhotos">
            <SimpleFormIterator inline>
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => {
                  const source = getSource ? getSource("upload") : "";
                  const { uploadId } = scopedFormData;

                  return (
                    <ImageInput
                      source={source}
                      helperText={false}
                      validate={[required()]}
                      accept={"image/png,image/jpeg,image/jpg"}
                      options={{
                        onDropAccepted: handlePrefileUpload.bind(
                          null,
                          "property_photos",
                        ),
                      }}
                    >
                      <FileFieldWithContext
                        placeholder={<ImageField source="src" title="title" />}
                      >
                        <ImageField
                          source="uri"
                          title="info.original_filename"
                        />
                      </FileFieldWithContext>
                    </ImageInput>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Gallery">
          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"gallery"}
            createNewLabel="Add New Image"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Property Photos"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={true}
            unique={false}
            hasEnabled={false}
            limit={null}
          />
        </FormTab>
        <FormTab label="Covers">
          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"navigationPhoto"}
            createNewLabel="Add New Navigation Photo"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Navigation Photos"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={true}
            hasEnabled={false}
            limit={3}
          />

          <hr />

          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"hero"}
            createNewLabel="Add New Property Hero"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Property Details Hero"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={false}
            hasEnabled={false}
            limit={1}
          />

          <hr />

          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"groupEvents"}
            createNewLabel="Add New Group Event Cover"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Group Events Cover"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={false}
            hasEnabled={false}
            limit={1}
          />

          <hr />

          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"navigationFeatured"}
            createNewLabel="Add New Nav Featured Cover"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Nav Featured Cover"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={false}
            hasEnabled={false}
            limit={1}
          />

          <hr />

          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"navigationCover"}
            createNewLabel="Add New Navigation Cover"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Navigation Cover"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={false}
            hasEnabled={false}
            limit={1}
          />

          <hr />

          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"navigationExperience"}
            createNewLabel="Add New Nav Experiences Cover"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Nav Experiences Cover"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={false}
            hasEnabled={false}
            limit={1}
          />

          <hr />

          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"navigationGearRental"}
            createNewLabel="Add New Nav Gear Rental Cover"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Nav Gear Rental Cover"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={false}
            hasEnabled={false}
            limit={1}
          />

          <hr />

          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"experiencesHero"}
            createNewLabel="Add New Experiences Hero"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Experiences Hero"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={false}
            hasEnabled={false}
            limit={1}
          />

          <hr />

          <GalleryEditor
            resource={"property-uploads"}
            propertyId={id}
            categoryCode={"gearRentalHero"}
            createNewLabel="Add New Gear Rental Hero"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Gear Rental Hero"
            CreateScreen={PropertyUploadsCreate}
            EditScreen={PropertyUploadsEdit}
            hasOrder={false}
            hasEnabled={false}
            limit={1}
          />
        </FormTab>
        <FormTab label="Group Events">
          <TextInputCounter
            source="groupEventsShortDescription"
            multiline
            fullWidth
            resettable
            validate={[]}
          />
          <TextInputCounter
            source="groupEventsLongDescription"
            multiline
            fullWidth
            resettable
            validate={[]}
          />
        </FormTab>
        <FormTab label="Highlighted Exp">
          <HExpEditor
            resource={"highlighted-experiences"}
            propertyId={id}
            createNewLabel="Add New Highlighted Exp."
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Highlighted Experiences"
            CreateScreen={HExpCreate}
            EditScreen={HExpEdit}
            hasOrder={true}
            hasEnabled={true}
            limit={12}
          />
        </FormTab>
        <FormTab label="Highlighted Gear Rental">
          <TextInputCounter
            source="highlightedGearsTitle"
            label="Section Title"
            validate={[maxLength(15)]}
            limit={15}
            fullWidth
          />
          <HGearsEditor
            resource={"highlighted-gears"}
            propertyId={id}
            createNewLabel="+ New Highlighted Gear"
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Highlighted Gear Rental"
            CreateScreen={HGearsCreate}
            EditScreen={HGearsEdit}
            hasOrder={true}
            hasEnabled={true}
            limit={12}
          />
        </FormTab>
        <FormTab label="Local Recs.">
          <LocalRecEditor
            resource={"local-recommendations"}
            propertyId={id}
            createNewLabel="Add New Local Rec."
            updatedOrderMsg="Item order updated."
            updatingOrderMsg="Updating item order..."
            heading="Local Recommendations"
            CreateScreen={LocalRecCreate}
            EditScreen={LocalRecEdit}
            hasOrder={true}
            hasEnabled={true}
            limit={null}
          />
        </FormTab>
        <FormTab label="Room Categories">
          <RoomCategoryEditor
            categories={roomsByCategory}
            externalId={data && data.externalId}
          />
        </FormTab>
        <FormTab label="Connections">
          <TextInputCounter
            source="experienceUrl"
            label="Experience URL"
            validate={[startWithHTTPS, maxLength(250)]}
            limit={250}
            fullWidth
          />
          <TextInputCounter
            source="gearUrl"
            label="Gear URL"
            validate={[
              requiredIfExtIdIsNotEmpty,
              startWithHTTPS,
              maxLength(250),
            ]}
            limit={250}
            fullWidth
          />
          <TextInputCounter
            source="jobsUrl"
            label="Jobs URL"
            validate={[startWithHTTPS, maxLength(250)]}
            limit={250}
            fullWidth
          />
          <TextInputCounter
            source="groupContactFormId"
            validate={[]}
            label="Group Contact Form ID"
            fullWidth
          />
          <TextInputCounter
            source="wayCoId"
            validate={[]}
            label="WayCo ID"
            fullWidth
          />
          <TextInputCounter
            source="wayCoParameters"
            validate={[]}
            label="WayCo Parameters"
            fullWidth
          />
          <TextInputCounter
            source="checkfrontId"
            validate={[]}
            label="CheckFront ID"
            fullWidth
          />
          <TextInputCounter
            label="Experiences Calendar URL"
            source="experiencesCalendarUrl"
            validate={[startWithHTTPS]}
            fullWidth
          />
          <TextInputCounter
            label="Strava URL"
            source="stravaUrl"
            validate={[startWithHTTPS, maxLength(500)]}
            fullWidth
            limit={500}
          />
          <TextInputCounter
            label="Revinate API Key"
            source="revinateKey"
            validate={[maxLength(32)]}
            fullWidth
            limit={32}
          />
          <TextInputCounter
            label="Revinate API Secret"
            source="revinateSecret"
            validate={[maxLength(64)]}
            fullWidth
            limit={64}
          />
          <TextInputCounter
            label="Waitlist URL"
            source="waitlistUrl"
            validate={[startWithHTTPS]}
            fullWidth
          />
        </FormTab>
      </TabbedForm>
    </RaEditWrapper>
  );
};
