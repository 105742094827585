import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../TextInputCounter/TextInputCounter";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";
import { hexColorRequired } from "../../utils/validators";

interface ICreate {
  [key: string]: any;
}

const CreateToolbar = ({ ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="Create"
        style={{ top: 54 }}
        transform={({ ...data }: ICreate) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            publishedAt:
              data.enabled == true || data.enabled == "true"
                ? date.toISOString()
                : null,
            order: 100,
          };

          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const CreateScreen = (props: any) => {
  const { onCreated, hasEnabled, resource } = props;
  const notify = useNotify();

  const onSuccess = (data: any) => {
    notify(`Changes saved`);
    onCreated(data.id);
  };

  // Grab Children Resource List.
  const dataProvider = useDataProvider();
  const [properties, setProperties] = React.useState<any>([]);

  React.useEffect(() => {
    dataProvider
      .getMany("properties", { ids: [] })
      .then((response) => {
        if (response && response.data) {
          setProperties(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
      });
  }, [dataProvider]);

  return (
    <Create
      resource={resource}
      redirect={false}
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm toolbar={<CreateToolbar />}>
        <div className="form-text-input-row">
          <SelectInput
            source="propertyId"
            label="Property"
            choices={properties.map((type: any) => ({
              id: type.id,
              name: type.name,
            }))}
            validate={[required()]}
          />
          <SelectInput
            choices={enabledFlags}
            source="enabled"
            label="Enabled?"
            defaultValue="false"
            validate={[required()]}
          />
        </div>
      </SimpleForm>
    </Create>
  );
};
