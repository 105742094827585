export const enabledFlags = [
  { id: "true", name: "Enabled" },
  { id: "false", name: "Disabled" },
];

export const featuredFlags = [
  { id: "true", name: "Yes" },
  { id: "false", name: "No" },
];

export const visibleFlags = [
  { id: "true", name: "Visible" },
  { id: "false", name: "Hidden" },
];

export const promoTypes = [
  { id: "rate", name: "Rate" },
  { id: "code", name: "Promo Code" },
];
