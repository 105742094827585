import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
  Edit,
  useGetOne,
  useEditController,
  maxLength,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";

const DATAPROVIDER_RESOURCE_ID = "contact-us-page-faqs";
const RESOURCE_DISPLAY_NAME = "FAQs";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            // publishedAt: data.enabled == true || data.enabled == "true" ? date.toISOString() : null,
          };

          // Clean up fields.
          delete mappedRequest.order;
          delete mappedRequest.enabled;
          delete mappedRequest.id;

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const { id, onCreated, hasEnabled, resource } = props;
  const notify = useNotify();

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <Edit
      mutationMode="pessimistic"
      resource={resource}
      id={id}
      mutationOptions={{ onSuccess: onCreated }}
      redirect={false}
    >
      <SimpleForm
        toolbar={<EditToolbar record={record} hasEnabled={hasEnabled} />}
        id={id}
        resource={resource}
      >
        {hasEnabled ? (
          <div className="form-text-input-row">
            <SelectInput
              choices={enabledFlags}
              source="enabled"
              label="Enabled?"
              defaultValue="false"
              validate={[required()]}
            />
          </div>
        ) : null}

        <TextInputCounter
          source="questionText"
          multiline
          fullWidth
          resettable
          label="Question Text"
          validate={[required(), maxLength(250)]}
          limit={250}
        />

        <TextInputCounter
          source="answerText"
          multiline
          fullWidth
          resettable
          label="Answer Text"
          validate={[required(), maxLength(500)]}
          limit={500}
        />
      </SimpleForm>
    </Edit>
  );
};
