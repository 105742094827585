import { useState } from "react";
import "./RoomCategoryEditor.css";
import { Button, useDataProvider, useNotify } from "react-admin";
import Switch from "@mui/material/Switch";
import { RoomEditorManager } from "../RoomEditor/RoomEditor";

const CategoryEditor = (props: any) => {
  const { category, onSubmit, externalId } = props;
  const {
    code,
    description,
    id,
    name,
    hasRoomAmenities,
    hasAccessibleAccommodations,
    rooms,
  } = category;
  const [localDescription, setLocalDescription] = useState<string>(description);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [localHasRoomAmenities, setLocalHasRoomAmenities] =
    useState<boolean>(hasRoomAmenities);

  const [localHasAccessibleAccom, setLocalHasAccessibleAccom] =
    useState<boolean>(hasAccessibleAccommodations);

  const charCount = localDescription.length;
  const maxChar = 140;

  const onSave = async (e: any) => {
    e.preventDefault();

    if (
      localDescription === description &&
      localHasAccessibleAccom === hasAccessibleAccommodations &&
      localHasRoomAmenities === hasRoomAmenities
    ) {
      return;
    }

    setIsLoading(true);

    await onSubmit({
      id: id,
      categoryCode: code,
      description: localDescription,
      hasRoomAmenities: localHasRoomAmenities ?? false,
      hasAccessibleAccommodations: localHasAccessibleAccom ?? false,
    });

    setIsLoading(false);
  };

  const onEdit = (e: any) => {
    if (e.target.value.length <= maxChar) {
      setLocalDescription(e.target.value);
    }
  };

  const handleHasRoomChange = (e: any) => {
    setLocalHasRoomAmenities((prev: boolean) => !prev);
  };

  const handleHasAccessibleAccomm = (e: any) => {
    setLocalHasAccessibleAccom((prev: boolean) => !prev);
  };

  return (
    <div className="category">
      <p className="catTitle">
        {name} - {code}
      </p>

      <div className="inputToggleWrapper">
        <div className="inputDesc">
          <div className="toggleLayout">
            <div>
              <Switch
                checked={localHasRoomAmenities}
                onChange={handleHasRoomChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <label>Has Room Amenities?</label>
          </div>
        </div>
        <div className="inputDesc">
          <div className="toggleLayout">
            <div>
              <Switch
                checked={localHasAccessibleAccom}
                onChange={handleHasAccessibleAccomm}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            <label>Has Accessible Accommodations?</label>
          </div>
        </div>
      </div>
      <div className="inputDesc">
        <label>Description</label>
        <textarea onChange={onEdit} value={localDescription}></textarea>{" "}
        <span>
          {charCount}/{maxChar}
        </span>
      </div>
      <Button
        onClick={onSave}
        color="primary"
        variant="contained"
        label={isLoading ? "Loading..." : "Save"}
        disabled={
          (localDescription === description &&
            localHasAccessibleAccom === hasAccessibleAccommodations &&
            localHasRoomAmenities === hasRoomAmenities) ||
          isLoading
        }
      />
      <div>
        <RoomEditorManager rooms={rooms} externalId={externalId} />
      </div>
    </div>
  );
};

export const RoomCategoryEditor = (props: any) => {
  const { categories, externalId } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();

  if (!categories) {
    return (
      <div className="wrapper">
        <p>Loading...</p>
      </div>
    );
  }

  const onSubmit = async (data: any) => {
    const payload = {
      externalId: externalId,
      ...data,
    };

    try {
      const result = await dataProvider.updateRoomCategory(payload);

      notify("Room category updated with success!");
    } catch (err) {
      notify("Error updating room category: " + payload.code);
    }
  };

  return (
    <div className="wrapper">
      {categories.map((category: any, i: number) => {
        return (
          <CategoryEditor
            onSubmit={onSubmit}
            externalId={externalId}
            category={category}
            key={i}
          />
        );
      })}
    </div>
  );
};
