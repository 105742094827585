import { ResourceProps } from "react-admin";
import Icon from "@mui/icons-material/NightShelter";
import { Resource } from "../../enums";
import ReviewsList from "./ReviewsList";

const reviews: ResourceProps = {
  name: Resource.REVIEWS,
  icon: Icon,
  list: ReviewsList,
  options: {
    label: "Guest Reviews",
  },
};

export default reviews;
