import { fetchUtils } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { stringify } from "querystring";

const httpClient = async (url: string, options: any = {}) => {
  if (!options?.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  // Include the API Key.
  options.headers = new Headers({
    ...options.headers,
    "X-Api-Key": process.env.REACT_APP_LOGE_API_KEY,
  });

  let accessToken = Cookies.get("accessToken");

  if (accessToken) {
    const decodedToken: any = jwtDecode(accessToken);

    const now = Date.now() / 1000;

    if (600 > decodedToken.exp - now) {
      const refreshToken = Cookies.get("refreshToken");

      if (refreshToken) {
        // change refresh url
        const { json } = await fetchUtils.fetchJson(
          `${process.env.REACT_APP_API_URL}/users/refresh-token`,
          {
            method: "POST",
            body: JSON.stringify({ refresh: refreshToken }),
            headers: new Headers({
              "X-Api-Key": process.env.REACT_APP_LOGE_API_KEY!,
            }),
          },
        );

        ({ accessToken } = json);

        if (accessToken) {
          Cookies.set("accessToken", accessToken);
        } else {
          accessToken = undefined;

          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
        }
      } else {
        accessToken = undefined;
      }
    }
  }
  if (accessToken && options?.headers) {
    options?.headers.set("Authorization", `Bearer ${accessToken}`);
  }

  return await fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
  process.env.REACT_APP_API_URL,
  httpClient,
);

export default {
  ...dataProvider,
  getMany: (resource: string, params: any) => {
    let query;

    switch (resource) {
      case "activities":
        query = {
          showDisabled: params.showDisabled ?? true,
          onlyTopLevel: params.onlyTopLevel ?? false,
        };
        break;

      case "lodging-types":
      case "amenities":
        query = {
          showDisabled: params.showDisabled ?? true,
        };
        break;

      case "regions":
      case "partners":
      case "local-recommendation-types":
      case "local-recommendations":
      case "highlighted-experiences":
      case "highlighted-gears":
      case "group-events":
      case "group-event-page-sections":
      case "promotions":
        query = {
          onlyPublished: params.onlyPublished ?? false,
        };
        break;

      case "journal-articles":
        query = {
          onlyPublished: params.onlyPublished ?? false,
          pageSize: 300,
        };
        break;

      case "properties":
        query = {
          showHidden: true,
        };
        break;

      default:
        query = {
          filter: JSON.stringify({ ids: params.ids }),
        };
        break;
    }

    const url = `${process.env.REACT_APP_API_URL}/${resource}?${stringify(
      query,
    )}`;

    return httpClient(url).then(({ json }) => {
      if (json.data && json.data[resource]) {
        return {
          data: json.data[resource],
        };
      }

      if (json.data) {
        return {
          data: json.data,
        };
      }

      if (!Array.isArray(json)) {
        return {
          data: [json],
        };
      }

      return {
        data: json,
      };
    });
  },
  getParentActivities: () => {
    const query = {
      showDisabled: true,
      onlyTopLevel: true,
    };

    const url = `${process.env.REACT_APP_API_URL}/activities?${stringify(
      query,
    )}`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data["activities"],
      };
    });
  },
  getChildrenActivities: () => {
    const query = {
      showDisabled: false,
      onlyTopLevel: false,
    };

    const url = `${process.env.REACT_APP_API_URL}/activities?${stringify(
      query,
    )}`;

    return httpClient(url).then(({ json }) => {
      const allActvs = json.data["activities"];

      const onlyChildren = allActvs.flatMap((item: any, itemIndex: number) => {
        let subActivities = item.subActivities || [];

        return [
          ...subActivities.flatMap((subItem: any, subItemIndex: number) => {
            const newSubItem = {
              id: subItem.id,
              name: subItem.name,
            };

            return newSubItem;
          }),
        ];
      });

      return {
        data: onlyChildren,
      };
    });
  },
  getPropertyActivities: (params: any) => {
    const { id } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/activities`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data["activities"],
      };
    });
  },
  updateProperyActivities: (id: string, params: any) => {
    const { activities } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/activities`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        activities: activities,
      }),
    }).then(({ json }) => {
      return {
        data: json.assignedActivities,
      };
    });
  },
  getAmbassadorsActivities: (params: any) => {
    const { id } = params;

    const url = `${process.env.REACT_APP_API_URL}/ambassadors/${id}/activities`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data["activities"],
      };
    });
  },
  updateAmbassadorsActivities: (id: string, params: any) => {
    const { activities } = params;

    const url = `${process.env.REACT_APP_API_URL}/ambassadors/${id}/activities`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        activities: activities,
      }),
    }).then(({ json }) => {
      return {
        data: json.assignedActivities,
      };
    });
  },
  getArtistsActivities: (params: any) => {
    const { id } = params;

    const url = `${process.env.REACT_APP_API_URL}/artists/${id}/activities`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data["activities"],
      };
    });
  },
  updateArtistsActivities: (id: string, params: any) => {
    const { activities } = params;

    const url = `${process.env.REACT_APP_API_URL}/artists/${id}/activities`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        activities: activities,
      }),
    }).then(({ json }) => {
      return {
        data: json.assignedActivities,
      };
    });
  },
  getEnabledLodgingTypes: () => {
    const query = {
      showDisabled: false,
    };

    const url = `${process.env.REACT_APP_API_URL}/lodging-types?${stringify(
      query,
    )}`;

    return httpClient(url).then(({ json }) => {
      const allLTypes = json.data;

      return {
        data: allLTypes,
      };
    });
  },
  getPropertyLodgingTypes: (params: any) => {
    const { id } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/lodging-types`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updateProperyLodgingTypes: (id: string, params: any) => {
    const { lodgingTypes } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/lodging-types`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        lodgingTypes: lodgingTypes,
      }),
    }).then(({ json }) => {
      return {
        data: json.assignedActivities,
      };
    });
  },
  getEnabledGroupEvents: () => {
    const query = {
      onlyPublished: true,
    };

    const url = `${process.env.REACT_APP_API_URL}/group-events?${stringify(
      query,
    )}`;

    return httpClient(url).then(({ json }) => {
      const allLTypes = json;

      return {
        data: allLTypes,
      };
    });
  },
  getPropertyGroupEvents: (params: any) => {
    const { id } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/group-events`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updatePropertyGroupEvents: (id: string, params: any) => {
    const { groupEvents } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/group-events`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        groupEvents: groupEvents,
      }),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  getEnabledAmenities: () => {
    const query = {
      showDisabled: false,
    };

    const url = `${process.env.REACT_APP_API_URL}/amenities?${stringify(
      query,
    )}`;

    return httpClient(url).then(({ json }) => {
      const allLTypes = json;

      return {
        data: allLTypes,
      };
    });
  },
  getPropertyAmenities: (params: any) => {
    const { id } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/amenities`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updatePropertyAmenities: (id: string, params: any) => {
    const { amenities } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/amenities`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        amenities: amenities,
      }),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  getPromotions: () => {
    const query = {
      onlyPublished: false,
    };

    const url = `${process.env.REACT_APP_API_URL}/promotions?${stringify(
      query,
    )}`;

    return httpClient(url).then(({ json }) => {
      const allLTypes = json;

      return {
        data: allLTypes,
      };
    });
  },
  getEnabledPromotions: () => {
    const query = {
      onlyPublished: false,
    };

    const url = `${process.env.REACT_APP_API_URL}/promotions?${stringify(
      query,
    )}`;

    return httpClient(url).then(({ json }) => {
      const allLTypes = json;

      return {
        data: allLTypes,
      };
    });
  },
  getPropertyPromotions: (params: any) => {
    const { id } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/promotions`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updatePropertyPromotions: (id: string, params: any) => {
    const { promotions } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/promotions`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        promotions: promotions,
      }),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  getPropertyUploads: (params: any) => {
    const { propertyId, categoryCode } = params;

    const url = `${process.env.REACT_APP_API_URL}/property-uploads?propertyId=${propertyId}&categoryCode=${categoryCode}`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json,
      };
    });
  },
  getEventUploads: (params: any) => {
    const { eventId } = params;

    const url = `${process.env.REACT_APP_API_URL}/event-uploads?eventId=${eventId}`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json,
      };
    });
  },
  getEventAttendees: (params: any) => {
    const { eventId } = params;

    const url = `${process.env.REACT_APP_API_URL}/events/${eventId}/attendees`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json,
      };
    });
  },
  getPropertyHExps: (params: any) => {
    const { propertyId } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${propertyId}/highlighted-experiences`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updatePropertyHighlightedExperiences: (id: string, params: any) => {
    const { highlightedExperiences } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/highlighted-experiences`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        highlightedExperiences: highlightedExperiences,
      }),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  getPropertyHGears: (params: any) => {
    const { propertyId } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${propertyId}/highlighted-gears`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updatePropertyHighlightedGears: (id: string, params: any) => {
    const { highlightedGears } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/highlighted-gears`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        highlightedGears: highlightedGears,
      }),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  getPropertyLocalRec: (params: any) => {
    const { propertyId } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${propertyId}/local-recommendations`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updatePropertyLocalRec: (id: string, params: any) => {
    const { localRecommendations } = params;

    const url = `${process.env.REACT_APP_API_URL}/properties/${id}/local-recommendations`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        localRecommendations: localRecommendations,
      }),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  getEnabledJournalTags: () => {
    const query = {};

    const url = `${process.env.REACT_APP_API_URL}/journal-tags?${stringify(
      query,
    )}`;

    return httpClient(url).then(({ json }) => {
      const allLTypes = json;

      return {
        data: allLTypes,
      };
    });
  },
  getJournalArticleTags: (params: any) => {
    const { id } = params;

    const url = `${process.env.REACT_APP_API_URL}/journal-articles/${id}/tags`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updateJournalArticleTags: (id: string, params: any) => {
    const { tags } = params;

    const url = `${process.env.REACT_APP_API_URL}/journal-articles/${id}/tags`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        journalTags: tags,
      }),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  getEnabledJournalProperties: () => {
    const query = {};

    const url = `${process.env.REACT_APP_API_URL}/properties?${stringify(
      query,
    )}`;

    return httpClient(url).then(({ json }) => {
      const allLTypes = json;

      return {
        data: allLTypes.data.properties,
      };
    });
  },
  getJournalArticleProperties: (params: any) => {
    const { id } = params;

    const url = `${process.env.REACT_APP_API_URL}/journal-articles/${id}/properties`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updateJournalArticleProperties: (id: string, params: any) => {
    const { properties } = params;

    const url = `${process.env.REACT_APP_API_URL}/journal-articles/${id}/properties`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({
        properties: properties,
      }),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  getGroupsSectionUploads: (params: any) => {
    const { groupEventPageSectionId } = params;

    const url = `${process.env.REACT_APP_API_URL}/group-event-page-section-uploads?groupEventPageSectionId=${groupEventPageSectionId}`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json,
      };
    });
  },
  getJournalArticleList: (params: any) => {
    const { page } = params;

    const url = `${process.env.REACT_APP_API_URL}/journal-articles?pageNumber=${page}&pageSize=20`;
    return httpClient(url).then(({ json }) => {
      return {
        data: json.data,
        metadata: json.metadata,
      };
    });
  },
  updateRoomCategory: (payload: any) => {
    const url = `${process.env.REACT_APP_API_URL}/properties/room-categories`;
    return httpClient(url, {
      method: "put",
      body: JSON.stringify(payload),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  updateRoom: (payload: any) => {
    const url = `${process.env.REACT_APP_API_URL}/properties/rooms`;
    return httpClient(url, {
      method: "put",
      body: JSON.stringify(payload),
    }).then(({ json }) => {
      return {
        data: json.data,
      };
    });
  },
  getAllPropertyReviews: (externalId: string) => {
    const query = {};

    const url = `${process.env.REACT_APP_API_URL}/property-reviews/${externalId}/`;

    return httpClient(url).then(({ json }) => {
      const allReviews = json;

      return {
        data: allReviews.reviews,
      };
    });
  },
  updatePropertyReview: (id: string, payload: any) => {
    const url = `${process.env.REACT_APP_API_URL}/property-reviews/${id}`;
    return httpClient(url, {
      method: "put",
      body: JSON.stringify(payload),
    }).then(({ json }) => {
      return {
        data: json.review,
      };
    });
  },
  fetchProperyReviews: (externalId: string, payload: any) => {
    const url = `${process.env.REACT_APP_API_URL}/property-reviews/fetch/?externalId=${externalId}&fromDate=${payload.from}&toDate=${payload.to}`;
    return httpClient(url, {
      method: "post",
      body: JSON.stringify({}),
    }).then(({ json }) => {
      return {
        data: json.createdOnDb ? true : false,
      };
    });
  },
  getAllPropertyEvents: (externalId: string) => {
    const query = {};

    const url = `${process.env.REACT_APP_API_URL}/events/property/${externalId}`;

    return httpClient(url).then(({ json }) => {
      const allEvents = json;

      return {
        data: allEvents,
      };
    });
  },
  deleteEvent: (eventId: string) => {
    const query = {};

    const url = `${process.env.REACT_APP_API_URL}/events/${eventId}`;

    return httpClient(url, {
      method: "delete",
    }).then(({ json }) => {
      const allEvents = json;

      return {
        data: allEvents,
      };
    });
  },
};
