import { ReactNode, useCallback, useState } from "react";
import { Confirm } from "react-admin";
import Icon from "@mui/material/Icon";

import NorthRoundedIcon from "@mui/icons-material/NorthRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  ItemText,
  EditControl,
  StyledIcon,
  StyledItemRow,
} from "./ResourceEditor.styled";

import { UsualResourceItem } from "./ResourceEditor";
import { ResourceEditorDialog } from "./ResourceEditorDialog";

export type ResourceEditorItemProps<ResourceItemType> = {
  resource: ResourceItemType;
  children: ReactNode;
  canOrder: boolean;
  isFirst: boolean;
  isLast: boolean;
  isDisabled: boolean;
  isLoading?: boolean;
  EditComponent?: ReactNode;
  onDeleteItem: (resource: ResourceItemType) => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
};

export const ResourceEditorItem = <T extends UsualResourceItem>({
  resource,
  children,
  canOrder,
  EditComponent,
  isDisabled,
  isFirst,
  isLast,
  isLoading,
  onDeleteItem,
  onMoveUp,
  onMoveDown,
}: ResourceEditorItemProps<T>) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const iconColor = isLoading ? "gray" : undefined;
  const additionalStyles = !isLoading ? {} : { cursor: "not-allowed" };

  const handleDeleteItem = useCallback(
    () => onDeleteItem(resource),
    [onDeleteItem, resource],
  );

  const handleOpenEditDialog = useCallback(() => setEditDialogOpen(true), []);

  const handleCloseEditDialog = useCallback(() => setEditDialogOpen(false), []);

  const handleOpenDeleteDialog = useCallback(
    () => setDeleteDialogOpen(true),
    [],
  );

  const handleCloseDeleteDialog = useCallback(
    () => setDeleteDialogOpen(false),
    [],
  );

  return (
    <StyledItemRow key={resource.id}>
      <Confirm
        isOpen={deleteDialogOpen}
        loading={isLoading}
        title={`Delete`}
        content="Are you sure you want to delete this item?"
        onConfirm={handleDeleteItem}
        onClose={handleCloseDeleteDialog}
      />

      {canOrder && (
        <>
          <StyledIcon
            component={NorthRoundedIcon}
            $display={!isFirst}
            $color={iconColor}
            style={additionalStyles}
            fontSize="small"
            onClick={onMoveUp}
          />
          <StyledIcon
            component={SouthRoundedIcon}
            $display={!isLast}
            $color={iconColor}
            style={additionalStyles}
            fontSize="small"
            onClick={onMoveDown}
          />
        </>
      )}

      <ItemText $disabled={isDisabled}>{children}</ItemText>

      {!!EditComponent && (
        <>
          <EditControl onClick={handleOpenEditDialog}>
            <Icon
              component={EditOutlinedIcon}
              sx={{
                color: "#000",
                verticalAlign: "text-top",
                marginRight: "8px",
              }}
              fontSize="inherit"
            />
            Edit
          </EditControl>

          <ResourceEditorDialog
            isOpen={editDialogOpen}
            content={EditComponent}
            onClose={handleCloseEditDialog}
          />
        </>
      )}

      <StyledIcon
        component={CloseRoundedIcon}
        $color="#FF3B0A"
        $display
        fontSize="small"
        onClick={handleOpenDeleteDialog}
      />
    </StyledItemRow>
  );
};
