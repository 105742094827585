import englishMessages from "ra-language-english";
import { deepmerge } from "@mui/utils";

export const en = deepmerge(englishMessages, {
  ra: {
    input: {
      image: {
        upload_single: `+ Upload an Image`,
      },
    },
    action: {
      save: "Save Changes",
    },
  },
});
