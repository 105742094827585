export function getPastTenYears() {
  const currentYear = new Date().getFullYear();

  const pastTenYears = [];

  for (let i = 0; i <= 10; i++) {
    pastTenYears.push(currentYear - i);
  }

  return pastTenYears;
}

export function isDateInFuture(year: number, month: number) {
  const today = new Date();

  const givenDate = new Date(year, month - 1);

  return givenDate > today;
}

export function getDateRange(
  fromMonth: number,
  fromYear: number,
  toMonth: number,
  toYear: number,
) {
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // getMonth() returns 0-indexed month
  const currentYear = today.getFullYear();

  // Calculate the last day of the given month and year
  function getLastDayOfMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate(); // 0th day of next month is the last day of the given month
  }

  // Format date with leading zero for day and month
  function formatDate(day: number, month: number, year: number) {
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0",
    )}`;
  }

  // Construct 'from' field
  const fromField = `${fromYear}-${String(fromMonth).padStart(2, "0")}-01`;

  // Construct 'to' field
  let lastDay;
  if (toYear === currentYear && toMonth === currentMonth) {
    lastDay = today.getDate() - 1; // Yesterday's date
  } else {
    lastDay = getLastDayOfMonth(toMonth, toYear); // Last day of the given month
  }
  const toField = formatDate(lastDay, toMonth, toYear);

  return {
    from: fromField,
    to: toField,
  };
}

export function isFirstDateBefore(
  secondYear: number,
  secondMonth: number,
  firstYear: number,
  firstMonth: number,
) {
  // Create date objects for the given months and years
  const firstDate = new Date(firstYear, firstMonth - 1); // month is zero-indexed
  const secondDate = new Date(secondYear, secondMonth - 1); // month is zero-indexed

  // Compare the dates
  return firstDate < secondDate;
}

export function getMonthsArray() {
  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  return months;
}
