import { useCallback, useEffect, useState } from "react";
import { Button, useDataProvider } from "react-admin";
import { Confirm } from "react-admin";
import "./EventItem.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styled from "styled-components";
import Icon from "@mui/material/Icon";
import { ResourceEditorDialog } from "../ResourceEditor/ResourceEditorDialog";
import { EditScreen as EventsEdit } from "../../components/events/edit";
import AttendeeList from "./AttendeeList";

export const StyledIcon = styled(Icon)<{
  component: any;
  $display: boolean;
  $color?: string;
}>`
  color: ${(p) => p.$color ?? "#19988b"};
  visibility: hidden;
  position: absolute;
  right: 5px;
  top: 5px;
  ${(p) =>
    !!p.$display &&
    `
    cursor: pointer;
    visibility: visible;
    `}
`;

const EventItem = ({
  event,
  refetchEvents,
  activities,
}: {
  event: any;
  refetchEvents: any;
  activities: any[];
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dataProvider = useDataProvider();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [attendeeListDialogOpen, setAttendeeListDialogOpen] = useState(false);

  const handleOpenAttendeeListDialog = useCallback(
    () => setAttendeeListDialogOpen(true),
    [],
  );

  const handleCloseAttendeeListDialog = useCallback(
    () => setAttendeeListDialogOpen(false),
    [],
  );

  const handleOpenEditDialog = useCallback(() => setEditDialogOpen(true), []);

  const handleCloseEditDialog = useCallback(() => setEditDialogOpen(false), []);

  const handleDeleteItem = async () => {
    setLoading(true);

    await dataProvider.deleteEvent(event.id);

    setDeleteDialogOpen(false);
    refetchEvents();
    setLoading(false);
  };

  const handleOpenDeleteDialog = useCallback(
    () => setDeleteDialogOpen(true),
    [],
  );

  const handleCloseDeleteDialog = useCallback(
    () => setDeleteDialogOpen(false),
    [],
  );

  const humanDate = new Date(event.startDate);

  return (
    <div key={event.id} className="event-item">
      <div className="event-top">
        <p className="name">
          <span className="event-title">{event.name}</span>
          {event.isFeatured ? <span className="featured">Featured</span> : null}
        </p>
        <p className="status">
          Status: {event.publishedAt ? "Published" : "Hidden"}
        </p>
      </div>
      <div className="event-bottom">
        {event.activity ? (
          <p className="date">Activity: {event.activity.name}</p>
        ) : null}

        <div className="event-data">
          <p className="date">Start Date: {humanDate.toLocaleDateString()}</p>
          <p className="date">Start Time: {event.startTime}</p>
          <p className="date">Max Attendees: {event.maxAttendees}</p>
          <p className="date">Attendees Count: {event.attendees}</p>
        </div>

        <p className="event-controls">
          <Button
            onClick={handleOpenEditDialog}
            disabled={loading}
            label="Edit Event"
            variant="contained"
          />
          <Button
            onClick={handleOpenAttendeeListDialog}
            disabled={loading}
            label="Attendees List"
            variant="contained"
          />
        </p>
      </div>

      <StyledIcon
        component={CloseRoundedIcon}
        $color="#FF3B0A"
        $display
        fontSize="small"
        onClick={handleOpenDeleteDialog}
      />

      <Confirm
        isOpen={deleteDialogOpen}
        loading={loading}
        title={`Delete`}
        content="Are you sure you want to delete this event?"
        onConfirm={handleDeleteItem}
        onClose={handleCloseDeleteDialog}
      />

      <ResourceEditorDialog
        isOpen={editDialogOpen}
        content={
          <EventsEdit
            id={event.id}
            resource={"events"}
            onCreated={refetchEvents}
            hasEnabled={true}
            activities={activities}
            event={event}
          />
        }
        onClose={handleCloseEditDialog}
      />

      <ResourceEditorDialog
        isOpen={attendeeListDialogOpen}
        content={<AttendeeList eventId={event.id} event={event} />}
        onClose={handleCloseAttendeeListDialog}
      />
    </div>
  );
};

export default EventItem;
