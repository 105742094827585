import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";

import axios from "../../clients/axios";
import Cookies from "js-cookie";
import { fetchUtils } from "react-admin";
import jwtDecode from "jwt-decode";

const ColorPlugin = require("editorjs-text-color-plugin");

export const EDITOR_JS_TOOLS = {
  embed: {
    class: Embed,
    inlineToolbar: true,
  },
  table: Table,
  marker: Marker,
  Color: {
    class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
    config: {
      colorCollections: ["#36b3a8", "#9C27B0", "#1A1A1AB3", "#1A1A1AE6"],
      defaultColor: "#36b3a8",
      type: "text",
    },
  },
  list: {
    class: List,
    inlineToolbar: true,
    config: {
      defaultStyle: "unordered",
    },
  },
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  image: {
    class: Image,
    config: {
      types: "image/png,image/jpg,image/jpeg",
      uploader: {
        /**
         * Upload file to the server and return an uploaded image data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
         */
        async uploadByFile(file) {
          try {
            let accessToken = Cookies.get("accessToken");

            if (accessToken) {
              const decodedToken = jwtDecode(accessToken);

              const now = Date.now() / 1000;

              if (600 > decodedToken.exp - now) {
                const refreshToken = Cookies.get("refreshToken");

                if (refreshToken) {
                  const { json } = await fetchUtils.fetchJson(
                    `${process.env.REACT_APP_API_URL}/users/refresh-token`,
                    {
                      method: "POST",
                      body: JSON.stringify({ refresh: refreshToken }),
                      headers: new Headers({
                        "X-Api-Key": process.env.REACT_APP_LOGE_API_KEY,
                      }),
                    },
                  );

                  ({ accessToken } = json);

                  if (accessToken) {
                    Cookies.set("accessToken", accessToken);
                  } else {
                    accessToken = undefined;

                    Cookies.remove("accessToken");
                    Cookies.remove("refreshToken");
                  }
                } else {
                  accessToken = undefined;
                }
              }
            }

            const headers = {
              headers: {
                Authorization: "Bearer " + accessToken,
                "X-Api-Key": process.env.REACT_APP_LOGE_API_KEY,
              },
            };

            const presignResponse = await axios.post("/uploads/pre-sign-url", {}, headers);

            if (!presignResponse.data) throw new Error("Error presigning url");

            const CLOUDINARY_CLOUD_NAME =
              process.env.REACT_APP_CLOUDINARY_CLOUD_NAME ?? "djxgag7qb";
            const CLOUDINARY_API_KEY =
              process.env.REACT_APP_CLOUDINARY_API_KEY ?? "468348541421976";

            const formData = new FormData();
            formData.append("file", file);
            formData.append("timestamp", presignResponse.data.timestamp);
            formData.append("signature", presignResponse.data.signature);
            formData.append("api_key", CLOUDINARY_API_KEY);

            const {
              data: { api_key, pages, ...upload },
            } = await axios.post(
              "/image/upload",
              formData,
              { baseURL: `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}` },
            );

            const backendUploadResponse = await axios.post("/uploads", { ...upload, folder: upload.folder || "uploads" }, headers);

            const data = backendUploadResponse.data;

            return {
              success: data && data.uri ? 1 : 0,
              file: {
                url: data && data.uri ? data.uri.replace('image/upload', 'image/upload/f_auto,q_auto:eco,c_limit,w_1500') : '',
              }
            };
          } catch (err) {
            console.error(err.toString());
          }
        },
      },
    },
  },
  raw: Raw,
  header: {
    class: Header,
    inlineToolbar: true,
  },
  quote: Quote,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
};
