import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  required,
  SelectInput,
  Toolbar,
  SaveButton,
  useGetOne,
  useEditController,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import { RaEditWrapper } from "../../components/RaEditWrapper";

const DATAPROVIDER_RESOURCE_ID = "regions";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            publishedAt:
              data.enabled == true || data.enabled == "true"
                ? date.toISOString()
                : null,
          };

          // Clean up fields.
          delete mappedRequest.properties;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const { id } = useParams();
  const { data } = useGetOne(`/${DATAPROVIDER_RESOURCE_ID}`, { id });

  const resourceName = data ? data.name : "";

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <RaEditWrapper mutationMode="pessimistic" title={resourceName}>
      <SimpleForm toolbar={<EditToolbar />} record={record}>
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="name"
            validate={[required()]}
          />
          <SelectInput
            choices={enabledFlags}
            source="enabled"
            label="Enabled?"
            defaultValue="false"
            validate={[required()]}
          />
        </div>
      </SimpleForm>
    </RaEditWrapper>
  );
};
