export const countryListAllIsoData = [
  { id: "US", value: "US", name: "US", code: "US" },
  { id: "AF", value: "AF", name: "AF", code: "AF" },
  { id: "AL", value: "AL", name: "AL", code: "AL" },
  { id: "DZ", value: "DZ", name: "DZ", code: "DZ" },
  { id: "AS", value: "AS", name: "AS", code: "AS" },
  { id: "AD", value: "AD", name: "AD", code: "AD" },
  { id: "AO", value: "AO", name: "AO", code: "AO" },
  { id: "AI", value: "AI", name: "AI", code: "AI" },
  { id: "AQ", value: "AQ", name: "AQ", code: "AQ" },
  { id: "AG", value: "AG", name: "AG", code: "AG" },
  { id: "AR", value: "AR", name: "AR", code: "AR" },
  { id: "AM", value: "AM", name: "AM", code: "AM" },
  { id: "AW", value: "AW", name: "AW", code: "AW" },
  { id: "AU", value: "AU", name: "AU", code: "AU" },
  { id: "AT", value: "AT", name: "AT", code: "AT" },
  { id: "AZ", value: "AZ", name: "AZ", code: "AZ" },
  { id: "BS", value: "BS", name: "BS", code: "BS" },
  { id: "BH", value: "BH", name: "BH", code: "BH" },
  { id: "BD", value: "BD", name: "BD", code: "BD" },
  { id: "BB", value: "BB", name: "BB", code: "BB" },
  { id: "BY", value: "BY", name: "BY", code: "BY" },
  { id: "BE", value: "BE", name: "BE", code: "BE" },
  { id: "BZ", value: "BZ", name: "BZ", code: "BZ" },
  { id: "BJ", value: "BJ", name: "BJ", code: "BJ" },
  { id: "BM", value: "BM", name: "BM", code: "BM" },
  { id: "BT", value: "BT", name: "BT", code: "BT" },
  { id: "BO", value: "BO", name: "BO", code: "BO" },
  { id: "BQ", value: "BQ", name: "BQ", code: "BQ" },
  { id: "BA", value: "BA", name: "BA", code: "BA" },
  { id: "BW", value: "BW", name: "BW", code: "BW" },
  { id: "BV", value: "BV", name: "BV", code: "BV" },
  { id: "BR", value: "BR", name: "BR", code: "BR" },
  { id: "IO", value: "IO", name: "IO", code: "IO" },
  { id: "BN", value: "BN", name: "BN", code: "BN" },
  { id: "BG", value: "BG", name: "BG", code: "BG" },
  { id: "BF", value: "BF", name: "BF", code: "BF" },
  { id: "BI", value: "BI", name: "BI", code: "BI" },
  { id: "CV", value: "CV", name: "CV", code: "CV" },
  { id: "KH", value: "KH", name: "KH", code: "KH" },
  { id: "CM", value: "CM", name: "CM", code: "CM" },
  { id: "CA", value: "CA", name: "CA", code: "CA" },
  { id: "KY", value: "KY", name: "KY", code: "KY" },
  { id: "CF", value: "CF", name: "CF", code: "CF" },
  { id: "TD", value: "TD", name: "TD", code: "TD" },
  { id: "CL", value: "CL", name: "CL", code: "CL" },
  { id: "CN", value: "CN", name: "CN", code: "CN" },
  { id: "CX", value: "CX", name: "CX", code: "CX" },
  { id: "CC", value: "CC", name: "CC", code: "CC" },
  { id: "CO", value: "CO", name: "CO", code: "CO" },
  { id: "KM", value: "KM", name: "KM", code: "KM" },
  { id: "CD", value: "CD", name: "CD", code: "CD" },
  { id: "CG", value: "CG", name: "CG", code: "CG" },
  { id: "CK", value: "CK", name: "CK", code: "CK" },
  { id: "CR", value: "CR", name: "CR", code: "CR" },
  { id: "HR", value: "HR", name: "HR", code: "HR" },
  { id: "CU", value: "CU", name: "CU", code: "CU" },
  { id: "CW", value: "CW", name: "CW", code: "CW" },
  { id: "CY", value: "CY", name: "CY", code: "CY" },
  { id: "CZ", value: "CZ", name: "CZ", code: "CZ" },
  { id: "CI", value: "CI", name: "CI", code: "CI" },
  { id: "DK", value: "DK", name: "DK", code: "DK" },
  { id: "DJ", value: "DJ", name: "DJ", code: "DJ" },
  { id: "DM", value: "DM", name: "DM", code: "DM" },
  { id: "DO", value: "DO", name: "DO", code: "DO" },
  { id: "EC", value: "EC", name: "EC", code: "EC" },
  { id: "EG", value: "EG", name: "EG", code: "EG" },
  { id: "SV", value: "SV", name: "SV", code: "SV" },
  { id: "GQ", value: "GQ", name: "GQ", code: "GQ" },
  { id: "ER", value: "ER", name: "ER", code: "ER" },
  { id: "EE", value: "EE", name: "EE", code: "EE" },
  { id: "SZ", value: "SZ", name: "SZ", code: "SZ" },
  { id: "ET", value: "ET", name: "ET", code: "ET" },
  { id: "FK", value: "FK", name: "FK", code: "FK" },
  { id: "FO", value: "FO", name: "FO", code: "FO" },
  { id: "FJ", value: "FJ", name: "FJ", code: "FJ" },
  { id: "FI", value: "FI", name: "FI", code: "FI" },
  { id: "FR", value: "FR", name: "FR", code: "FR" },
  { id: "GF", value: "GF", name: "GF", code: "GF" },
  { id: "PF", value: "PF", name: "PF", code: "PF" },
  { id: "TF", value: "TF", name: "TF", code: "TF" },
  { id: "GA", value: "GA", name: "GA", code: "GA" },
  { id: "GM", value: "GM", name: "GM", code: "GM" },
  { id: "GE", value: "GE", name: "GE", code: "GE" },
  { id: "DE", value: "DE", name: "DE", code: "DE" },
  { id: "GH", value: "GH", name: "GH", code: "GH" },
  { id: "GI", value: "GI", name: "GI", code: "GI" },
  { id: "GR", value: "GR", name: "GR", code: "GR" },
  { id: "GL", value: "GL", name: "GL", code: "GL" },
  { id: "GD", value: "GD", name: "GD", code: "GD" },
  { id: "GP", value: "GP", name: "GP", code: "GP" },
  { id: "GU", value: "GU", name: "GU", code: "GU" },
  { id: "GT", value: "GT", name: "GT", code: "GT" },
  { id: "GG", value: "GG", name: "GG", code: "GG" },
  { id: "GN", value: "GN", name: "GN", code: "GN" },
  { id: "GW", value: "GW", name: "GW", code: "GW" },
  { id: "GY", value: "GY", name: "GY", code: "GY" },
  { id: "HT", value: "HT", name: "HT", code: "HT" },
  { id: "HM", value: "HM", name: "HM", code: "HM" },
  { id: "VA", value: "VA", name: "VA", code: "VA" },
  { id: "HN", value: "HN", name: "HN", code: "HN" },
  { id: "HK", value: "HK", name: "HK", code: "HK" },
  { id: "HU", value: "HU", name: "HU", code: "HU" },
  { id: "IS", value: "IS", name: "IS", code: "IS" },
  { id: "IN", value: "IN", name: "IN", code: "IN" },
  { id: "ID", value: "ID", name: "ID", code: "ID" },
  { id: "IR", value: "IR", name: "IR", code: "IR" },
  { id: "IQ", value: "IQ", name: "IQ", code: "IQ" },
  { id: "IE", value: "IE", name: "IE", code: "IE" },
  { id: "IM", value: "IM", name: "IM", code: "IM" },
  { id: "IL", value: "IL", name: "IL", code: "IL" },
  { id: "IT", value: "IT", name: "IT", code: "IT" },
  { id: "JM", value: "JM", name: "JM", code: "JM" },
  { id: "JP", value: "JP", name: "JP", code: "JP" },
  { id: "JE", value: "JE", name: "JE", code: "JE" },
  { id: "JO", value: "JO", name: "JO", code: "JO" },
  { id: "KZ", value: "KZ", name: "KZ", code: "KZ" },
  { id: "KE", value: "KE", name: "KE", code: "KE" },
  { id: "KI", value: "KI", name: "KI", code: "KI" },
  { id: "KP", value: "KP", name: "KP", code: "KP" },
  { id: "KR", value: "KR", name: "KR", code: "KR" },
  { id: "KW", value: "KW", name: "KW", code: "KW" },
  { id: "KG", value: "KG", name: "KG", code: "KG" },
  { id: "LA", value: "LA", name: "LA", code: "LA" },
  { id: "LV", value: "LV", name: "LV", code: "LV" },
  { id: "LB", value: "LB", name: "LB", code: "LB" },
  { id: "LS", value: "LS", name: "LS", code: "LS" },
  { id: "LR", value: "LR", name: "LR", code: "LR" },
  { id: "LY", value: "LY", name: "LY", code: "LY" },
  { id: "LI", value: "LI", name: "LI", code: "LI" },
  { id: "LT", value: "LT", name: "LT", code: "LT" },
  { id: "LU", value: "LU", name: "LU", code: "LU" },
  { id: "MO", value: "MO", name: "MO", code: "MO" },
  { id: "MG", value: "MG", name: "MG", code: "MG" },
  { id: "MW", value: "MW", name: "MW", code: "MW" },
  { id: "MY", value: "MY", name: "MY", code: "MY" },
  { id: "MV", value: "MV", name: "MV", code: "MV" },
  { id: "ML", value: "ML", name: "ML", code: "ML" },
  { id: "MT", value: "MT", name: "MT", code: "MT" },
  { id: "MH", value: "MH", name: "MH", code: "MH" },
  { id: "MQ", value: "MQ", name: "MQ", code: "MQ" },
  { id: "MR", value: "MR", name: "MR", code: "MR" },
  { id: "MU", value: "MU", name: "MU", code: "MU" },
  { id: "YT", value: "YT", name: "YT", code: "YT" },
  { id: "MX", value: "MX", name: "MX", code: "MX" },
  { id: "FM", value: "FM", name: "FM", code: "FM" },
  { id: "MD", value: "MD", name: "MD", code: "MD" },
  { id: "MC", value: "MC", name: "MC", code: "MC" },
  { id: "MN", value: "MN", name: "MN", code: "MN" },
  { id: "ME", value: "ME", name: "ME", code: "ME" },
  { id: "MS", value: "MS", name: "MS", code: "MS" },
  { id: "MA", value: "MA", name: "MA", code: "MA" },
  { id: "MZ", value: "MZ", name: "MZ", code: "MZ" },
  { id: "MM", value: "MM", name: "MM", code: "MM" },
  { id: "NA", value: "NA", name: "NA", code: "NA" },
  { id: "NR", value: "NR", name: "NR", code: "NR" },
  { id: "NP", value: "NP", name: "NP", code: "NP" },
  { id: "NL", value: "NL", name: "NL", code: "NL" },
  { id: "NC", value: "NC", name: "NC", code: "NC" },
  { id: "NZ", value: "NZ", name: "NZ", code: "NZ" },
  { id: "NI", value: "NI", name: "NI", code: "NI" },
  { id: "NE", value: "NE", name: "NE", code: "NE" },
  { id: "NG", value: "NG", name: "NG", code: "NG" },
  { id: "NU", value: "NU", name: "NU", code: "NU" },
  { id: "NF", value: "NF", name: "NF", code: "NF" },
  { id: "MP", value: "MP", name: "MP", code: "MP" },
  { id: "NO", value: "NO", name: "NO", code: "NO" },
  { id: "OM", value: "OM", name: "OM", code: "OM" },
  { id: "PK", value: "PK", name: "PK", code: "PK" },
  { id: "PW", value: "PW", name: "PW", code: "PW" },
  { id: "PS", value: "PS", name: "PS", code: "PS" },
  { id: "PA", value: "PA", name: "PA", code: "PA" },
  { id: "PG", value: "PG", name: "PG", code: "PG" },
  { id: "PY", value: "PY", name: "PY", code: "PY" },
  { id: "PE", value: "PE", name: "PE", code: "PE" },
  { id: "PH", value: "PH", name: "PH", code: "PH" },
  { id: "PN", value: "PN", name: "PN", code: "PN" },
  { id: "PL", value: "PL", name: "PL", code: "PL" },
  { id: "PT", value: "PT", name: "PT", code: "PT" },
  { id: "PR", value: "PR", name: "PR", code: "PR" },
  { id: "QA", value: "QA", name: "QA", code: "QA" },
  { id: "MK", value: "MK", name: "MK", code: "MK" },
  { id: "RO", value: "RO", name: "RO", code: "RO" },
  { id: "RU", value: "RU", name: "RU", code: "RU" },
  { id: "RW", value: "RW", name: "RW", code: "RW" },
  { id: "RE", value: "RE", name: "RE", code: "RE" },
  { id: "BL", value: "BL", name: "BL", code: "BL" },
  { id: "SH", value: "SH", name: "SH", code: "SH" },
  { id: "KN", value: "KN", name: "KN", code: "KN" },
  { id: "LC", value: "LC", name: "LC", code: "LC" },
  { id: "MF", value: "MF", name: "MF", code: "MF" },
  { id: "PM", value: "PM", name: "PM", code: "PM" },
  { id: "VC", value: "VC", name: "VC", code: "VC" },
  { id: "WS", value: "WS", name: "WS", code: "WS" },
  { id: "SM", value: "SM", name: "SM", code: "SM" },
  { id: "ST", value: "ST", name: "ST", code: "ST" },
  { id: "SA", value: "SA", name: "SA", code: "SA" },
  { id: "SN", value: "SN", name: "SN", code: "SN" },
  { id: "RS", value: "RS", name: "RS", code: "RS" },
  { id: "SC", value: "SC", name: "SC", code: "SC" },
  { id: "SL", value: "SL", name: "SL", code: "SL" },
  { id: "SG", value: "SG", name: "SG", code: "SG" },
  { id: "SX", value: "SX", name: "SX", code: "SX" },
  { id: "SK", value: "SK", name: "SK", code: "SK" },
  { id: "SI", value: "SI", name: "SI", code: "SI" },
  { id: "SB", value: "SB", name: "SB", code: "SB" },
  { id: "SO", value: "SO", name: "SO", code: "SO" },
  { id: "ZA", value: "ZA", name: "ZA", code: "ZA" },
  { id: "GS", value: "GS", name: "GS", code: "GS" },
  { id: "SS", value: "SS", name: "SS", code: "SS" },
  { id: "ES", value: "ES", name: "ES", code: "ES" },
  { id: "LK", value: "LK", name: "LK", code: "LK" },
  { id: "SD", value: "SD", name: "SD", code: "SD" },
  { id: "SR", value: "SR", name: "SR", code: "SR" },
  { id: "SJ", value: "SJ", name: "SJ", code: "SJ" },
  { id: "SE", value: "SE", name: "SE", code: "SE" },
  { id: "CH", value: "CH", name: "CH", code: "CH" },
  { id: "SY", value: "SY", name: "SY", code: "SY" },
  { id: "TW", value: "TW", name: "TW", code: "TW" },
  { id: "TJ", value: "TJ", name: "TJ", code: "TJ" },
  { id: "TZ", value: "TZ", name: "TZ", code: "TZ" },
  { id: "TH", value: "TH", name: "TH", code: "TH" },
  { id: "TL", value: "TL", name: "TL", code: "TL" },
  { id: "TG", value: "TG", name: "TG", code: "TG" },
  { id: "TK", value: "TK", name: "TK", code: "TK" },
  { id: "TO", value: "TO", name: "TO", code: "TO" },
  { id: "TT", value: "TT", name: "TT", code: "TT" },
  { id: "TN", value: "TN", name: "TN", code: "TN" },
  { id: "TR", value: "TR", name: "TR", code: "TR" },
  { id: "TM", value: "TM", name: "TM", code: "TM" },
  { id: "TC", value: "TC", name: "TC", code: "TC" },
  { id: "TV", value: "TV", name: "TV", code: "TV" },
  { id: "UG", value: "UG", name: "UG", code: "UG" },
  { id: "UA", value: "UA", name: "UA", code: "UA" },
  { id: "AE", value: "AE", name: "AE", code: "AE" },
  { id: "GB", value: "GB", name: "GB", code: "GB" },
  { id: "UM", value: "UM", name: "UM", code: "UM" },
  { id: "UY", value: "UY", name: "UY", code: "UY" },
  { id: "UZ", value: "UZ", name: "UZ", code: "UZ" },
  { id: "VU", value: "VU", name: "VU", code: "VU" },
  { id: "VE", value: "VE", name: "VE", code: "VE" },
  { id: "VN", value: "VN", name: "VN", code: "VN" },
  { id: "VG", value: "VG", name: "VG", code: "VG" },
  { id: "VI", value: "VI", name: "VI", code: "VI" },
  { id: "WF", value: "WF", name: "WF", code: "WF" },
  { id: "EH", value: "EH", name: "EH", code: "EH" },
  { id: "YE", value: "YE", name: "YE", code: "YE" },
  { id: "ZM", value: "ZM", name: "ZM", code: "ZM" },
  { id: "ZW", value: "ZW", name: "ZW", code: "ZW" },
  { id: "AX", value: "AX", name: "AX", code: "AX" },
];
