import axios from "../clients/axios";
import Cookies from "js-cookie";
import { AxiosResponse } from "axios";
import { fetchUtils, useNotify } from "react-admin";
import jwtDecode from "jwt-decode";

interface PreSignResponse {
  signature: string;
  timestamp: string;
}
interface CloudinaryResponse {
  asset_id: string;
  public_id: string;
  version: number;
  version_id: string;
  signature: string;
  width: number;
  height: number;
  format: string;
  resource_type: string;
  created_at: Date;
  tags: any[];
  pages: number;
  bytes: number;
  type: string;
  etag: string;
  placeholder: boolean;
  url: string;
  secure_url: string;
  folder: string;
  original_filename: string;
  api_key: string;
}

export interface Info {
  asset_id: string;
  public_id: string;
  version: number;
  version_id: string;
  signature: string;
  width: number;
  height: number;
  format: string;
  resource_type: string;
  created_at: Date;
  tags: any[];
  bytes: number;
  type: string;
  etag: string;
  placeholder: boolean;
  url: string;
  secure_url: string;
  folder: string;
  original_filename: string;
}

export interface BackendUploadResponse {
  id: string;
  mime_type: string;
  byte_size: number;
  uri: string;
  created_at: Date;
  info: Info;
}

const useFileUpload = () => {
  const notify = useNotify();

  const uploadFile = async (
    file: File,
    name: string,
  ): Promise<any | undefined> => {
    try {
      let accessToken = Cookies.get("accessToken");

      if (accessToken) {
        const decodedToken: any = jwtDecode(accessToken);

        const now = Date.now() / 1000;

        if (600 > decodedToken.exp - now) {
          const refreshToken = Cookies.get("refreshToken");

          if (refreshToken) {
            const { json } = await fetchUtils.fetchJson(
              `${process.env.REACT_APP_API_URL}/users/refresh-token`,
              {
                method: "POST",
                body: JSON.stringify({ refresh: refreshToken }),
                headers: new Headers({
                  "X-Api-Key": process.env.REACT_APP_LOGE_API_KEY!,
                }),
              },
            );

            ({ accessToken } = json);

            if (accessToken) {
              Cookies.set("accessToken", accessToken);
            } else {
              accessToken = undefined;

              Cookies.remove("accessToken");
              Cookies.remove("refreshToken");
            }
          } else {
            accessToken = undefined;
          }
        }
      }

      const headers = {
        headers: {
          Authorization: "Bearer " + accessToken,
          "X-Api-Key": process.env.REACT_APP_LOGE_API_KEY,
        },
      };

      const presignResponse = await axios.post<
        null,
        AxiosResponse<PreSignResponse>
      >("/uploads/pre-sign-url", {}, headers);

      if (!presignResponse.data) throw new Error("Error presigning url");

      const CLOUDINARY_CLOUD_NAME =
        process.env.REACT_APP_CLOUDINARY_CLOUD_NAME ?? "djxgag7qb";
      const CLOUDINARY_API_KEY =
        process.env.REACT_APP_CLOUDINARY_API_KEY ?? "468348541421976";

      const formData = new FormData();
      formData.append("file", file);
      formData.append("timestamp", presignResponse.data.timestamp);
      formData.append("signature", presignResponse.data.signature);
      formData.append("api_key", CLOUDINARY_API_KEY);

      const {
        data: { api_key, pages, ...upload },
      } = await axios.post<FormData, AxiosResponse<CloudinaryResponse>>(
        "/image/upload",
        formData,
        { baseURL: `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}` },
      );

      const backendUploadResponse = await axios.post<
        Partial<CloudinaryResponse>,
        AxiosResponse<BackendUploadResponse>
      >("/uploads", { ...upload, folder: upload.folder || "uploads" }, headers);

      return backendUploadResponse.data;
    } catch (err: any) {
      notify(err.toString());
    }
  };

  return { uploadFile };
};

export default useFileUpload;
