import polyglotI18nProvider from "ra-i18n-polyglot";

import { en } from "./i18n/en";

const translations = { en };

export const i18nProvider = polyglotI18nProvider(
  (_) => translations.en,
  "en", // default locale
  { allowMissing: true },
);
