import { useEffect, useState } from "react";
import { useDataProvider, useRedirect } from "react-admin";
import { LoadingScreen } from "../../components/LoadingScreen";

const DATAPROVIDER_RESOURCE_ID = "our-family-pages";

const ListScreen = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [resources, setResources] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const { data } = await dataProvider.getMany(DATAPROVIDER_RESOURCE_ID, {
          ids: [],
        });

        redirect(`/${DATAPROVIDER_RESOURCE_ID}/${data[0].id}`);

        setResources(data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchResources();
  }, []);

  return <LoadingScreen />;
};

export default ListScreen;
