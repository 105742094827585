import { useCallback, useEffect, useMemo, useState } from "react";
import { useDataProvider } from "react-admin";
import { ResourceEditor } from "./ResourceEditor/ResourceEditor";
import { SelectCreateScreen } from "./ResourceEditor/SelectCreateScreen";

type LodgingTypesManagerProps = {
  id: string;
};

export const LodgingTypesManager = ({ id }: LodgingTypesManagerProps) => {
  const dataProvider = useDataProvider();

  const [enabledLodgingTypes, setEnabledLodgingTypes] = useState([]);

  const fetchPropertyLodgingTypes = useCallback(async () => {
    const { data } = await dataProvider.getPropertyLodgingTypes({ id: id });
    return data;
  }, [dataProvider, id]);

  const fetchEnabledLodgingTypes = useCallback(async () => {
    const { data } = await dataProvider.getEnabledLodgingTypes();
    setEnabledLodgingTypes(data);
  }, [dataProvider]);

  const updateLodgingTypes = useCallback(
    (lodgingTypes: any[]) =>
      dataProvider.updateProperyLodgingTypes(id, {
        lodgingTypes: lodgingTypes.map((a: any) => a.id),
      }),
    [dataProvider, id],
  );

  const handleMoveItems = useCallback(
    (resources: any[]) => updateLodgingTypes(resources),
    [updateLodgingTypes],
  );

  const handleRemoveItem = useCallback(
    (resources: any[], removedResource: any) =>
      updateLodgingTypes(
        resources.filter((item: any) => item.id !== removedResource.id),
      ),
    [updateLodgingTypes],
  );

  const CreateScreen = useMemo(
    () => (props: any) => (
      <SelectCreateScreen
        {...props}
        label="Lodging Types"
        onSave={(resources, selectedResource) =>
          updateLodgingTypes([...resources, selectedResource])
        }
        getSelectOptions={(resources) =>
          enabledLodgingTypes.filter(
            (type: any) => !resources.some((res) => res.id === type.id),
          )
        }
      />
    ),
    [enabledLodgingTypes, updateLodgingTypes],
  );

  useEffect(() => {
    fetchEnabledLodgingTypes();
  }, []);

  return (
    <ResourceEditor
      hasOrder
      hasBorder
      resourceId="lodging-types"
      heading="Lodging Types"
      createNewLabel="Add New Lodging Type"
      updatingOrderMsg="Updating lodging types order..."
      updatedOrderMsg="Lodging types order updated."
      fetchResourceItems={fetchPropertyLodgingTypes}
      CreateScreen={CreateScreen}
      onUpdateResourceItems={handleMoveItems}
      onRemoveResourceItem={handleRemoveItem}
    />
  );
};
