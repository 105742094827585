import { useState } from "react";
import "./RoomEditor.css";
import { Button, useDataProvider, useNotify } from "react-admin";

const RoomEditor = (props: any) => {
  const { room, onSubmit } = props;
  const { id, roomCode, roomCategoryCode, roomType, accessibilityCopy } = room;
  const [localAccessibilityCopy, setLocalAccesibilityText] =
    useState<string>(accessibilityCopy);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const charCount = localAccessibilityCopy.length;
  const maxChar = 1400;

  const onSave = async (e: any) => {
    e.preventDefault();

    if (localAccessibilityCopy === accessibilityCopy) {
      return;
    }

    setIsLoading(true);

    await onSubmit({
      id: id,
      categoryCode: roomCategoryCode,
      roomCode: roomCode,
      accessibilityText: localAccessibilityCopy,
    });

    setIsLoading(false);
  };

  const onEdit = (e: any) => {
    if (e.target.value.length <= maxChar) {
      setLocalAccesibilityText(e.target.value);
    }
  };

  return (
    <div className="category">
      <p className="catTitle">
        {roomCode} - {roomType}
      </p>

      <div className="inputDesc">
        <label>Accessibility Copy</label>
        <textarea
          onChange={onEdit}
          value={localAccessibilityCopy}
          rows={8}
        ></textarea>{" "}
        <span>
          {charCount}/{maxChar}
        </span>
      </div>
      <Button
        onClick={onSave}
        color="primary"
        variant="contained"
        label={isLoading ? "Loading..." : "Save"}
        disabled={localAccessibilityCopy === accessibilityCopy || isLoading}
      />
    </div>
  );
};

export const RoomEditorManager = (props: any) => {
  const { rooms, externalId } = props;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [isShowing, setIsShowing] = useState<boolean>(false);

  if (!rooms) {
    return (
      <div className="wrapper">
        <p>Loading...</p>
      </div>
    );
  }

  const onSubmit = async (data: any) => {
    const payload = {
      externalId: externalId,
      ...data,
    };

    try {
      const result = await dataProvider.updateRoom(payload);

      notify("Room updated with success!");
    } catch (err) {
      notify("Error updating room: " + payload.code);
    }
  };

  const filteredRooms = rooms.filter((r: any) => r.id);

  return (
    <div className="wrapper-rooms">
      <div
        className="collapse-rooms"
        onClick={() => setIsShowing((prev) => !prev)}
      >
        {isShowing
          ? `- Hide Rooms (${filteredRooms.length})`
          : `+ Show Rooms (${filteredRooms.length})`}
      </div>
      <div className="rooms-list">
        {isShowing
          ? filteredRooms.map((room: any, i: number) => {
              return <RoomEditor onSubmit={onSubmit} room={room} key={i} />;
            })
          : null}
      </div>
    </div>
  );
};
