export const startWithHTTPS = (value: string) => {
  if (value && value !== "" && !value.startsWith("https://")) {
    return "Must start with https://";
  }
  return undefined;
};

export const hexColorRequired = (value: string) => {
  if (value && !value.startsWith("#")) {
    return "Must start with #";
  }

  if (value && value.length !== 7) {
    return "Expects valid hex color. e.g: #01ABCD";
  }

  return undefined;
};

export const coordinatesPattern =
  /(^\(([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)\))$/;

export const requiredIfExtIdIsNotEmpty = (value: string, allValues: any) => {
  const { externalId } = allValues;

  // If external ID is not empty, we validate the field.
  if (externalId && externalId !== "" && externalId !== "0") {
    if (!value || value === "") {
      return "Required field.";
    }
  }

  return undefined;
};
