import React, { useState } from "react";
import { styled } from "@mui/system";
import { Box, Button, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import { LogeLogo } from "../assets";
import {
  Form,
  TextInput,
  email,
  required,
  useLogin,
  useNotify,
} from "react-admin";

const LoginContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.paper,
}));

const LoginFormContainer = styled(Box)(({ theme }) => ({
  height: "40vh",
  width: "30vw",
}));

const LoginForm = styled(Form)(() => ({
  height: "100%",
  padding: "2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const Input = styled(TextInput)(({ theme }) => ({
  backgroundColor: "#F6F6EF",
}));

const StyledSpinner = styled(CircularProgress)`
  color: #f6f6ef;
`;

const Logo = styled(LogeLogo)({
  marginBottom: "1rem",
});

const EMAIL_FORM_KEY = "email";
const PASSWORD_FORM_KEY = "password";

interface ISignInForm {
  [EMAIL_FORM_KEY]: string;
  [PASSWORD_FORM_KEY]: string;
}

const defaultValues: ISignInForm = {
  email: "",
  password: "",
};

const Login = () => {
  const login = useLogin();
  const notify = useNotify();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleSubmit = async (data: any) => {
    setLoadingLogin(true);
    await login({ ...data, adminInterface: true }).catch((err) => {
      notify(err);
    });
    setLoadingLogin(false);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleClickPasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <LoginContainer>
      <Logo />
      <LoginFormContainer>
        <LoginForm onSubmit={handleSubmit} defaultValues={defaultValues}>
          <Input
            source={EMAIL_FORM_KEY}
            label={"Email"}
            variant={"outlined"}
            placeholder={"john.doe@hotmail.com"}
            validate={[required(), email()]}
          />
          <Input
            source={PASSWORD_FORM_KEY}
            type={passwordVisible ? "text" : "password"}
            label={"Password"}
            variant={"outlined"}
            placeholder={"********"}
            validate={[required()]}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <IconButton
                    aria-label={"toggle password visibility"}
                    onClick={handleClickPasswordVisibility}
                    onMouseDown={handleMouseDownPassword}
                    edge={"end"}
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            disabled={loadingLogin}
            sx={{ mt: 2 }}
            type="submit"
            variant={"contained"}
          >
            {loadingLogin ? <StyledSpinner size={24} /> : "Log in"}
          </Button>
        </LoginForm>
      </LoginFormContainer>
    </LoginContainer>
  );
};

export default Login;
