import React from "react";
import { Admin, AdminProps, Resource, Layout } from "react-admin";
import CssBaseline from "@mui/material/CssBaseline";

import dataProvider from "./dataProvider";
import authProvider from "./authProvider";
import { i18nProvider } from "./i18nProvider";
import theme from "./theme";
import resources from "./resources";

import Login from "./pages/Login";
import { SidebarMenu } from "./components/SidebarMenu";

import "./App.css";

const adminProps: AdminProps = {
  title: "LOGE Admin",
  requireAuth: true,
  loginPage: Login,
  authProvider: authProvider,
  dataProvider,
  i18nProvider,
  theme,
};

const MyLayout = (props: any) => (
  <Layout {...props} menu={SidebarMenu} appBar={() => null} />
);

const App = () => {
  return (
    <>
      <CssBaseline />
      <Admin {...adminProps} layout={MyLayout}>
        {(permissions) =>
          permissions.includes("admin") &&
          resources.map((resource) => (
            <Resource key={resource.name} {...resource} />
          ))
        }
      </Admin>
    </>
  );
};

export default App;
