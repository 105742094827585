import { useCallback, useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

type ResourceListState = {
  state: "idle" | "loading" | "error" | "success";
  items?: any[];
};

export const useResourceList = (resourceName: string) => {
  const dataProvider = useDataProvider();
  const [hookState, setHookState] = useState<ResourceListState>({
    state: "idle",
  });

  const fetchResources = useCallback(async () => {
    try {
      setHookState((prevState) => ({ ...prevState, state: "loading" }));

      const { data } = await dataProvider.getMany(resourceName, { ids: [] });

      setHookState({ state: "success", items: data });
    } catch (err: any) {
      console.error(err);
      setHookState({ state: "error", items: undefined });
    }
  }, [resourceName, dataProvider]);

  const updateResources = useCallback(
    async (updatedItems: any[]) => {
      try {
        setHookState((prevState) => ({ ...prevState, state: "loading" }));

        await Promise.all(
          updatedItems.map((p) => dataProvider.update(resourceName, p)),
        );

        setHookState((prevState) => ({ ...prevState, state: "success" }));
      } catch (err: any) {
        console.error(err);
        setHookState({ state: "error", items: undefined });
      }
    },
    [resourceName, dataProvider],
  );

  useEffect(() => {
    fetchResources();
  }, []);

  return { ...hookState, updateResources, refetch: fetchResources };
};
