import { useEffect, useState } from "react";
import {
  Datagrid,
  TextField,
  useDataProvider,
  useList,
  ListToolbar,
  EditButton,
  CreateButton,
  Toolbar,
  BulkDeleteButton,
  useListContext,
  FunctionField,
} from "react-admin";
import { styled } from "styled-components";
import { RaListContextProviderWrapper } from "../../components/RaListContextProviderWrapper";

const StyledEditButton = styled(EditButton)`
  color: #000;
`;

const ListActions = () => (
  <Toolbar>
    <CreateButton label="New Property" />
  </Toolbar>
);

const PropertiesBulkActionButtons = (props: any) => {
  const { onAddDeletedIds } = props;
  const { selectedIds } = useListContext();

  const onConfirmDelete = () => {
    onAddDeletedIds(selectedIds);
  };

  return (
    <>
      <BulkDeleteButton mutationMode="optimistic" onClick={onConfirmDelete} />
    </>
  );
};

/**
 *
 * Should display:
    Name => name
    City => locality
    State => area
 */

const PropertiesList = () => {
  const dataProvider = useDataProvider();
  const [properties, setProperties] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [deletedIds, setDeletedIds] = useState<any[]>([]);

  const addDeletedIds = (ids: string[]) => {
    setDeletedIds([...deletedIds, ...ids]);
  };

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const { data } = await dataProvider.getMany("properties", { ids: [] });
        setProperties(data);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchProperties();
  }, []);

  const filteredProperties = properties.filter(
    (p: any) => !deletedIds.includes(p.id),
  );

  const listContext = useList({ data: filteredProperties, isLoading: loading });

  // Custom FunctionFields.
  const renderStatus = (record: any) => {
    return (
      <>
        {record.isComingSoon ? (
          <span className="badge">Coming Soon</span>
        ) : (
          <span className="badge">Live</span>
        )}
      </>
    );
  };

  const renderFeaturedOnNav = (record: any) => {
    return (
      <>
        {record.featuredOnNavigation ? (
          <span className="badge">Yes</span>
        ) : null}
      </>
    );
  };

  const renderEnabled = (record: any) => {
    return record.enabled ? (
      "Visible"
    ) : (
      <span className={`${!record.enabled && "disabled-name"}`}>Hidden</span>
    );
  };

  return (
    <RaListContextProviderWrapper value={listContext}>
      <ListActions />
      <Datagrid
        bulkActionButtons={
          <PropertiesBulkActionButtons onAddDeletedIds={addDeletedIds} />
        }
      >
        <TextField source="name" label="Name" />
        <TextField source="locality" label="City" />
        <TextField source="area" label="State" />
        <FunctionField label={"Status"} render={renderStatus} />
        <FunctionField label={"Visibility"} render={renderEnabled} />
        <FunctionField
          label={"Featured Destination"}
          render={renderFeaturedOnNav}
        />
        <FunctionField
          source="_edit"
          label={false}
          render={() => <StyledEditButton />}
        />
      </Datagrid>
    </RaListContextProviderWrapper>
  );
};

export default PropertiesList;
