import { useEffect, useState } from "react";
import {
  Datagrid,
  TextField,
  useDataProvider,
  ListContextProvider,
  useList,
  ListToolbar,
  EditButton,
  CreateButton,
  TopToolbar,
  BulkDeleteButton,
  useListContext,
  FunctionField,
  RadioButtonGroupInput,
  List,
  useNotify,
  useRedirect,
} from "react-admin";
import { LoadingScreen } from "../../components/LoadingScreen";

const DATAPROVIDER_RESOURCE_ID = "giving-back-pages";
const UPDATE_MSG = "Section order updated.";
const UPDATE_ORDER_MSG = "Updating section order...";
const PAGE_NAME = "Giving Back Page";

const ListActions = () => <TopToolbar></TopToolbar>;

const BulkActionButtons = (props: any) => {
  const { onAddDeletedIds } = props;
  const { selectedIds } = useListContext();

  const onConfirmDelete = () => {
    onAddDeletedIds(selectedIds);
  };

  const confirmMsg = `
    Are you sure you want to delete?
  `;

  return (
    <>
      <BulkDeleteButton
        mutationMode="optimistic"
        onClick={onConfirmDelete}
        confirmContent={confirmMsg}
      />
    </>
  );
};

const ListScreen = () => {
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [resources, setResources] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const { data } = await dataProvider.getMany(DATAPROVIDER_RESOURCE_ID, {
          ids: [],
        });

        redirect(`/${DATAPROVIDER_RESOURCE_ID}/${data[0].id}`);

        setResources(data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchResources();
  }, []);

  return <LoadingScreen />;
};

export default ListScreen;
