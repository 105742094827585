import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const Wrapper = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingScreen = () => (
  <Wrapper>
    <StyledProgress />
  </Wrapper>
);
