import { useEffect, useState } from "react";
import { Button, useDataProvider } from "react-admin";
import "./ReviewItem.css";

const ReviewItem = ({
  review,
  onSingleReviewChange,
  checked,
  onToggleChecked,
}: {
  review: any;
  onSingleReviewChange: any;
  checked: boolean;
  onToggleChecked: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dataProvider = useDataProvider();

  const onClick = async () => {
    setLoading(true);

    const payload = {
      publishedAt: review.publishedAt ? null : new Date().toISOString(),
    };

    const { data } = await dataProvider.updatePropertyReview(
      review.id,
      payload,
    );

    console.log(data);

    onSingleReviewChange(review.id, payload);

    setLoading(false);
  };

  const humanDate = new Date(review.date);

  return (
    <div key={review.id} className="review-item">
      <div className="review-top">
        <p className="check">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => onToggleChecked(review.id)}
          />
        </p>
        <p className="status">{review.publishedAt ? "Published" : "Hidden"}</p>
        <p className="rating">{review.rating}/5</p>
        <p className="author">{review.author}</p>
        <p className="date">{humanDate.toLocaleDateString()}</p>
      </div>
      <div className="review-bottom">
        {review.body && review.body !== "" ? (
          <p className="review-body">{review.body}</p>
        ) : null}
        <p>
          {review.publishedAt ? (
            <Button
              onClick={onClick}
              disabled={loading}
              label="Hide"
              variant="contained"
            />
          ) : (
            <Button
              onClick={onClick}
              disabled={loading}
              label="Publish"
              variant="contained"
            />
          )}
        </p>
        {loading
          ? `${
              review.publishedAt ? "Hiding review..." : "Publishing review...."
            }`
          : null}
      </div>
    </div>
  );
};

export default ReviewItem;
