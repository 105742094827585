import { useEffect, useState } from "react";
import "./JournalPropertiesManager.css";
import { Button, useDataProvider, useNotify } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

export const JournalPropertiesManager = (props: any) => {
  const { id } = props;

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [tags, setTags] = useState<any[]>([]);
  const [allTags, setAllTags] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const [selectedTag, setSelectedTags] = useState<any>({
    id: "x",
    name: "Properties",
  });

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const { data } = await dataProvider.getJournalArticleProperties({
          id: id,
        });

        setTags(data);
      } catch (err: any) {
        notify(err);
      } finally {
        setLoading(false);
      }
    };
    fetchTags();
  }, []);

  useEffect(() => {
    const fetchEnabledTags = async () => {
      const { data } = await dataProvider.getEnabledJournalProperties();

      const mappedAllTags = data.map((actv: any) => {
        return {
          id: actv.id,
          name: actv.name,
        };
      });

      setAllTags(mappedAllTags);
    };
    fetchEnabledTags();
  }, []);

  const onChangeTag = (e: any) => {
    const id = e.target.value;

    const promotion = allTags.find((a: any) => a.id === id);

    if (!promotion) {
      setSelectedTags({
        id: "x",
        name: "Properties",
      });

      return;
    }

    setSelectedTags({
      id: promotion.id,
      name: promotion.name,
    });
  };

  const onAddTag = async (e: any) => {
    if (!selectedTag || selectedTag.id === "x") {
      return;
    }

    const newTags = [...tags, selectedTag];

    setSelectedTags({
      id: "x",
      name: "Properties",
    });

    setTags(newTags);

    await updateTagOrder(newTags);
  };

  const moveMainItem = async (index: number, direction: "up" | "down") => {
    const movedTags = [...tags];

    if (direction === "up" && index > 0 && index < movedTags.length) {
      const removedItem = movedTags.splice(index, 1)[0];
      movedTags.splice(index - 1, 0, removedItem);
    }

    if (direction === "down" && index >= 0 && index < movedTags.length - 1) {
      const removedItem = movedTags.splice(index, 1)[0];
      movedTags.splice(index + 1, 0, removedItem);
    }

    setTags(movedTags);

    await updateTagOrder(movedTags);
  };

  const removeItem = async (index: number) => {
    const movedTags = [...tags];

    movedTags.splice(index, 1);

    setTags(movedTags);

    await updateTagOrder(movedTags);
  };

  const updateTagOrder = async (movedTags: any[]) => {
    notify("Updating properties order...");

    const newOrderIds = movedTags.map((a: any) => a.id);

    const { data } = await dataProvider.updateJournalArticleProperties(id, {
      properties: newOrderIds,
    });

    notify("Properties order updated.");
  };

  const alreadyInUseTags = tags.map((a: any) => a.id);

  const selectableTags = allTags.filter(
    (allPromotion: any) => !alreadyInUseTags.includes(allPromotion.id),
  );

  return (
    <section>
      <div>
        {loading ? (
          <div>Loading properties...</div>
        ) : (
          tags.map((act: any, index: number) => {
            const isFirst = index === 0;
            const isLast = index === tags.length - 1;

            return (
              <div key={act.id}>
                <a className="arrow-btn" onClick={(e) => removeItem(index)}>
                  &#215;
                </a>
                {!isFirst ? (
                  <a
                    className="arrow-btn"
                    onClick={(e) => moveMainItem(index, "up")}
                  >
                    &uarr;
                  </a>
                ) : (
                  <a className="arrow-dummy">&darr;</a>
                )}
                {!isLast ? (
                  <a
                    className="arrow-btn"
                    onClick={(e) => moveMainItem(index, "down")}
                  >
                    &darr;
                  </a>
                ) : (
                  <a className="arrow-dummy">&darr;</a>
                )}
                <span className="actv-name">{act.name}</span>
              </div>
            );
          })
        )}
        {selectableTags.length === 0 ? null : (
          <>
            <div className="add-actv">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <Select onChange={onChangeTag} value={selectedTag.id}>
                  <MenuItem value={"x"}>Properties</MenuItem>
                  {selectableTags.map((act: any) => {
                    return (
                      <MenuItem value={act.id} key={act.id}>
                        {act.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="medium">
                <Button
                  onClick={onAddTag}
                  color="primary"
                  variant="contained"
                  label="Add Property"
                />
              </FormControl>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
