import {
  useList,
  EditButton,
  CreateButton,
  Toolbar,
  BulkDeleteButton,
  FunctionField,
  useNotify,
} from "react-admin";
import { RaListContextProviderWrapper } from "../../components/RaListContextProviderWrapper";

import {
  MovingItemPayload,
  OrderableDatagrid,
} from "../../components/OrderableDatagrid";
import { useResourceList } from "../../hooks/useResourceList";

const CREATE_NEW_LABEL = "New Partner";
const DATAPROVIDER_RESOURCE_ID = "partners";
const UPDATE_MSG = "Partner order updated.";
const UPDATE_ORDER_MSG = "Updating partner order...";

const ListActions = () => (
  <Toolbar>
    <CreateButton label={CREATE_NEW_LABEL} />
  </Toolbar>
);

const BulkActionButtons = ({ onDelete }: any) => {
  const confirmMsg = `
    Are you sure you want to delete?
  `;

  return (
    <>
      <BulkDeleteButton
        mutationMode="optimistic"
        mutationOptions={{ onSettled: () => onDelete() }}
        confirmContent={confirmMsg}
      />
    </>
  );
};

const ListScreen = () => {
  const { state, items, updateResources, refetch } = useResourceList(
    DATAPROVIDER_RESOURCE_ID,
  );
  const notify = useNotify();

  const updateResourceOrder = async (itemsPayload: MovingItemPayload[]) => {
    notify(UPDATE_ORDER_MSG);

    await updateResources(itemsPayload);

    notify(UPDATE_MSG);

    refetch();
  };

  const mappedResources = items?.map((item, itemIndex: number) => {
    return {
      ...item,
      index: itemIndex,
    };
  });

  const listContext = useList({
    data: mappedResources,
    isLoading: !Array.isArray(items) && state === "loading",
  });

  // Custom FunctionFields.
  const renderName = (record: any) => {
    return (
      <span className={`record-name ${!record.publishedAt && "disabled-name"}`}>
        {record.name}
      </span>
    );
  };

  const renderEnabled = (record: any) => {
    return record.publishedAt ? (
      "Enabled"
    ) : (
      <span className={`${!record.publishedAt && "disabled-name"}`}>
        Disabled
      </span>
    );
  };

  return (
    <RaListContextProviderWrapper value={listContext}>
      <ListActions />
      <OrderableDatagrid
        items={items ?? []}
        isLoading={state === "loading"}
        onUpdateOrder={updateResourceOrder}
        bulkActionButtons={<BulkActionButtons onDelete={refetch} />}
      >
        <FunctionField label="Name" render={renderName} />
        <FunctionField label="Enabled?" render={renderEnabled} />
        <EditButton className="editToHover" label="Edit" />
      </OrderableDatagrid>
    </RaListContextProviderWrapper>
  );
};

export default ListScreen;
