import { useCallback, useEffect, useMemo, useState } from "react";
import { useDataProvider } from "react-admin";
import { ResourceEditor } from "./ResourceEditor/ResourceEditor";
import { SelectCreateScreen } from "./ResourceEditor/SelectCreateScreen";

type GroupEventsManagerProps = {
  id: string;
};

export const GroupEventsManager = ({ id }: GroupEventsManagerProps) => {
  const dataProvider = useDataProvider();
  const [enabledResources, setEnabledResources] = useState([]);

  const fetchPropertyResources = useCallback(async () => {
    const { data } = await dataProvider.getPropertyGroupEvents({ id: id });
    return data;
  }, [dataProvider, id]);

  const fetchEnabledResources = useCallback(async () => {
    const { data } = await dataProvider.getEnabledGroupEvents();
    setEnabledResources(data);
  }, [dataProvider]);

  const updateResources = useCallback(
    async (resources: any[]) =>
      dataProvider.updatePropertyGroupEvents(id, {
        groupEvents: resources.map((a: any) => a.id),
      }),
    [dataProvider, id],
  );

  const handleMoveItems = useCallback(
    (resources: any[]) => updateResources(resources),
    [updateResources],
  );

  const handleRemoveItem = useCallback(
    (resources: any[], removedResource: any) =>
      updateResources(
        resources.filter((item: any) => item.id !== removedResource.id),
      ),
    [updateResources],
  );

  const CreateScreen = useMemo(
    () => (props: any) => (
      <SelectCreateScreen
        {...props}
        label="Group Event"
        onSave={(resources, selectedResource) =>
          updateResources([...resources, selectedResource])
        }
        getSelectOptions={(resources) =>
          enabledResources.filter(
            (type: any) => !resources.some((res) => res.id === type.id),
          )
        }
      />
    ),
    [enabledResources, updateResources],
  );

  useEffect(() => {
    fetchEnabledResources();
  }, []);

  return (
    <ResourceEditor
      hasOrder
      hasBorder
      resourceId="group-events"
      heading="Group Events"
      createNewLabel="Add New Group Event"
      updatingOrderMsg="Updating group events order..."
      updatedOrderMsg="Group events order updated."
      fetchResourceItems={fetchPropertyResources}
      CreateScreen={CreateScreen}
      onUpdateResourceItems={handleMoveItems}
      onRemoveResourceItem={handleRemoveItem}
    />
  );
};
