import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
  maxLength,
  TabbedForm,
  FormTab,
} from "react-admin";
import { enabledFlags, featuredFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";
import { hexColorRequired } from "../../utils/validators";
import { RichEditorField } from "../../components/RichEditorField/RichEditorField";
import { RaCreateWrapper } from "../../components/RaCreateWrapper";

interface ICreate {
  [key: string]: any;
}

const CreateToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: ICreate) => {
          const date = new Date();

          const defaultValue = {
            time: Date.now(),
            blocks: [
              {
                id: "QSOZ9NHw3R",
                type: "paragraph",
                data: {
                  text: "Your article content starts here...",
                },
              },
            ],
          };

          const mappedRequest: any = {
            ...data,
            publishedAt: null,
            content: defaultValue,
          };

          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
            ...files,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const CreateScreen = () => {
  const notify = useNotify();

  // Editor.
  const [editorContent, setEditorContent] = React.useState<any>(null);

  // Resource Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    imageUploadId: null | any;
  }>({
    imageUploadId: null,
  });

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: "imageUploadId", files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title } = useRecordContext();

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.children;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  const onFileRemoval = (target: string, _file: any) => {
    setResourceFiles({ ...resourceFiles, [target]: null });
    return true; // is handled from frontend
  };

  return (
    <RaCreateWrapper title="New Article">
      <TabbedForm toolbar={<CreateToolbar files={resourceFiles} />}>
        <FormTab label="Article">
          <div className="form-text-input-row">
            <TextInputCounter
              formClassName="form-text-input-field"
              source="title"
              validate={[required(), maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              formClassName="form-text-input-field"
              source="slug"
              validate={[required(), maxLength(100)]}
              limit={100}
            />
            <SelectInput
              choices={featuredFlags}
              source="featured"
              label="Featured?"
              defaultValue="false"
              validate={[required()]}
            />
          </div>
        </FormTab>

        <FormTab label="Image">
          <div className="resource-icons-grid">
            <ImageInput
              source="imageUploadId"
              label="Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "imageUploadId"),
              }}
              validateFileRemoval={(file: any) =>
                onFileRemoval("imageUploadId", file)
              }
            >
              <FileFieldWithContext>
                <ImageField source="src" title="title" />
              </FileFieldWithContext>
            </ImageInput>
          </div>
        </FormTab>
      </TabbedForm>
    </RaCreateWrapper>
  );
};
