import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useGetOne,
  useEditController,
  maxLength,
  TabbedForm,
  FormTab,
  DateInput,
} from "react-admin";
import { featuredFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";
import { JournalTagsManager } from "../../components/JournalTagsManager";
import { JournalPropertiesManager } from "../../components/JournalPropertiesManager";
import { RichEditorField } from "../../components/RichEditorField/RichEditorField";
import { RaEditWrapper } from "../../components/RaEditWrapper";

const DATAPROVIDER_RESOURCE_ID = "journal-articles";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ files, record, editorContent, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          let pubAt = null;

          if (data.publishedAt != "") {
            const date = new Date(data.publishedAt);

            pubAt = date.toISOString();
          }

          const mappedRequest: any = {
            ...data,
            publishedAt: pubAt,
            content: editorContent ? editorContent : record.content,
          };

          const resourceFilesReq = {
            imageUploadId: files.imageUpload ?? data.imageUpload.id,
          };

          // Clean up fields.
          delete mappedRequest.imageUpload;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;
          delete mappedRequest.journalTags;
          delete mappedRequest.properties;

          return {
            ...mappedRequest,
            ...resourceFilesReq,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const { id } = useParams();
  const { data } = useGetOne(`/${DATAPROVIDER_RESOURCE_ID}`, { id });
  const notify = useNotify();

  const resourceName = data
    ? `${data.title.substr(0, 60)}${data.title.length > 60 ? "..." : ""}`
    : "";

  // Editor.
  const [editorContent, setEditorContent] = React.useState<any>(null);

  // Upload Files
  const [resourceFiles, setResourceFiles] = React.useState<{
    imageUpload: null | any;
  }>({
    imageUpload: null,
  });

  const [resourceImgs, setResourceImgs] = React.useState<{
    [key: string]: any;
  }>({});

  const renderImgPreview = (name: string) => {
    if (!resourceImgs[name]) {
      return <ImageField source="uri" title="info.original_filename" />;
    }

    return (
      <FileFieldWithContext
        placeholder={
          <div className="MuiBox-root css-cb34tx-RaImageField-root">
            <img
              src={resourceImgs[name].uri}
              title={resourceImgs[name].title}
              width={200}
              height={200}
              className="RaImageField-image"
            />
          </div>
        }
      >
        <ImageField source="uri" title="title" />
      </FileFieldWithContext>
    );
  };

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (name: "imageUpload", files: File[]) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({ ...uploadedFileNames, [fileName]: "Uploading..." });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      setResourceImgs({
        ...resourceImgs,
        [name]: {
          title: asset.info.original_filename,
          uri: asset.uri,
          uploading: false,
        },
      });

      const newValue = asset.id;
      setResourceFiles({ ...resourceFiles, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNames, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNames,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title, uri } = useRecordContext();

    if (uri) {
      return props.children;
    }

    if (!Object.hasOwn(uploadedFileNames, title)) {
      return null;
    }

    if (uploadedFileNames[title] === true) {
      return props.placeholder;
    } else {
      return <p>{uploadedFileNames[title]}</p>;
    }
  };

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
    record.publishedAt = record.publishedAt
      ? record.publishedAt.replace("00:00:00", "12:00:00")
      : null;
  }

  return (
    <RaEditWrapper mutationMode="pessimistic" title={resourceName}>
      <TabbedForm
        toolbar={
          <EditToolbar
            files={resourceFiles}
            record={record}
            editorContent={editorContent}
          />
        }
      >
        <FormTab label="Article">
          <div className="form-text-input-row">
            <TextInputCounter
              formClassName="form-text-input-field"
              source="title"
              validate={[required(), maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              formClassName="form-text-input-field"
              source="slug"
              validate={[required(), maxLength(100)]}
              limit={100}
            />
            <SelectInput
              choices={featuredFlags}
              source="featured"
              label="Featured?"
              defaultValue="false"
              validate={[required()]}
            />
            <DateInput source="publishedAt" />
          </div>

          <RichEditorField
            source="content"
            onContentChange={(d: any) => setEditorContent(d)}
          />
        </FormTab>

        <FormTab label="Image">
          <div className="resource-icons-grid">
            <ImageInput
              source="imageUpload"
              label="Image (.png, .jpg, .jpeg)"
              validate={[required()]}
              accept={"image/png,image/jpg,image/jpeg"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "imageUpload"),
              }}
            >
              {renderImgPreview("imageUpload")}
            </ImageInput>
          </div>
        </FormTab>
        <FormTab label="Tags">
          <JournalTagsManager id={id} />
        </FormTab>
        <FormTab label="Properties">
          <JournalPropertiesManager id={id} />
        </FormTab>
      </TabbedForm>
    </RaEditWrapper>
  );
};
