import * as React from "react";
import "../../assets/crudStyles.css";
import "./styles.css";
import { TextInputCounter } from "../TextInputCounter/TextInputCounter";
import { useParams } from "react-router-dom";
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  ImageInput,
  Toolbar,
  SaveButton,
  useNotify,
  ImageField,
  useRecordContext,
  useDataProvider,
  Edit,
  useGetOne,
  useEditController,
  maxLength,
} from "react-admin";
import { enabledFlags } from "../../constants/utils";
import useFileUpload from "../../hooks/useFileUpload";
import { hexColorRequired } from "../../utils/validators";
import { GroupSectionGalleryEditor } from "../GroupSectionGalleryEditor/GroupSectionGalleryEditor";
import { CreateScreen as UploadCreate } from "../group-event-page-section-uploads/create";
import { EditScreen as UploadEdit } from "../group-event-page-section-uploads/edit";

const DATAPROVIDER_RESOURCE_ID = "group-event-page-sections";
const RESOURCE_DISPLAY_NAME = "Sections";

interface IEdit {
  [key: string]: any;
}

const EditToolbar = ({ ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ ...data }: IEdit) => {
          const date = new Date();

          const mappedRequest: any = {
            ...data,
            publishedAt:
              data.enabled == true || data.enabled == "true"
                ? date.toISOString()
                : null,
          };

          // Clean up fields.
          delete mappedRequest.imageUploads;
          delete mappedRequest.order;
          delete mappedRequest.id;
          delete mappedRequest.enabled;

          return {
            ...mappedRequest,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const EditScreen = (props: any) => {
  const { id, onCreated, hasEnabled, resource } = props;
  const notify = useNotify();

  // Record handler.
  const { record } = useEditController(props);

  if (record) {
    record.enabled = record.publishedAt !== null ? true : false;
  }

  return (
    <Edit
      mutationMode="pessimistic"
      resource={resource}
      id={id}
      mutationOptions={{ onSuccess: onCreated }}
      redirect={false}
    >
      <SimpleForm
        toolbar={<EditToolbar record={record} hasEnabled={hasEnabled} />}
        id={id}
        resource={resource}
      >
        <div className="form-text-input-row">
          <TextInputCounter
            formClassName="form-text-input-field"
            source="header"
            validate={[required(), maxLength(100)]}
            limit={100}
          />
          <SelectInput
            choices={enabledFlags}
            source="enabled"
            label="Enabled?"
            defaultValue="false"
            validate={[required()]}
          />
        </div>

        <TextInputCounter
          source="description"
          multiline
          fullWidth
          resettable
          validate={[required(), maxLength(500)]}
          limit={500}
        />

        <h4 className="heading-file-uploads">Gallery</h4>

        <GroupSectionGalleryEditor
          resource={"group-event-page-section-uploads"}
          groupEventPageSectionId={id}
          createNewLabel="Add New Item"
          updatedOrderMsg="Item order updated."
          updatingOrderMsg="Updating item order..."
          heading="Section Gallery"
          CreateScreen={UploadCreate}
          EditScreen={UploadEdit}
          hasOrder={true}
          hasEnabled={true}
          limit={null}
        />
      </SimpleForm>
    </Edit>
  );
};
