import { useEffect, useState } from "react";
import {
  Datagrid,
  useDataProvider,
  useList,
  EditButton,
  CreateButton,
  Toolbar,
  BulkDeleteButton,
  useListContext,
  FunctionField,
  useNotify,
} from "react-admin";
import { RaListContextProviderWrapper } from "../../components/RaListContextProviderWrapper";

const CREATE_NEW_LABEL = "New Tag";
const DATAPROVIDER_RESOURCE_ID = "journal-tags";
const UPDATE_MSG = "Tag order updated.";
const UPDATE_ORDER_MSG = "Updating tag order...";

const ListActions = () => (
  <Toolbar>
    <CreateButton label={CREATE_NEW_LABEL} />
  </Toolbar>
);

const BulkActionButtons = (props: any) => {
  const { onAddDeletedIds } = props;
  const { selectedIds } = useListContext();

  const onConfirmDelete = () => {
    onAddDeletedIds(selectedIds);
  };

  const confirmMsg = `
    Are you sure you want to delete?
  `;

  return (
    <>
      <BulkDeleteButton
        mutationMode="optimistic"
        onClick={onConfirmDelete}
        confirmContent={confirmMsg}
      />
    </>
  );
};

const ListScreen = () => {
  const dataProvider = useDataProvider();
  const [resources, setResources] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [deletedIds, setDeletedIds] = useState<any[]>([]);
  const notify = useNotify();

  const addDeletedIds = (ids: string[]) => {
    setDeletedIds([...deletedIds, ...ids]);
  };

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const { data } = await dataProvider.getMany(DATAPROVIDER_RESOURCE_ID, {
          ids: [],
        });

        setResources(data);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchResources();
  }, []);

  // Filter and flat.
  const filteredResources = resources.filter(
    (p: any) => !deletedIds.includes(p.id),
  );

  const mappedResources = filteredResources.map((item, itemIndex: number) => {
    return {
      ...item,
      index: itemIndex,
    };
  });

  const listContext = useList({ data: mappedResources, isLoading: loading });

  // Custom FunctionFields.
  const renderName = (record: any) => {
    return (
      <>
        <span className={`record-name`}>{record.name}</span>
      </>
    );
  };

  return (
    <RaListContextProviderWrapper value={listContext}>
      <ListActions />
      <Datagrid
        bulkActionButtons={
          <BulkActionButtons onAddDeletedIds={addDeletedIds} />
        }
      >
        <FunctionField label="Name" render={renderName} />
        <EditButton className="editToHover" label="Edit" />
      </Datagrid>
    </RaListContextProviderWrapper>
  );
};

export default ListScreen;
