import * as React from "react";
import "./styles.css";
import { TextInputCounter } from "../../components/TextInputCounter/TextInputCounter";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ArrayField,
  SimpleFormIterator,
  TimeInput,
  NumberInput,
  SelectInput,
  ArrayInput,
  ImageInput,
  Toolbar,
  SaveButton,
  FileInput,
  FileField,
  useNotify,
  IconButtonWithTooltip,
  regex,
  maxLength,
  ImageField,
  useRecordContext,
  TabbedForm,
  FormTab,
  useDataProvider,
} from "react-admin";
import { countryListAllIsoData } from "../../constants/country_codes";
import { PropertyHour } from "../../types/Property";
import useFileUpload from "../../hooks/useFileUpload";
import {
  timeFormatter,
  timeParser,
  getAllTimezones,
} from "../../utils/time-utils";
import { week_days } from "../../utils/weekdays";
import { ColorPicker } from "../../components/ColorPicker";
import {
  startWithHTTPS,
  hexColorRequired,
  coordinatesPattern,
  requiredIfExtIdIsNotEmpty,
} from "../../utils/validators";
import { featuredFlags } from "../../constants/utils";
import { RaCreateWrapper } from "../../components/RaCreateWrapper";

// Property ID (not editable)
// Locality
// Area
// Property name
// Description
// External ID (this is how this property is identified on Sabre)
// Address (including City and State)
// Country code
// postal code
// email
// Phone number
// check-in time
// check-out time
// timezone (present as dropdown with US time zone names)
// Coordinates
// House-rules

// home Screen Order (this is used on the app's home screen to define the order the properties are listed)
// Color set > This should be a dropdown that will automatically assign the primary and secondary hex color values. The idea is not to allow the admin to freely select any colors, but pick between a number of preselected pairs.  For now we have only 2, but the plan is to have 6 to 8, so when we have these, we'll have a specific task to add them.
// For now the available options/values will be:
// - present name (on the dropdown):Westport
//    Primary: #4B858E
//    Secondary: #4A5B5E

// - preset name: Bend
//    Primary: #75C2BA
//    Secondary: #19988B

// wifi_ssid
// wifi_password
// experiences url
// gear rental url

// These two are a relation of multiple records per property:
// Office hours (per weekday, check table schema link below)
// Property FAQs (question/answer pairs)

// Also allow to upload one file related to each of the following:
// uploadIdMenu (this is a static image or PDF of the cafe menu)
// uploadIdMap (this is a static image of the property map)
// uploadIdWordmark (this image is one of the property's badges used on upcoming bookings)
// uploadIdComposition (this image is one of the property's badges used on the home screen)
// uploadIdBadge (this image is one of the property's badges used on property details screen)

// Finally, allow for multiple photos uploads per property (propertyPhotos), allowing the user to set their order.

interface IPropertyCreate {
  [key: string]: any;
  property_hours: PropertyHour[];
}

const PropertyCreateToolbar = ({ files, ...props }: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        transform={({ color_set, ...data }: IPropertyCreate) => {
          return {
            ...data,
            ...files,
            enabled: false,
          };
        }}
        type="button"
      />
    </Toolbar>
  );
};

export const PropertiesCreate = () => {
  // Grab Children Resource List.
  const dataProvider = useDataProvider();
  const [regions, setRegions] = React.useState<any>([]);
  const [partners, setPartners] = React.useState<any>([]);

  React.useEffect(() => {
    dataProvider
      .getMany("regions", { ids: [] })
      .then((response) => {
        if (response && response.data) {
          setRegions(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching regions:", error);
      });

    dataProvider
      .getMany("partners", { ids: [] })
      .then((response) => {
        if (response && response.data) {
          setPartners(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching partners:", error);
      });
  }, [dataProvider]);

  // Resource Files.
  const notify = useNotify();
  const [propertyFiles, setPropertyFiles] = React.useState<{
    uploadIdMenu: null | any;
    uploadIdMap: null | any;
    uploadIdWordmark: null | any;
    uploadIdComposition: null | any;
    uploadIdBadge: null | any;
    propertyPhotos: [] | any[];
  }>({
    uploadIdMenu: null,
    uploadIdMap: null,
    uploadIdWordmark: null,
    uploadIdComposition: null,
    uploadIdBadge: null,
    propertyPhotos: [],
  });
  const propertyFilesRef = React.useRef<any>(propertyFiles);
  propertyFilesRef.current = propertyFiles;

  // Used to track the uploaded files and show in progress or error.
  const [uploadedFileNames, setUploadFileNames] = React.useState<{
    [key: string]: string | boolean;
  }>({});
  const uploadedFileNamesRef = React.useRef<any>();
  uploadedFileNamesRef.current = uploadedFileNames;

  const { uploadFile } = useFileUpload();
  const handlePrefileUpload = async (
    name:
      | "uploadIdMenu"
      | "uploadIdMap"
      | "uploadIdWordmark"
      | "uploadIdComposition"
      | "propertyPhotos"
      | "uploadIdBadge",
    files: File[],
  ) => {
    const fileName = files[0].name;
    notify(`Uploading file... ${fileName}`);

    setUploadFileNames({
      ...uploadedFileNamesRef.current,
      [fileName]: "Uploading...",
    });

    const asset = await uploadFile(files[0], name);
    if (asset !== undefined) {
      const newValue =
        name === "propertyPhotos"
          ? [
              ...propertyFilesRef.current.propertyPhotos,
              {
                uploadId: asset.id,
                order: propertyFilesRef.current.propertyPhotos.length + 1,
              },
            ]
          : asset.id;
      setPropertyFiles({ ...propertyFilesRef.current, [name]: newValue });
      notify(`Upload completed! File: ${fileName}`);
      setUploadFileNames({ ...uploadedFileNamesRef.current, [fileName]: true });
    } else {
      notify(`Error uploading file! ${fileName}`);
      setUploadFileNames({
        ...uploadedFileNamesRef.current,
        [fileName]: "Error uploading file, remove and try again.",
      });
    }
  };

  const FileFieldWithContext = (props: any) => {
    const { title } = useRecordContext();

    if (!Object.hasOwn(uploadedFileNamesRef.current, title)) {
      return null;
    }

    if (uploadedFileNamesRef.current[title] === true) {
      return props.children;
    } else {
      return <p>{uploadedFileNamesRef.current[title]}</p>;
    }
  };

  const onFileRemoval = (target: string, _file: any) => {
    setPropertyFiles({ ...propertyFilesRef.current, [target]: null });
    return true; // is handled from frontend
  };
  return (
    <RaCreateWrapper title="New Property">
      <TabbedForm toolbar={<PropertyCreateToolbar files={propertyFiles} />}>
        <FormTab label="Details">
          <div className="form-text-input-row">
            <TextInputCounter
              source="name"
              validate={[required(), maxLength(50)]}
              limit={50}
            />
            <TextInputCounter
              source="slug"
              label="Slug"
              validate={[required()]}
            />
            <TextInputCounter
              source="akiaId"
              label="Akia ID"
              validate={[required(), maxLength(50)]}
              limit={50}
            />
            <TextInputCounter
              source="locality"
              label="City"
              validate={[required(), maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              source="area"
              label="State"
              validate={[
                required(),
                maxLength(2, "Expected 2 chars. E.g.: NY"),
              ]}
              limit={2}
            />
          </div>
          <TextInputCounter
            source="description"
            multiline
            fullWidth
            resettable
            validate={[required()]}
          />
          <TextInputCounter
            source="pageTitle"
            multiline
            fullWidth
            resettable
            validate={[maxLength(1000)]}
            limit={1000}
          />
          <div className="form-text-input-row">
            <TextInputCounter
              source="externalId"
              label="External ID"
              placeholder="External ID"
              parse={(v: any) => (v === "" ? null : v)}
              validate={maxLength(50)}
              limit={50}
            />
            <SelectInput
              choices={countryListAllIsoData}
              source="countryCode"
              validate={[required()]}
            />
            <TextInputCounter
              source="postalAddress"
              validate={[required(), maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              source="postalCode"
              validate={[required(), maxLength(10)]}
              limit={10}
            />
          </div>
          <div className="form-text-input-row">
            <TextInputCounter
              source="email"
              validate={[requiredIfExtIdIsNotEmpty, maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              source="phoneNumber"
              validate={[maxLength(250)]}
              limit={250}
            />
            <TimeInput
              source="checkinTime"
              validate={[required()]}
              format={timeFormatter}
              parse={timeParser}
            />
            <TimeInput
              source="checkoutTime"
              validate={[required()]}
              format={timeFormatter}
              parse={timeParser}
            />
          </div>
          <div className="form-text-input-row">
            <SelectInput
              choices={getAllTimezones()}
              source="timezoneName"
              validate={[required()]}
            />
            <TextInputCounter
              source="coordinates"
              validate={[
                required(),
                regex(coordinatesPattern, "Expected format is (2.123,1.234)"),
              ]}
            />
            <NumberInput source="homeScreenOrder" validate={[required()]} />
          </div>
          <div className="form-text-input-row">
            <ColorPicker
              source="primaryColor"
              label="Primary Color (e.g.: #00ABFA)"
              validate={[required(), hexColorRequired]}
              className="form-picker-full-width"
            />
            <ColorPicker
              source="secondaryColor"
              label="Secondary Color (e.g.: #00ABFA)"
              validate={[required(), hexColorRequired]}
              className="form-picker-full-width"
            />
          </div>
          <TextInputCounter
            source="houseRules"
            multiline
            fullWidth
            validate={[requiredIfExtIdIsNotEmpty]}
          />
          <TextInputCounter
            source="accessibilityCopy"
            label="Accessibility"
            multiline
            fullWidth
            validate={[]}
          />
          <div className="form-text-input-row">
            <TextInputCounter
              source="wifiSsid"
              validate={[requiredIfExtIdIsNotEmpty, maxLength(30)]}
              limit={30}
            />
            <TextInputCounter
              source="wifiPassword"
              validate={[requiredIfExtIdIsNotEmpty, maxLength(250)]}
              limit={250}
            />
            <TextInputCounter
              source="comingSoonLabel"
              validate={[maxLength(15)]}
              limit={15}
            />
          </div>
        </FormTab>
        <FormTab label="Hours">
          <ArrayInput source="propertyHours" label="Office hours">
            <SimpleFormIterator inline>
              <SelectInput
                source="dayOfWeek"
                helperText={false}
                choices={week_days}
                validate={[required()]}
              />
              <TimeInput
                source="openingTime"
                helperText={false}
                validate={[required()]}
                format={timeFormatter}
                parse={timeParser}
              />
              <TimeInput
                source="closingTime"
                helperText={false}
                validate={[required()]}
                format={timeFormatter}
                parse={timeParser}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="FAQs">
          <ArrayInput source="faqs" label="FAQs">
            <SimpleFormIterator inline>
              <TextInputCounter
                source="question"
                helperText={false}
                validate={[required()]}
              />
              <TextInputCounter
                source="answer"
                helperText={false}
                validate={[required()]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Relations">
          <div className="form-text-input-row">
            <SelectInput
              choices={featuredFlags}
              source="featuredOnNavigation"
              label="Featured Destination?"
              defaultValue="false"
              validate={[required()]}
            />
            <SelectInput
              source="regionId"
              label="Region"
              choices={regions.map((type: any) => ({
                id: type.id,
                name: type.name,
              }))}
              validate={[]}
            />
            <SelectInput
              source="partnerId"
              label="Partner"
              choices={partners.map((type: any) => ({
                id: type.id,
                name: type.name,
              }))}
              validate={[]}
            />
          </div>
        </FormTab>

        <FormTab label="Badges">
          <h4 className="heading-file-uploads">Property Badges</h4>

          <div className="property-badges-grid">
            <ImageInput
              source="uploadIdWordmark"
              label="Property Badge - Name (.png)"
              validate={[required()]}
              accept={"image/png"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "uploadIdWordmark",
                ),
              }}
              validateFileRemoval={(file: any) =>
                onFileRemoval("uploadIdWordmark", file)
              }
            >
              <FileFieldWithContext>
                <ImageField source="src" title="title" />
              </FileFieldWithContext>
            </ImageInput>
            <ImageInput
              source="uploadIdComposition"
              label="Property Badge - Home Card Configuration (.png)"
              validate={[required()]}
              accept={"image/png"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(
                  null,
                  "uploadIdComposition",
                ),
              }}
              validateFileRemoval={(file: any) =>
                onFileRemoval("uploadIdComposition", file)
              }
            >
              <FileFieldWithContext>
                <ImageField source="src" title="title" />
              </FileFieldWithContext>
            </ImageInput>
            <ImageInput
              source="uploadIdBadge"
              label="Property Badge (.png)"
              validate={[required()]}
              accept={"image/png"}
              options={{
                onDropAccepted: handlePrefileUpload.bind(null, "uploadIdBadge"),
              }}
              validateFileRemoval={(file: any) =>
                onFileRemoval("uploadIdBadge", file)
              }
            >
              <FileFieldWithContext>
                <ImageField source="src" title="title" />
              </FileFieldWithContext>
            </ImageInput>
          </div>
        </FormTab>

        <FormTab label="Menu/Map">
          <h4 className="heading-file-uploads">Menu</h4>

          <FileInput
            source="uploadIdMenu"
            label="Menu PDF (.pdf)"
            validate={[required()]}
            accept={"application/pdf"}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "uploadIdMenu"),
            }}
            validateFileRemoval={(file: any) =>
              onFileRemoval("uploadIdMenu", file)
            }
          >
            <FileFieldWithContext>
              <FileField source="src" title="title" />
            </FileFieldWithContext>
          </FileInput>

          <h4 className="heading-file-uploads">Property Map</h4>

          <FileInput
            source="uploadIdMap"
            label="Property Map Image (.pdf)"
            validate={[required()]}
            options={{
              onDropAccepted: handlePrefileUpload.bind(null, "uploadIdMap"),
            }}
            accept={"application/pdf"}
            validateFileRemoval={(file: any) =>
              onFileRemoval("uploadIdMap", file)
            }
          >
            <FileFieldWithContext>
              <FileField source="src" title="title" />
            </FileFieldWithContext>
          </FileInput>
        </FormTab>

        <FormTab label="Mobile">
          <h4 className="heading-file-uploads">Property Photos</h4>

          <ArrayInput source="propertyPhotos">
            <SimpleFormIterator inline disableReordering disableRemove>
              <ImageInput
                source="upload_id"
                helperText={false}
                validate={[required()]}
                accept={"image/png,image/jpeg,image/jpg"}
                options={{
                  onDropAccepted: handlePrefileUpload.bind(
                    null,
                    "propertyPhotos",
                  ),
                }}
              >
                <FileFieldWithContext>
                  <ImageField source="src" title="title" />
                </FileFieldWithContext>
              </ImageInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Group Events">
          <TextInputCounter
            source="groupEventsShortDescription"
            multiline
            fullWidth
            resettable
            validate={[]}
          />
          <TextInputCounter
            source="groupEventsLongDescription"
            multiline
            fullWidth
            resettable
            validate={[]}
          />
        </FormTab>

        <FormTab label="Connections">
          <TextInputCounter
            source="experienceUrl"
            label="Experience URL"
            validate={[startWithHTTPS]}
            fullWidth
          />
          <TextInputCounter
            source="gearUrl"
            label="Gear URL"
            validate={[requiredIfExtIdIsNotEmpty, startWithHTTPS]}
            fullWidth
          />
          <TextInputCounter
            source="jobsUrl"
            label="Jobs URL"
            validate={[startWithHTTPS]}
            fullWidth
          />
          <TextInputCounter
            source="groupContactFormId"
            validate={[]}
            label="Group Contact Form ID"
            fullWidth
          />
          <TextInputCounter
            source="wayCoId"
            validate={[]}
            label="WayCo ID"
            fullWidth
          />
          <TextInputCounter
            source="wayCoParameters"
            validate={[]}
            label="WayCo Parameters"
            fullWidth
          />
          <TextInputCounter
            source="checkfrontId"
            validate={[]}
            label="CheckFront ID"
            fullWidth
          />
          <TextInputCounter
            label="Experiences Calendar URL"
            source="experiencesCalendarUrl"
            validate={[startWithHTTPS]}
            fullWidth
          />
          <TextInputCounter
            label="Strava URL"
            source="stravaUrl"
            validate={[startWithHTTPS, maxLength(500)]}
            fullWidth
            limit={500}
          />
          <TextInputCounter
            label="Revinate API Key"
            source="revinateKey"
            validate={[maxLength(32)]}
            fullWidth
            limit={32}
          />
          <TextInputCounter
            label="Revinate API Secret"
            source="revinateSecret"
            validate={[maxLength(64)]}
            fullWidth
            limit={64}
          />
          <TextInputCounter
            label="Waitlist URL"
            source="waitlistUrl"
            validate={[startWithHTTPS]}
            fullWidth
          />
        </FormTab>
      </TabbedForm>
    </RaCreateWrapper>
  );
};
